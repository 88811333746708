export enum PartnerActionTypes {
    SET_ALL_PARTNERS = "@@partner/SET_ALL_PARTNERS",
    SET_PARTNER_FILTER_DATA_LOADER = "@@partner/SET_PARTNER_FILTER_DATA_LOADER",
    ADD_PARTNER_MODAL = "@@partner/ADD_PARTNER_MODAL",
    ADD_PARTNER = "@@partner/ADD_PARTNER",
    RESET_ADD_PARTNER_MODAL = "@@partner/RESET_ADD_PARTNER_MODAL",
    EDIT_PARTNER_MODAL = "@@partner/EDIT_PARTNER_MODAL",
    RESET_EDIT_PARTNER_MODAL = "@@partner/RESET_EDIT_PARTNER_MODAL",
    RESET_VIEW_PARTNER_MODAL = "@@partner/RESET_VIEW_PARTNER_MODAL",
    RESET_DELETE_PARTNER_MODAL = "@@partner/RESET_DELETE_PARTNER_MODAL",
    UPDATE_PARTNER = "@@partner/UPDATE_PARTNER",
    VIEW_PARTNER = "@@partner/VIEW_PARTNER",
    VIEW_PARTNER_MODAL = "@@partner/VIEW_PARTNER_MODAL",
    DELETE_PARTNER_MODAL = "@@partner/DELETE_PARTNER_MODAL",
    DELETE_PARTNER = "@@partner/DELETE_PARTNER",
    SET_ALL_PARTNERS_LOADER = "@@partner/SET_ALL_PARTNERS_LOADER", 
    SET_PARTNER_TYPES = "@@partner/SET_PARTNER_TYPES",

}

export const partnersFilterInitial: IPartnerFilter = {
    BeginDate: null,
    EndDate: null,
    IsActive: null,
  };
