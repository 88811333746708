import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import Loader from "components/loader/Loader";
import moment from "moment";
import { useState } from "react";
import OrganizationDetails from "views/organization/OrganizationDetails";

const ViewSubscriptionDetails = ({
  subscriptionDetails,
  refreshDetails,
  isOpen,
  onClose,
}) => {
  console.log("subscriptionDetails", subscriptionDetails);
  
  const [isLoading, setLoaded] = useState(false);
  return (
    <div>
      {!isLoading && isOpen && subscriptionDetails && (
        <CModal
          show={isOpen}
          onClose={onClose}
          closeOnBackdrop={false}
          style={{ borderTop: "5px solid #ccc" }}
        >
          <CModalHeader closeButton>
            <CModalTitle>
              <div>Subscription's Details</div>
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Organization Name</span>
              </div>
              <div className="col-md-6">
                <span>{subscriptionDetails.organizationName ? subscriptionDetails.organzationName:"N/A"}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold"> Email</span>
              </div>
              <div className="col-md-6">
                <span>{subscriptionDetails.organizationEmail ? subscriptionDetails.organzationEmail:"N/A"}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Account Type</span>
              </div>
              <div className="col-md-6">
                <span>{subscriptionDetails.organizationAccountType ? subscriptionDetails.organizationAccountType:"N/A"}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Plan Name:</span>
              </div>
              <div className="col-md-6">
                <span>{subscriptionDetails.planName}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Active:</span>
              </div>
              <div className="col-md-6">
                <span>{subscriptionDetails.isActive ? "Yes" : "No"}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Creation Date:</span>
              </div>
              <div className="col-md-6">
                <span>
                  {moment(subscriptionDetails.creationDate).format(
                    "MMM DD YYYY"
                  )}
                </span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">End Date:</span>
              </div>
              <div className="col-md-6">
                <span>
                  {moment(subscriptionDetails.endDate).format("MMM DD YYYY")}
                </span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Subscription Type:</span>
              </div>
              <div className="col-md-6">
                <span>{subscriptionDetails.subscriptionType?subscriptionDetails.subscriptionType:"N/A"}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">ThirdParty SubscriptionID:</span>
              </div>
              <div className="col-md-6">
                <span>{subscriptionDetails.msThirdPartySubscriptionID?subscriptionDetails.msThirdPartySubscriptionID:"N/A"}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Purchased Licenses:</span>
              </div>
              <div className="col-md-6">
                <span>{subscriptionDetails.purchasedLicenses}</span>
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton color="danger" size="sm" onClick={onClose}>
              Close
            </CButton>
          </CModalFooter>
        </CModal>
      )}
      <div />
      {isLoading && (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default ViewSubscriptionDetails;
