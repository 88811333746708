import { Reducer } from "redux";
import {
  SubscriptionActionTypes,
  subscriptionFilterInitials,
} from "./action.types";

export const initialState: subscriptionState = {
  subscriptionTypes: [],
  yearlySubscriptions: [],
  isSubscriptionFiltersLoaded: false,
  filteredSubscriptions: [],
  isFilteredSubscriptionsLoaded: false,
  subscriptionsFilter: subscriptionFilterInitials,
  isSubscriptionLoading: { id: null, isStatusLoading: false },
  editSubscriptionModal: {
    showModal: false,
    isModalFormSubmitting: false,
    subscription: null,
  }
};

const reducer: Reducer<subscriptionState> = (state = initialState, action) => {
  switch (action.type) {
    case SubscriptionActionTypes.SET_SUBSCRIPTION_TYPES: {
      return {
        ...state,
        subscriptionTypes: [...action.subscriptionTypes],
      };
    }
    case SubscriptionActionTypes.SET_YEARLY_SUBSCRIPTIONS: {
      return {
        ...state,
        yearlySubscriptions: [...action.yearlySubscriptions],
      };
    }
    case SubscriptionActionTypes.SET_SUBSCRIPTION_FILTERS_LOADER: {
      return {
        ...state,
        isSubscriptionFiltersLoaded: action.isSubscriptionFiltersLoaded,
      };
    }
    case SubscriptionActionTypes.SET_FILTERED_SUBSCRIPTIONS: {
      return {
        ...state,
        filteredSubscriptions: [...action.filteredSubscriptions],
      };
    }
    case SubscriptionActionTypes.SET_FILTERED_SUBSCRIPTIONS_LOADER: {
      return {
        ...state,
        isFilteredSubscriptionsLoaded: action.isFilteredSubscriptionsLoaded,
      };
    }

    case SubscriptionActionTypes.SET_SUBSCRIPTION_FILTER: {
      return {
        ...state,
        subscriptionsFilter: {
          ...state.subscriptionsFilter,
          ...action.subscriptionsFilter,
        },
      };
    }
    // case SubscriptionActionTypes.SET_SUBSCRIPTION_FILTER: {
    //   return {
    //     ...state,
    //     usersFilter: {
    //       ...state.subscriptionsFilter,
    //       ...action.subscriptionsFilter,
    //     },
    //   };
    // }

    case SubscriptionActionTypes.SET_SUBSCRIPTION_STATUS: {
      return {
        ...state,
        users: state.filteredSubscriptions.map((el) => {
          if (el.id === action.subscription.id) {
            el.isActive = action.subscription.isActive;
          }
          return el;
        }),
      };
    }
    case SubscriptionActionTypes.EDIT_SUBSCRIPTION_MODAL: {
      return {
        ...state,
        editSubscriptionModal: { ...state.editSubscriptionModal, ...action.editSubscriptionModal },
      };
    }
    case SubscriptionActionTypes.DELETE_SUBSCRIPTION_MODAL: {
      return {
        ...state,
        deleteSubscriptionModal: { ...state.deleteSubscriptionModal, ...action.deleteSubscriptionModal },
      };
    }
    case SubscriptionActionTypes.UNDO_DELETE_SUBSCRIPTION_MODAL: {
      return {
        ...state,
        undoDeleteSubscriptionModal: { ...state.undoDeleteSubscriptionModal, ...action.undoDeleteSubscriptionModal },
      };
    }
    case SubscriptionActionTypes.VIEW_SUBSCRIPTION_DETAILS_MODAL: {
      return {
        ...state,
        viewSubscriptionModal: { ...state.viewSubscriptionModal, ...action.viewSubscriptionModal },
      };
    }
    case SubscriptionActionTypes.VIEW_SUBSCRIPTIONS_MAIL_MODAL: {
      return {
        ...state,
        viewSubscriptionMailModal: { ...state.viewSubscriptionMailModal, ...action.viewSubscriptionMailModal },
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as subscriptionReducer };
