import { Reducer } from "redux";
import { GlobalActionTypes } from "./action.types";

export const initialState: globalState = {
  countries: [],
  timezones: [],
  currentSelectData: null,
};

const reducer: Reducer<globalState> = (state = initialState, action) => {
  switch (action.type) {
    case GlobalActionTypes.SET_COUNTRIES: {
      return {
        ...state,
        countries: [...action.countries],
      };
    }
    case GlobalActionTypes.SET_TIMEZONES: {
      return {
        ...state,
        timezones: [...action.timezones],
      };
    }

    case GlobalActionTypes.SET_SELECT_DATA: {
      return {
        ...state,
        currentSelectData: {...action.currentSelectData},
      };
    }
    case GlobalActionTypes.RESET_SELECT_DATA: {
      return {
        ...state,
        currentSelectData: null,
      };
    }
    case GlobalActionTypes.SET_SELECT_SEARCH: {
      return {
        ...state,
        currentSelectSearch: action.currentSelectSearch,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as globalReducer };
