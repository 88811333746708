import { combineReducers } from "redux";
import { globalReducer } from "./global/reducer";
import { iconReducer } from "./icon/reducer";
import { offerReducer } from "./offer/reducer";
import { organizationReducer } from "./organization/reducer";
import { partnerReducer } from "./partner/reducer";
import { planReducer } from "./plan/reducer";
import { sidebarReducer } from "./sidebar/reducer";
import { subscriptionReducer } from "./subscription/reducer";
import { userReducer } from "./user/reducer";
import { leadsReducer } from "./leads/reducer";
import { categoryReducer } from "./court/reducer";
import { bookmarkReducer } from "./bookmark/reducer";
import { tagReducer } from "./tag/reducer";


export interface ApplicationState {
  global: globalState;
  organization: organizationState;
  sidebar: sidebarState;
  user: userState;
  subscription: subscriptionState;
  plan: planState;
  offer: offerState;
  partner: partnerState;
  icon: iconState;
  lead: leadState;
  category:categoryState;
  bookmark:bookmarkState;
  tag:tagState;
}

export const createRootReducer = () => {
  return combineReducers({
    global: globalReducer,
    organization: organizationReducer,
    sidebar: sidebarReducer,
    user: userReducer,
    subscription: subscriptionReducer,
    plan: planReducer,
    offer: offerReducer,
    partner: partnerReducer,
    icon: iconReducer,
    lead: leadsReducer,
    category:categoryReducer,
    bookmark:bookmarkReducer,
    tag:tagReducer
  });
};
