import { CDataTable, CSwitch } from "@coreui/react";
import { useContext, useEffect, useRef, useState } from "react";
// import AddPlanModal from "./AddPlanModal";
// import EditPlanModal from "./EditPlanModal";
import axios from "axios";
import DataTableNoContent from "components/datatable/datatableNoContent";
import { DTablePagination } from "components/datatable/pagination";
import TMFSearch from "components/input/TMFSearch";
import commonConstants from "constants/commonConstants";
import Constants from "constants/Constants";
import { TotalCountContext } from "context/TotalCount";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import planApi from "services/apis/plan.api";
import { ApplicationState } from "store";
import { setLeadSearchValue } from "store/leads/action";
import {
  setAddPlanModal,
  setDeletePlanModal,
  setViewPlanModal
} from "store/plan/action";
import AddPlanModal from "../../components/modals/plan/addPlanModal";
import EditPlanModal from "../../components/modals/plan/editPlanModal";
import ViewPlanModal from "../../components/modals/plan/viewPlanModal";
import DeletePlanModal from "./DeletePlanModal";
import PlanActions from "./planActions";
import PlansTableVariables from "./plansTableVariables";
import PlanStatusModal from "./PlanStatusModal";
import planVariables from "./planVariables";

const Plans = () => {
  const totalData = useContext(TotalCountContext);
  const dispatch: Dispatch<any> = useDispatch();
  const plan = useSelector((state: ApplicationState) => state.plan);
  const [editPlanModal, setEditPlanModal] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(commonConstants.PAGE_SIZE);
  const [isLoading, setLoaded] = useState(false);
  const [planStatusModal, setPlanStatusModal] = useState(false);
  const [privateEvent, setPrivateEvent] = useState(null);
  const [planID, setPlanID] = useState(null);
  const [planName, setPlanName] = useState(null);
  const planFilter = useSelector(
    (state: ApplicationState) => state.plan.planFilter
  );

  useEffect(() => {
    getPlansTotalCount();
    loadPlans();
  }, []);

  useEffect(() => {
    loadPlanFilter();
  }, [pageNo]);

  const loadPlans = async () => {
    setLoaded(true);
    dispatch(setLeadSearchValue(""));
    planApi.fetchAllPlans(dispatch).then((res) => {
      setLoaded(false);
    });
  };

  const loadPlanFilter = async () => {
    setLoaded(true);
    planApi.filterPlans(planFilter, dispatch, pageSize, pageNo).then((res) => {
      setLoaded(false);
    });
    // .filterLeads(leadsFilter, dispatch, pageSize, pageNo)
    // .then((res) => {
    //   setLoaded(false);
    // });
  };

  const getPlansTotalCount = () => {
    axios.post(Constants.PLAN_TOTAL_COUNT_URI).then((res) => {
      setTotalPage(Math.ceil(Math.abs(res.data / commonConstants.PAGE_SIZE)));
    });
  };
  const handleEditPlanModal = (id) => {
    setEditPlanModal(true);
    setPlanID(id);
  };

  const handlePlanStatus = (event, id, name) => {
    setPlanStatusModal(true);
    setPlanID(id);
    setPrivateEvent(event);
    setPlanName(name);
  };

  const deletePlanModal = useSelector(
    (state: ApplicationState) => state.plan?.deletePlanModal
  );
  const viewPlanModal = useSelector(
    (state: ApplicationState) => state.plan?.viewPlanModal
  );
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const PlanPageTitleBuild = () => {
    return (
      <>
        <div className="title">
          <div className="page-title">Plans</div>
          <div>
            <button
              className="create"
              onClick={() => dispatch(setAddPlanModal({ showModal: true }))}
            >
              Add New
            </button>
          </div>
        </div>
      </>
    );
  };

 

  const PlanPageModals = () => {
    return (
      <>
        {planStatusModal && (
          <PlanStatusModal
            isOpen={planStatusModal}
            onClose={() => setPlanStatusModal(false)}
            refreshDetails={() => loadPlans()}
            id={planID}
            event={privateEvent}
            name={planName}
          />
        )}
      </>
    );
  };
  const DeletePlanModals = () => {
    return (
      <>
        {deletePlanModal?.showModal && (
          <DeletePlanModal
            id={deletePlanModal?.plan?.id}
            isOpen={deletePlanModal?.showModal}
            onClose={() => dispatch(setDeletePlanModal({ showModal: false }))}
            refreshDetails={() => loadPlans()}
            name={deletePlanModal?.plan?.name}
          />
        )}
      </>
    );
  };

  const handleCancelSearch = () => {
    loadPlans();
  };
  const handleClicks = () => {
    csvLinkRef.current?.link.click();
  };
  const PlanTableBuild = () => {
    return (
      <div className="tmf-table_wrapper">
        <div
          className={`tmf-datatable__wrapper tmf_hide_build_in_pagination ${isSearchActive && "tmf_paggination_state"
            }`}
          style={{
            width: "100%",
            maxWidth: "100%",
            overflowX: "auto"
          }}
        >
          <div
            style={{
              borderTopColor: "#fff",
              width: "1200px",
              minWidth: "100%",
              overflowX: "auto",
            }} >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                padding: "12px",
                backgroundColor: "#fff",
              }}
            >
              <TMFSearch
                sapi={Constants.PLAN_SEARCH_URI}
                dispatch={dispatch}
                isSearchActive={(e) => setIsSearchActive(e)}
                searchIn="plans"
                onCalcelSearch={(e) => handleCancelSearch()}
                Placeholder="Search by Name"
              />

              <div className="table-filter-section">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ position: "relative" }}>
                    <div className="d-flex" >
                      <button
                        onClick={handleClicks}
                        className="exportcsvwithborderradius"
                        disabled={plan.allPlans.length === 0}
                        style={{
                          cursor: plan.allPlans.length === 0 ? "not-allowed" : "pointer"
                        }}
                      >
                        Export to CSV
                      </button>
                      <CSVLink
                        headers={planVariables.CSVHeaders}
                        data={plan.allPlans}
                        filename="AllPlan.csv"
                        ref={csvLinkRef}
                        className="hidden"
                      />
                    </div>

                  </div>


                  {
                    !isSearchActive &&
                    <span style={{ marginLeft: "auto" }}>
                      <DTablePagination
                        cPage={pageNo}
                        tPage={totalPage!}
                        onPageChange={(e) => {
                          setPageNo(e);
                          loadPlans();
                        }}
                      ></DTablePagination>
                    </span>
                  }

                </div>
              </div>

            </div>
            <CDataTable
              items={plan.allPlans}
              fields={PlansTableVariables.fields}
              //tableFilter={{ placeholder: "search Plan" }}
              responsive={true}
              loading={isLoading}
              noItemsViewSlot={<DataTableNoContent></DataTableNoContent>}
              hover
              // sorter
              // pagination
              scopedSlots={{
                name: (item) => {
                  return (
                    <td>
                      <div className="name"
                      >
                        {
                          item.isDeleted ? (
                            <span
                              style={{
                                fontSize: "1rem",
                                fontWeight: "500",
                                color: "#524467",
                                textDecoration: "line-through"
                              }}
                            >
                              {item.name ? item.name.charAt(0).toUpperCase() +
                                item.name.slice(1).toLowerCase()
                                : "N/A"}

                            </span>
                          ) : (
                            <span
                              style={{
                                fontSize: "1rem",
                                fontWeight: "500",
                                color: "var(--txt-primary, #23005B)",
                              }}
                            >
                              {item.name ? item.name.charAt(0).toUpperCase() +
                                item.name.slice(1).toLowerCase()
                                : "N/A"}

                            </span>
                          )
                        }

                      </div>
                    </td>
                  )
                },
                isActive: (item) => {
                  return (
                    <td>
                      {item.isDeleted ? (
                        <CSwitch
                          disabled
                          color="success"
                          defaultChecked={item.isActive}
                          labelOn="On"
                          labelOff="Off"
                          shape="pill"
                          variant="opposite"
                          size="sm"
                        />
                      ) : (
                        <CSwitch
                          color="success"
                          defaultChecked={item.isActive}
                          labelOn="On"
                          labelOff="Off"
                          shape="pill"
                          variant="opposite"
                          size="sm"
                          onChange={(e) =>
                            handlePlanStatus(item.isActive, item.id, item.name)
                          }
                          id={"planSwitch" + item.isActive}
                        />
                      )}
                    </td>
                  );
                },
                Action: (item) => {
                  return (
                    <td>
                      <PlanActions plan={item}></PlanActions>
                    </td>
                  );
                },
              }}
            />

            {!isSearchActive && (
              <DTablePagination
                tPage={totalPage!}
                cPage={pageNo}
                onPageChange={(e) => setPageNo(e)}
              />
            )}
          </div>

        </div>
      </div>

    );
  };
  return (
    <>
      <PlanPageTitleBuild></PlanPageTitleBuild>
      <PlanTableBuild></PlanTableBuild>

      <PlanPageModals></PlanPageModals>
      <AddPlanModal></AddPlanModal>
      <ViewPlanModal></ViewPlanModal>
      <EditPlanModal></EditPlanModal>
      <DeletePlanModals></DeletePlanModals>
    </>
  );
};

export default Plans;
