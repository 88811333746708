export enum OrganizationActionTypes {
  SET_ORGANIZATIONS = "@@organization/SET_ORGANIZATIONS",
  SET_ORGANIZATIONS_LOADER = "@@organization/SET_ORGANIZATIONS_LOADER",
  SET_ALL_ORGANIZATIONS = "@@organization/SET_ALL_ORGANIZATIONS",
  SET_ALL_ORGANIZATION_LOADER = "@@organization/SET_ALL_ORGANIZATION_LOADER",
  SET_ORGANIZATION_TYPES = "@@organization/SET_ORGANIZATION_TYPES",
  RESET_ORGANIZATIONS = "@@organization/RESET_ORGANIZATIONS",

  SET_SHOW_ADD_ORGANIZATION_MODAL = "@@organization/SET_SHOW_ADD_ORGANIZATION_MODAL",
  SET_ORGANIZATION_FILTERS = "@@organization/SET_ORGANIZATION_FILTERS",

  SET_ORGANIZATION_LOADER = "@@organization/SET_ORGANIZATION_LOADER",

  SET_ORGANIZATION_STATUS = "@@organization/SET_ORGANIZATION_STATUS",
  EDIT_ORGANIZATION_MODAL="@@organization/EDIT_ORGANIZATION_MODAL",
  SET_ORGANIZATION_COURT = "@@organization/SET_ORGANIZATION_STATUS",
}

export const organizationFilterInitials: IOrganizationFilter = {
  BeginDate: null,
  EndDate: null,
  IsActive: null,
  ID: null,
};
