export enum OfferActionTypes {
  SET_ALL_OFFERS = "@@offer/SET_ALL_OFFERS",
  SET_ALL_OFFERS_LOADER = "@@offer/SET_ALL_OFFERS_LOADER",
  ADD_OFFER = "@@offer/ADD_OFFER",
  UPDATE_OFFER = "@@offer/UPDATE_OFFER",
  SET_OFFER_MODAL = "@@offer/SET_OFFER_MODAL",
  RESET_OFFER_MODAL = "@@offer/RESET_OFFER_MODAL",
  EDIT_OFFER = "@@offer/EDIT_OFFER",
  RESET_EDIT_OFFER_MODAL = "@@offer/RESET_EDIT_OFFER_MODAL",
  VIEW_OFFER = "@@offer/VIEW_OFFER",
  VIEW_OFFER_MODAL = "@@offer/VIEW_OFFER_MODAL",
  RESET_VIEW_OFFER_MODAL = "@@offer/RESET_VIEW_OFFER_MODAL",
  DELETE_OFFER = "@@offer/DELETE_PLAN",
  DELETE_OFFER_MODAL = "@@offer/DELETE_OFFER_MODAL",
  RESET_DELETE_OFFER_MODAL = "@@offer/RESET_DELETE_OFFER_MODAL"

}
export const offersFilterInitial: IOfferFilter = {
  BeginDate: null,
  EndDate: null,
  IsActive: null,
};
