import { SubscriptionActionTypes } from "./action.types";

export function setSubscriptionTypes(subscriptionTypes: ISubscriptionType[]) {
  const action: SubscriptionAction = {
    type: SubscriptionActionTypes.SET_SUBSCRIPTION_TYPES,
    subscriptionTypes,
  };
  return (dispatch: SubscriptionDispatchType) => dispatch(action);
}

export function setYearlySubscriptions(
  yearlySubscriptions: ISubscriptionYearly[]
) {
  const action: SubscriptionAction = {
    type: SubscriptionActionTypes.SET_YEARLY_SUBSCRIPTIONS,
    yearlySubscriptions,
  };
  return (dispatch: SubscriptionDispatchType) => dispatch(action);
}

export function setSubscriptionsFilterDataLoader(
  isSubscriptionFiltersLoaded: boolean
) {
  const action: SubscriptionAction = {
    type: SubscriptionActionTypes.SET_SUBSCRIPTION_FILTERS_LOADER,
    isSubscriptionFiltersLoaded,
  };
  return (dispatch: SubscriptionDispatchType) => dispatch(action);
}

export function setFilteredSubscriptions(
  filteredSubscriptions: ISubscription[]
) {
  const action: SubscriptionAction = {
    type: SubscriptionActionTypes.SET_FILTERED_SUBSCRIPTIONS,
    filteredSubscriptions,
  };
  return (dispatch: SubscriptionDispatchType) => dispatch(action);
}

export function setFilteredSubscriptionsLoader(
  isFilteredSubscriptionsLoaded: boolean
) {
  const action: SubscriptionAction = {
    type: SubscriptionActionTypes.SET_FILTERED_SUBSCRIPTIONS_LOADER,
    isFilteredSubscriptionsLoaded,
  };
  return (dispatch: SubscriptionDispatchType) => dispatch(action);
}

//Subscription Filter
export function setSubscriptionsFilter(
  subscriptionsFilter: ISubscriptionFilter
) {
  const action: SubscriptionAction = {
    type: SubscriptionActionTypes.SET_SUBSCRIPTION_FILTER,
    subscriptionsFilter,
  };
  return (dispatch: SubscriptionDispatchType) => dispatch(action);
}

//Subscription Loader
export function setSubscriptionLoader(
  isSubscriptionLoading: ISubscriptionLoader
) {
  const action: SubscriptionAction = {
    type: SubscriptionActionTypes.SET_SUBSCRIPTION_LOADER,
    isSubscriptionLoading: isSubscriptionLoading,
  };
  return (dispatch: SubscriptionDispatchType) => dispatch(action);
}

//Subscription status
export function setSubscriptionStatus(subscription: ISubscription) {
  const action: SubscriptionAction = {
    type: SubscriptionActionTypes.SET_SUBSCRIPTION_STATUS,
    subscription: subscription,
  };
  return (dispatch: SubscriptionDispatchType) => dispatch(action);
}

// EditSubscriptionModal
export function setEditSubscriptionModal(
  editSubscriptionModal: IEditSubscriptionModal
) {
  const action: SubscriptionAction = {
    type: SubscriptionActionTypes.EDIT_SUBSCRIPTION_MODAL,
    editSubscriptionModal,
  };
  return (dispatch: SubscriptionDispatchType) => dispatch(action);
}
// DeleteSubscriptionModal
export function setDeleteSubscriptionModal(
  deleteSubscriptionModal: IDeleteSubscriptionModal
) {
  const action: SubscriptionAction = {
    type: SubscriptionActionTypes.DELETE_SUBSCRIPTION_MODAL,
    deleteSubscriptionModal,
  };
  return (dispatch: SubscriptionDispatchType) => dispatch(action);
}
// UndoDeleteSubscriptionModal
export function setUndoDeleteSubscriptionModal(
  undoDeleteSubscriptionModal: IUndoDeleteSubscriptionModal
) {
  const action: SubscriptionAction = {
    type: SubscriptionActionTypes.UNDO_DELETE_SUBSCRIPTION_MODAL,
    undoDeleteSubscriptionModal,
  };
  return (dispatch: SubscriptionDispatchType) => dispatch(action);
}

// ViewDeleteSubscriptionModal
export function setViewSubscriptionModal(
  viewSubscriptionModal: IViewSubscriptionModal
) {
  const action: SubscriptionAction = {
    type: SubscriptionActionTypes.VIEW_SUBSCRIPTION_DETAILS_MODAL,
    viewSubscriptionModal,
  };
  return (dispatch: SubscriptionDispatchType) => dispatch(action);
}

// ViewSubscriptionMailModal
export function setViewSubscriptionMailModal(
  viewSubscriptionMailModal: IViewSubscriptionMailModal
) {
  const action: SubscriptionAction = {
    type: SubscriptionActionTypes.VIEW_SUBSCRIPTIONS_MAIL_MODAL,
    viewSubscriptionMailModal,
  };
  return (dispatch: SubscriptionDispatchType) => dispatch(action);
}
