import { Dispatch } from "redux";
import apiUrls from "constants/Constants";
import api from "services/api.service";
import { setIcons, setIconsLoader } from "store/icon/action";

const iconApi = {
  fetchIcons: function (dispatch: Dispatch<any>) {
    dispatch(setIconsLoader(true));
    return api
      .get(apiUrls.ICON_GET_URI)
      .then((res) => {
        dispatch(setIcons(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => dispatch(setIconsLoader(false)));
  },
};

export default iconApi;
