import { Dispatch } from "redux";
import apiUrls from "constants/Constants";
import api from "services/api.service";
import {
  setAllOrganizationLoader,
  setAllOrganizations,
  setOrganizationsLoader,
  setOrganizations,
  setOrganizationStatus,
  setOrganizationTypes,
  setOrganizationLoader,
} from "store/organization/action";

const organizationApi = {
  fetchAllOrganizations: function (
    dispatch: Dispatch<any>,
    showLoader: boolean = true
  ) {
    if (showLoader) dispatch(setAllOrganizationLoader(true));
    return api
      .get(apiUrls.ORGANIZATION_GET_URI)
      .then((res) => {
        dispatch(setAllOrganizations(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        if (showLoader) dispatch(setAllOrganizationLoader(false));
      });
  },

  // filterOrganization: function (
  //   payload: IOrganizationFilter,
  //   dispatch: Dispatch<any>
  // ) {
  //   dispatch(setOrganizationsLoader(true));
  //   return api
  //     .post(apiUrls.DATE_WISE_ORGANIZATION_FILTER_URI, payload)
  //     .then((res) => {
  //       dispatch(setOrganizations(res.data));
  //       return res.data;
  //     })
  //     .catch((error) => {
  //       throw error;
  //     })
  //     .finally(() => {
  //       dispatch(setOrganizationsLoader(false));
  //     });
  // },

  filterOrganization: function (
    payload: IOrganizationFilter,
    dispatch: Dispatch<any>,
    pageSize,
    pageNo
  ) {
    dispatch(setOrganizationsLoader(true));
    return api
      .post(
        `${apiUrls.DATE_WISE_ORGANIZATION_FILTER_URI}/${pageSize}/${pageNo}`,
        payload
      )
      .then((res) => {
        dispatch(setOrganizations(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        dispatch(setOrganizationsLoader(false));
      });
  },

  fetchOrganizationTypes: function (dispatch: Dispatch<any>) {
    return api
      .get(apiUrls.ORGANIZATION_TYPE_GET_URI)
      .then((res) => {
        dispatch(setOrganizationTypes(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  toggleOrganizationStatus: function (
    organization: IOrganization,
    dispatch: Dispatch<any>
  ) {
    dispatch(
      setOrganizationLoader({
        id: organization.organizationId,
        isStatusLoading: true,
      })
    );
    return api
      .post(
        apiUrls.ORGANIZATION_TOGGLE_STATUS_URI +
          `/${organization.organizationId}/${!organization.isActive}`
      )
      .then((res) => {
        dispatch(
          setOrganizationStatus({
            ...organization,
            isActive: !organization.isActive,
          })
        );
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() =>
        dispatch(
          setOrganizationLoader({
            id: null,
            isStatusLoading: false,
          })
        )
      );
  },
};

export default organizationApi;
