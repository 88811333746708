import { Sidebar, NavigationHeader } from "containers";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";


const NavigationTemplate = ({ ...props }) => {

  const sidebar: ISidebar = useSelector(
    (state: ApplicationState) => state.sidebar.sidebar
  );

  return (
    <>

      <div className="c-app c-default-layout">
        <NavigationHeader />
        <Sidebar />

 
        <div className={`c-wrapper ${sidebar.showSidebar?"open-body    full-width  ":"close-body"}`} >
          <div className="c-body">{props.children}</div>
        </div>
      </div>
    </>
  );
};

export default NavigationTemplate;
