// import { Store, createStore, applyMiddleware } from "redux";

// import { thunk } from "redux-thunk";

// import { createRootReducer, ApplicationState } from "store/index";

// export const configureStore = (
//   initialState: ApplicationState
// ): Store<ApplicationState> => {
//   const store = createStore(
//     createRootReducer(),
//     initialState,
//     applyMiddleware(thunk)
//   );
//   return store;
// };
import { Store, createStore, applyMiddleware } from "redux";

import thunk from "redux-thunk";

import { createRootReducer, ApplicationState } from "store/index";

export const configureStore = (
  initialState: ApplicationState
): Store<ApplicationState> => {
  const store = createStore(
    createRootReducer(),
    initialState,
    applyMiddleware(thunk),
  );
  return store;
}