import { SidebarActionTypes } from "./action.types";

export function addSidebar(sidebar: ISidebar) {
    const action: SidebarAction = {
        type: SidebarActionTypes.SET_SIDEBAR,
        sidebar,
    };
    return (dispatch: SidebarDispatchType) => dispatch(action);
}

export function fetchSidebar(sidebar: ISidebar) {
    const action: SidebarAction = {
        type: SidebarActionTypes.FETCH_SIDEBAR,
        sidebar,
    };
    return (dispatch: SidebarDispatchType) => dispatch(action);
}