const LeadsTableVariables = {
  fields: [
    {
      key: "id",
      label: "",
      _style: { width: "5%", textAlign: "center", paddingRight: "0.3rem" },
      sorter: false,
      filter: false,
    },

    { key: "firstName", label: "Name", _style: { width: "20%" } },
    // { key: "email", label: "E-mail", _style: { width: "10%" } },
    { key: "phone", label: "Details", _style: { width: "25%" } },
    // { key: "country", label: "Country", _style: { width: "5%" } },
    // { key: "company", label: "Company", _style: { width: "5%" } },
    // { key: "title", label: "Title", _style: { width: "5%" } },
    { key: "leadSource", label: "Lead Source", _style: { width: "10%" } },
    { key: "mailStatus", label: "Mail Status", _style: { width: "10%" } },
    // { key: "actionCode", label: "Action Code", _style: { width: "5%" } },
    // { key: "offerTitle", label: "Offer Title", _style: { width: "5%" } },
    // { key: "description", label: "Description", _style: { width: "5%" } },
    { key: "attempt", label: "Attempt", _style: { width: "5%" } },
    { key: "visitDate", label: "Visit Date", _style: { width: "10%" } },
    { key: "isSubscribed", label: "Subscribed?", _style: { width: "10%" } },
    {
      key: "Action",
      label: "Action",
      _style: { width: "5%", textAlign: "center", paddingRight: "0.22rem" },
      sorter: false,
      filter: false,
    },
  ],

  headers: [
    { key: "email", label: "E-mail" },
    { key: "firstName", label: " First Name" },
    { key: "lastName", label: "Last Name" },
    { key: "phone", label: "Phone" },
    // { key: "country", label: "Country" },
    // { key: "company", label: "Company" },
    // { key: "title", label: "Title" },
    { key: "leadSource", label: "Lead Source" },
    { key: "actionCode", label: "Action Code" },
    { key: "offerTitle", label: "Offer Title" },
    { key: "description", label: "Description" },
    { key: "attempt", label: "Attempt" },
    { key: "visitDate", label: "Visit Date" },
    { key: "isSubscribed", label: "Subscribed" },
    { key: "mailStatus", label: "Mail Status" },
  ],
};
export default LeadsTableVariables;
