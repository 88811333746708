import { Reducer } from "redux";
import { IconActionTypes } from "./action.types";

export const initialState: iconState = {
  icons: [],
  isIconsLoading: false,
};

const reducer: Reducer<iconState> = (state = initialState, action) => {
  switch (action.type) {
    case IconActionTypes.SET_ICONS: {
      return {
        ...state,
        icons: [...action.icons],
      };
    }
    case IconActionTypes.SET_ICONS_LOADER: {
      return {
        ...state,
        isIconsLoading: action.isIconsLoading,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as iconReducer };
