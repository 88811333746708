
const MonthList ={
 getMonthList:()=>{
 // Get the current month (0-indexed, so January is 0)
 const currentMonth = new Date().getMonth();

 // Array of month names
 const months = [
   'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
   'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
 ];

 // Function to get the correct index in circular manner
 const getMonthIndex = (index) => {
   return (currentMonth + 1 + index) % 12;
 };

 // Generate list of 12 months starting from the current month
 const monthList = Array.from({ length: 12 }, (_, index) => months[getMonthIndex(index)]);

 return monthList;
 }
}

export default MonthList;
