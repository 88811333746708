import { BrowserRouter, Switch } from "react-router-dom";
import routes from "./routes/routes";

import "styles/style.scss";
import AuthProvider from "auth/auth.provider";
import PrivateRoute from "routes/routes.private";
import PublicRoute from "routes/routes.public";
import { ToastContainer } from "react-toastify";

import TotalCount from "context/TotalCount";

const App: React.FC = () => {
  return (
    <>
      <AuthProvider>
        <TotalCount>
          <BrowserRouter>
            <Switch>
              {routes.map((route, index) => {
                if (route.isProtected)
                  return <PrivateRoute key={index} {...route}></PrivateRoute>;
                else return <PublicRoute key={index} {...route}></PublicRoute>;
              })}
              {/* <ToastContainer /> */}
            </Switch>
          </BrowserRouter>
        </TotalCount>
        <ToastContainer />
      </AuthProvider>
    </>
  );
};

export default App;
