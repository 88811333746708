import AvishModal from "components/modal/modal";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import offerApi from "services/apis/offer.api";
import { ApplicationState } from "store";
import { resetOfferModal } from "store/offer/action";
import OfferFormElements from "../offerFormElement";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddOfferModal = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const addOfferModal = useSelector(
    (state: ApplicationState) => state.offer?.addOfferModal
  );

  function closeHandler() {
    dispatch(resetOfferModal());
  }
  function submitHandler(e) {
    const data = new FormData(e.target);
    var formData: IOfferFormData = [...data.entries()].reduce((attr, el) => {
      attr[el[0]] = el[1];
      return attr;
    }, {} as IOfferFormData);
    offerApi
      .createNewOffer(dispatch, formData)
      .then(() => {
        toast.success("😊 Offer added successfully.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        closeHandler();
      })
      .catch((error) => {
        toast.error(`😔 Error while adding offer!`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  }

  const ModalIconBuild = () => {
    return (
      <svg viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
          clipRule="evenodd"
        />
      </svg>
    );
  };

  return addOfferModal.showModal ? (
    <AvishModal
      submitButtonType="submit"
      submitButtonValue="Add Offer"
      title="Add Offer"
      icon={<ModalIconBuild></ModalIconBuild>}
      onClose={closeHandler}
      onSubmit={submitHandler}
      showModal={!!addOfferModal?.showModal}
      isFormSubmitting={!!addOfferModal?.isModalFormSubmitting}
    >
      <OfferFormElements></OfferFormElements>
    </AvishModal>
  ) : (
    <></>
  );
};

export default AddOfferModal;
