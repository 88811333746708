import { useContext, useEffect, useState } from "react";
import { selectContext } from "./select.provider";

interface ISelectModalBodyProps {
  options: ISelectData[];
}

const SelectModalBody = ({ options = [] }: ISelectModalBodyProps) => {
  const { search, selectedOption, setSelectedOption } = useContext(selectContext);


  const [filteredOptions, setFilteredOptions] =
    useState<ISelectData[]>(options);

  useEffect(() => {
    setFilteredOptions([
      ...options.filter((el) =>
        el.label.toLowerCase().startsWith(search || "")
      ),
    ]);
  }, [search]);

  const selectOptionHandler = (option: ISelectData) => {
    setSelectedOption(option)
  };

  const SelectModalOptionsBuild = () => {
    return (
      <>
        {filteredOptions.length !== options.length && (
          <span className="avish-select__length">
            {filteredOptions.length} items found from {options.length} items
          </span>
        )}

        <ul className="avish-select__options">
          {filteredOptions.map((el, index) => {
            return (
              <li
                key={index}
                onClick={() => selectOptionHandler(el)}
                className={
                  selectedOption?.value === el.value
                    ? "avish-select__options__selected"
                    : ""
                }
              >
                {selectedOption?.value === el.value && (
                  <svg viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
                {el.label}
              </li>
            );
          })}
        </ul>
      </>
    );
  };
  return (
    <>
      <SelectModalOptionsBuild></SelectModalOptionsBuild>
    </>
  );
};

export default SelectModalBody;
