import AvishInput from "components/formElement/inputWrapper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { ApplicationState } from "store";

interface IPlanFormElementProps {
  plan?: Iplan | null;
}

const PlanFormElements = ({ plan = null }: IPlanFormElementProps) => {
  const subscriptionTypes = useSelector(
    (state: ApplicationState) => state.subscription?.subscriptionTypes
  );

  const mappedSubscriptionTypes = subscriptionTypes.reduce((attr, el) => {
    let subscriptionType: ISelectData = {
      label: el.type,
      value: el.id.toString(),
    };
    attr.push(subscriptionType);
    return attr;
  }, [] as ISelectData[]);

  const [selectedSubscriptionType, setSelectedSubscriptionType] =
    useState<ISelectData>(mappedSubscriptionTypes[0]);
  const [changeInDefaultValue, setChangeInDefaultValue] =
    useState<boolean>(false);

  useEffect(() => {
    if (plan) {
      setSelectedSubscriptionType({
        label: plan.subscriptionType,
        value: plan?.subscriptionTypeId.toString(),
      });
    } else {
      setSelectedSubscriptionType(mappedSubscriptionTypes[0]);
    }
    setChangeInDefaultValue(true);
  }, [plan]);

  return (
    <>
      <AvishInput
        label="Plan Name*"
        required
        name="name"
        autoFocus
        defaultValue={plan?.name}
      ></AvishInput>

      <div className="form-groups">
        <AvishInput
          label="Minimum License Count*"
          name="minimumLicenceCount"
          type="number"
          required
          min={1}
          maxLength={4}
          defaultValue={plan?.minimumLicenceCount}
        ></AvishInput>
        <AvishInput
          label="Per License Cost*"
          name="perLicenceCost"
          type="number"
          required
          min={1}
          maxLength={4}
          defaultValue={plan?.perLicenceCost}
        ></AvishInput>
      </div>
      <AvishInput
        label="Subscription type*"
        name="subscriptionTypeID"
        className="overflow-auto"
        input={
          <Select
            key={changeInDefaultValue ? "changed" : "ontChanged"}
            className="basic-single"
            classNamePrefix="select"
            defaultValue={{ ...selectedSubscriptionType }}
            isClearable
            isSearchable
            menuPlacement="auto"
            name="subscriptionTypeID"
            options={mappedSubscriptionTypes}
          />
        }
      ></AvishInput>

      <AvishInput
        label="Skuid*"
        name="skuid"
        required
        min={1}
        defaultValue={plan?.skuid}
      ></AvishInput>
    </>
  );
};

export default PlanFormElements;
