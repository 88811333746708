import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import Loader from "components/loader/Loader";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api.service";
import Constants from "../../../constants/Constants";

const AddUserModal = ({ refreshDetails, isOpen, onClose }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [user, setUser] = useState({});
  const [time, setTimeZone] = useState([]);
  const [isSpinning, setSpinning] = useState(false);
  const [isloading, setLoaded] = useState(false);
  const [dropdownOrganization, setDropDownOrganization] = useState([]);
  const [userTypes, setUserTypes] = useState([]);

  useEffect(() => {
    getAllOrganization();
    loadTimeZone();
    getAllUserTypes();
  }, []);

  const onInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const getAllUserTypes = () => {
    api.get(Constants.USERTYPES_GET_URI).then((res) => {
      setUserTypes(res.data);
    });
  };

  const getAllOrganization = () => {
    //setLoaded(true);
    api
      .getWithParams(Constants.ORGANIZATION_GET_URI, dropdownOrganization!)
      .then((res) => setDropDownOrganization(res.data))
      .finally(() => setLoaded(false));
  };

  const onSubmit = () => {
    setSpinning(true);
    api
      .post(
        Constants.USER_CHECK_EXISTS_URI +
          "/" +
          user["email"] +
          "/" +
          user["organizationID"]
      )
      .then((res) => {
        if (res.data) {
          ////console.log(`This user is already part of the organization!`)
          toast.warn("User already part of an organization!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          setSpinning(false);
        } else {
          setSpinning(true);
          api
            .post(Constants.USER_CREATE_POST_URI, user)
            .then(async () => {
              toast.success("😊 User added successfully.", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });
              await onClose();
              refreshDetails();
              setUser({
                title: "",
                first_Name: "",
                middle_Name: "",
                last_Name: "",
                email: "",
                phone: "",
                timezone: "",
              });
            })
            .catch((err) => {
              toast.error(`😔 ${err.response}`, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });
            })
            .finally(() => {
              setSpinning(false);
            });
        }
      });
  };

  const loadTimeZone = () =>
    api.get(Constants.TIMEZONE_GET_URI).then((res) => setTimeZone(res.data));

  const TitleField = register("title", {
    required: true,
  });

  const FirstNameField = register("first_Name", {
    required: true,
  });

  const LastNameField = register("last_Name", {
    required: true,
  });

  const EmailField = register("email", {
    required: true,
  });
  const PhoneNumberField = register("phone", {
    required: true,
  });

  const TimezoneField = register("timezone", {
    required: true,
  });
  const OrganizationField = register("organizationID", {
    required: true,
  });
  const UserTypeField = register("userTypeID", {
    required: true,
  });

  return (
    <div>
      {!isloading && time && dropdownOrganization && userTypes && (
        <CModal show={isOpen} onClose={onClose} closeOnBackdrop={true}>
          <CModalHeader closeButton>
            <CModalTitle>
              {" "}
              <div> Add New User</div>{" "}
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ fontWeight: "bold", fontSize: "12px" }}
            >
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Title*</label>
                    <select
                      {...TitleField}
                      onChange={(e) => {
                        TitleField.onChange(e);
                        onInputChange(e);
                      }}
                      name="title"
                      className="form-control"
                    >
                      <option></option>
                      <option>Ms.</option>
                      <option>Mr.</option>
                      <option>Mrs.</option>
                    </select>
                    <small className="text-danger">
                      {errors.title?.type === "required" && "Title is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>First Name*</label>
                    <input
                      {...FirstNameField}
                      type="text"
                      name="first_Name"
                      onChange={(e) => {
                        FirstNameField.onChange(e);
                        onInputChange(e);
                      }}
                      className="form-control"
                    />
                    <small className="text-danger">
                      {errors.first_Name?.type === "required" &&
                        "First Name is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Middle Name</label>
                    <input
                      type="text"
                      name="middle_Name"
                      placeholder="Optional"
                      onChange={(e) => onInputChange(e)}
                      className="form-control"
                    />
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Last Name*</label>
                    <input
                      {...LastNameField}
                      type="text"
                      name="last_Name"
                      onChange={(e) => {
                        LastNameField.onChange(e);
                        onInputChange(e);
                      }}
                      className="form-control"
                    />
                    <small className="text-danger">
                      {errors.last_Name?.type === "required" &&
                        "Last Name is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>E-mail*</label>
                    <input
                      {...EmailField}
                      type="email"
                      className="form-control"
                      name="email"
                      onChange={(e) => {
                        EmailField.onChange(e);
                        onInputChange(e);
                      }}
                    />
                    <small className="text-danger">
                      {errors.email?.type === "required" && "Email is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Phone Number*</label>
                    <input
                      {...PhoneNumberField}
                      type="text"
                      className="form-control "
                      name="phone"
                      onChange={(e) => {
                        PhoneNumberField.onChange(e);
                        onInputChange(e);
                      }}
                    />
                    <small className="text-danger">
                      {errors.phone?.type === "required" &&
                        "Phone Number is Required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Timezone*</label>
                    <select
                      {...TimezoneField}
                      onChange={(e) => {
                        TimezoneField.onChange(e);
                        onInputChange(e);
                      }}
                      className="form-control"
                      name="timezone"
                    >
                      <option value={user["timezone"]}>
                        {user["displayName"]}
                      </option>
                      {time.map((temp) => {
                        return (
                          <>
                            <option value={temp["id"]}>
                              {temp["displayName"]}
                            </option>
                          </>
                        );
                      })}
                    </select>
                    <small className="text-danger">
                      {errors.timezone?.type === "required" &&
                        "Timezone is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Organization*</label>
                    <select
                      {...OrganizationField}
                      name="organizationID"
                      onChange={(e) => {
                        OrganizationField.onChange(e);
                        onInputChange(e);
                      }}
                      className="form-control"
                    >
                      <option></option>
                      {[...dropdownOrganization].map((item) => {
                        return (
                          <option value={item["organizationID"]}>
                            {item["name"]}
                          </option>
                        );
                      })}
                    </select>
                    <small className="text-danger">
                      {errors.organizationID?.type === "required" &&
                        "Organization is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>UserType*</label>
                    <select
                      {...UserTypeField}
                      name="userTypeID"
                      onChange={(e) => {
                        UserTypeField.onChange(e);
                        onInputChange(e);
                      }}
                      className="form-control"
                    >
                      <option></option>
                      {userTypes.map((item) => {
                        return (
                          <>
                            <option value={item["id"]}>{item["type"]}</option>
                          </>
                        );
                      })}
                    </select>
                    <small className="text-danger">
                      {errors.userTypeID?.type === "required" &&
                        "User Type is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol></CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Description</label>
                    <textarea
                     placeholder="Optional"
                      name="description"
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                    ></textarea>
                  </CFormGroup>
                </CCol>
              </CRow>

              {!isSpinning && (
                <div
                  style={{
                    placeItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <CButton
                    style={{ background: "var(--primary)",  color: "white" }}
                    type="submit"
                    size="sm"
                  >
                    Add
                  </CButton>

                  <div className="ml-1">
                    <CButton color="danger" onClick={onClose} size="sm">
                      Close
                    </CButton>
                  </div>
                </div>
              )}
              {isSpinning && (
                <div
                  style={{
                    placeItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <CButton
                    disabled
                    style={{background: "var(--primary)",  color: "white" }}
                    size="sm"
                  >
                    <i className="fa fa-spinner fa-spin"></i> Adding
                  </CButton>
                  <div className="ml-1">
                    <CButton color="danger" onClick={onClose} size="sm">
                      Close
                    </CButton>
                  </div>
                </div>
              )}
            </form>
          </CModalBody>
        </CModal>
      )}
      {isloading && <Loader />}
    </div>
  );
};

export default AddUserModal;
