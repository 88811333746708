
import commonConstants from "constants/commonConstants";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import organizationApi from "services/apis/organization.api";
import { ApplicationState } from "store";
import { setLeadSearchValue } from "store/leads/action";
import {
  setOrganizationFilters
} from "store/organization/action";

interface IOrganizationFilter{
  cPage: number;
  setIsSearchActive: React.Dispatch<React.SetStateAction<boolean>>;
  onPageChange: any;

}

const OrganizationFilter = ({ cPage, onPageChange,setIsSearchActive }:IOrganizationFilter) => {
  const dispatch: Dispatch<any> = useDispatch();
  const { countries } = useSelector((state: ApplicationState) => state.global);
  const [ddnData, setDdnData] = useState<any>();
  const [showFields, setShowFields] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const dateRangeRef = useRef<HTMLDivElement | null>(null);
  const isFirstMounted=useRef(true)

  const [openSelects, setOpenSelects] = useState({
    country: false,
    status: false,
    AccountType:false,
  });


  const organizationFilters = useSelector(
    (state: ApplicationState) => state.organization.organizationFilters
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch
  } = useForm();

  const selectDropdownSearch = (data) => {
    setDdnData(
      data.map((item) => {
        return { label: item.name, value: item.id };
      })
    );
  };

  const clearFilters = () => {
    setSelectedDateRange("Select Date Range");
    organizationFilters.ID = null;
    organizationFilters.BeginDate = null;
    organizationFilters.EndDate = null;
    organizationFilters.IsActive = null;
    organizationApi.filterOrganization(
      organizationFilters,
      dispatch,
      commonConstants.PAGE_SIZE,
      cPage
    );
    onPageChange(1); // set the initial page number here
    setValue("EndDate", undefined);
    setShowFields(false);
    setIsSearchActive(false);
    dispatch(setLeadSearchValue(""));
  };

  const clearDateFilters = () => {
    setSelectedDateRange("Select Date Range");
    organizationFilters.BeginDate = null;
    organizationFilters.EndDate = null;
    organizationApi.filterOrganization(
      organizationFilters,
      dispatch,
      commonConstants.PAGE_SIZE,
      cPage
    );
    onPageChange(1); // set the initial page number here
    setValue("EndDate", undefined);
    setShowFields(false);
  };

  useEffect(() => {
    selectDropdownSearch(countries);
  }, [countries]);

  // const EndDateField = register("EndDate", {
  //   required: true,
  // });

  const loadDateRange = () => {
    organizationApi.filterOrganization(
      organizationFilters,
      dispatch,
      commonConstants.PAGE_SIZE,
      cPage
    );
    onPageChange(1);
    dispatch(setLeadSearchValue(""));
  };

  // function onSelected(e) {
  //   organizationFilters.ID = e.value;
  // }

  function handleChange(
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) {
    const selectedValue = e.target.value;
    setSelectedDateRange(selectedValue);
    if (selectedValue == "Select Date Range") {
      setValue("BeginDate", null)
      setValue("EndDate", undefined);
      dispatch(
        setOrganizationFilters({
          ...organizationFilters,
          BeginDate: null,
          EndDate: null,
        })
      );

      return;
    }

    let startDate;
    let endDate = new Date();
    if (selectedValue.includes("Last")) {
      switch (selectedValue) {
        case "Last 30 Days":
          startDate = new Date();
          startDate.setDate(endDate.getDate() - 30);
          break;
        case "Last 60 Days":
          startDate = new Date();
          startDate.setDate(endDate.getDate() - 60);
          break;
        case "Last 90 Days":
          startDate = new Date();
          startDate.setDate(endDate.getDate() - 90);
          break;
        case "Last Week":
          startDate = new Date();
          startDate.setDate(endDate.getDate() - 7);
          break;
      }

      dispatch(
        setOrganizationFilters({
          ...organizationFilters,
          BeginDate: startDate.toISOString().split("T")[0],
          EndDate: endDate.toISOString().split("T")[0],
        })
      );
      setValue("EndDate", endDate.toISOString().split("T")[0])
    } else {
      if (e.target.name === "EndDate") {
        setValue("EndDate", endDate.toISOString().split("T")[0])
      }
      dispatch(
        setOrganizationFilters({
          ...organizationFilters,
          [e.target.name]: e.target.value?.length ? e.target.value : null,
        })
      );
    }
  }
  const checkEndData = watch("EndDate");

  //used to change the the date range popup open and close
  const handleClicks = () => {
    setShowFields((prev) => !prev);
  };

  //used to change the select tag icon
  const handleSelectClick = (selectName) => {
    setOpenSelects(prev => ({
      ...prev,
      [selectName]: !prev[selectName]
    }));
  };

  //used to close the date range popup when click outside the popup box
  useEffect(() => {
    if (organizationFilters.BeginDate !== null && checkEndData === undefined) {
      return;
    } else {
      const handleClickOutside = (event: MouseEvent) => {
        if (dateRangeRef.current && !dateRangeRef.current.contains(event.target as Node)) {
          if (dateRangeRef.current) {
            setShowFields(false)
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [organizationFilters.BeginDate, checkEndData]);

  //used to call the organization data when filter value is changed.


  useEffect(() => {
    if(isFirstMounted.current){
      isFirstMounted.current=false
    }else{
      const timer = setTimeout(() => {
        if (selectedDateRange !== "Select Date Range" && organizationFilters.BeginDate == null && organizationFilters.EndDate == null || selectedDateRange !== "Select Date Range" && organizationFilters.BeginDate != null && organizationFilters.EndDate != null) {
          loadDateRange()
          setShowFields(false);
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [organizationFilters])

  return (
 
      <form className="filter"
        onSubmit={handleSubmit(loadDateRange)}
      >
        <div>
          <label>Country</label>
          {/* <Select
            className="select-container"
            placeholder="select..."
            options={ddnData}
            onChange={(e) => {
              onSelected(e)
            }}
          /> */}
          <div className="select-wrapper">
            <select
              name="ID"
              className="form-control"
              onChange={(e) => handleChange(e)}
              defaultValue={organizationFilters?.ID?.toString()}
              onClick={() => handleSelectClick('country')}
              onBlur={() => setOpenSelects(prev => ({ ...prev, country: false }))}
            >
              <option value="">Select a Country</option>
              {[...countries].map((item, index) => {
                return (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {openSelects.country ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
          </div>
        </div>

        <div >
          <label>Status</label>
          <div className="select-wrapper">
            <select
              name="IsActive"
              onChange={(e) => handleChange(e)}
              className="form-control"
              defaultValue={organizationFilters?.IsActive?.toString()}
              onClick={() => handleSelectClick('status')}
              onBlur={() => setOpenSelects(prev => ({ ...prev, status: false }))}
            >
              <option value="">Select Status</option>
              <option value="true">Active</option>
              <option value="false">InActive</option>
            </select>
            {openSelects.status ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
          </div>

        </div>

        <div >
          <label>Account Type</label>
          <div className="select-wrapper">
            <select
              name="AccountType"
              onChange={(e) => handleChange(e)}
              className="form-control"
              defaultValue={""}
              onClick={() => handleSelectClick('AccountType')}
              onBlur={() => setOpenSelects(prev => ({ ...prev, AccountType: false }))}
            >
              <option value="">Select Account Type</option>
              <option value="personal">Personal</option>
              <option value="organization">Organization</option>
            </select>
            {openSelects.AccountType ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
          </div>

        </div>

        <div className="relative">
          <label>Date Range</label>

          <div className="date-range">
            <input
              readOnly
              className="form-control"
              placeholder={`${organizationFilters.BeginDate ? organizationFilters.BeginDate : "Select a Date Range"} ${organizationFilters.EndDate ? "-" : ""} ${organizationFilters.EndDate ? organizationFilters.EndDate : ""}`}
            ></input>
            {
              showFields ? <RiArrowDropUpLine onClick={handleClicks} /> : <RiArrowDropDownLine onClick={handleClicks} />
            }
          </div>

          {showFields && (
            <div
              ref={dateRangeRef}
              className={`dateRangeRef ${showFields ? 'show' : 'hide'}`}>
              <div className="flex" style={{ gap: "0.5rem", alignItems: "stretch", flexWrap: "wrap" }}>
                <fieldset>
                  <div className="tmf-form-group">
                    <label style={{ color: "black" }}>Date Range</label>
                    <select
                      className="form-control"
                      onChange={(e) => handleChange(e)}
                      value={selectedDateRange}
                    >
                      <option value="Select Date Range">Select Date Range</option>
                      <option value="Last 30 Days">Last 30 Days</option>
                      <option value="Last 60 Days">Last 60 Days</option>
                      <option value="Last 90 Days">Last 90 Days</option>
                      <option value="Last Week">Last Week</option>
                    </select>
                  </div>
                </fieldset>
                <fieldset>
                  <div className="tmf-form-group">
                    <label>Start Date</label>
                    <input
                      placeholder="date"
                      className="form-control sm"
                      type="date"
                      name="BeginDate"
                      value={`${organizationFilters.BeginDate}`}
                      onChange={(e) => handleChange(e)}

                    />
                  </div>
                </fieldset>

                <fieldset>
                  {organizationFilters.BeginDate ? (
                    <div className="tmf-form-group">
                      <label>End Date</label>

                      <input
                        placeholder="date"
                        className="form-control"
                        type="date"
                        value={`${organizationFilters.EndDate}`}
                        name="EndDate"
                        // {...EndDateField}
                        onChange={(e) => {
                          // EndDateField.onChange(e);
                          handleChange(e);
                        }}
                        min={organizationFilters.BeginDate as string}
                      />
                      <small className="text-danger">
                        {/* {errors.EndDate?.type === "required" &&
                          "End Date is required"} */}
                        {checkEndData === undefined ? "End Date is required" : ""}
                      </small>
                    </div>
                  ) : (
                    <div className="tmf-form-group">
                      <label>End Date</label>
                      <input disabled className="form-control" type="date" value={`${organizationFilters.EndDate}`} />
                    </div>
                  )}
                </fieldset>
              </div>


              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "12px",
                  gap: "20px",
                }}
              >
                {/* <div className="">
                  <button
                    className="apply"
                    type="submit"
                    disabled={checkEndData === undefined ? true : false}
                    onClick={() => {
                      loadDateRange();
                      setShowFields(!showFields);
                    }}
                  >
                    Apply
                  </button>
                </div> */}
                <div className="">
                  <button
                    className="clear"
                    type="reset"
                    onClick={() => clearDateFilters()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div style={{ marginTop: "25px" }}>
          <button
            className="apply"
            type="submit"
            onClick={() => loadDateRange()}
          >
            Apply
          </button>
        </div>

        <div style={{ marginTop: "25px" }}>
          <button className="clear" type="reset" onClick={() => clearFilters()}>
            Clear
          </button>
        </div>

      </form>
  
  );
};

export default OrganizationFilter;
