import { OfferActionTypes } from "./action.types";

export function setAllOffers(allOffers: IOffer[]) {
  const action: OfferAction = {
    type: OfferActionTypes.SET_ALL_OFFERS,
    allOffers,
  };
  return (dispatch: OfferDispatchType) => dispatch(action);
}

export function setAllOfferLoader(isAllOffersLoading: boolean) {
  const action: OfferAction = {
    type: OfferActionTypes.SET_ALL_OFFERS_LOADER,
    isAllOffersLoading,
  };
  return (dispatch: OfferDispatchType) => dispatch(action);
}

export function addOffer(offer: IOffer) {
  const action: OfferAction = {
    type: OfferActionTypes.ADD_OFFER,
    offer,
  };
  return (dispatch: OfferDispatchType) => dispatch(action);
}

export function updateOffer(offer: IOffer) {
  const action: OfferAction = {
    type: OfferActionTypes.UPDATE_OFFER,
    offer,
  };
  return (dispatch: OfferDispatchType) => dispatch(action);
}

export function ViewOffer(offer: IOffer) {
  const action: OfferAction = {
    type: OfferActionTypes.VIEW_OFFER,
    offer,
  };
  return (dispatch: OfferDispatchType) => dispatch(action);
}

export function setOfferModal(addOfferModal: IOfferModal) {
  const action: OfferAction = {
    type: OfferActionTypes.SET_OFFER_MODAL,
    addOfferModal,
  };
  return (dispatch: OfferDispatchType) => dispatch(action);
}

export function resetOfferModal() {
  const action: OfferAction = {
    type: OfferActionTypes.RESET_OFFER_MODAL,
  };
  return (dispatch: OfferDispatchType) => dispatch(action);
}
export function deleteOffer(offer: IOffer) {
  const action: OfferAction = {
    type: OfferActionTypes.DELETE_OFFER,
    offer,
  };
  return (dispatch: PlanDispatchType) => dispatch(action);
}

export function setEditOfferModal(editOfferModal: IEditOfferModal) {
  const action: OfferAction = {
    type: OfferActionTypes.EDIT_OFFER,
    editOfferModal,
  };
  return (dispatch: OfferDispatchType) => dispatch(action);
}

export function resetEditOfferModal() {
  const action: OfferAction = {
    type: OfferActionTypes.RESET_EDIT_OFFER_MODAL,
  };
  return (dispatch: OfferDispatchType) => dispatch(action);
}
export function setViewOfferModal(viewOfferModal: IViewOfferModal) {
  const action: OfferAction = {
    type: OfferActionTypes.VIEW_OFFER_MODAL,
    viewOfferModal,
  };
  return (dispatch: OfferDispatchType) => dispatch(action);
}

export function resetViewOfferModal() {
  const action: OfferAction = {
    type: OfferActionTypes.RESET_VIEW_OFFER_MODAL,
  };
  return (dispatch: OfferDispatchType) => dispatch(action);
}
export function setDeleteOfferModal(deleteOfferModal: IDeleteOfferModal) {
  const action: OfferAction = {
    type: OfferActionTypes.DELETE_OFFER_MODAL,
    deleteOfferModal,
  };
  return (dispatch: OfferDispatchType) => dispatch(action);
}

export function resetDeleteOfferModal() {
  const action: OfferAction = {
    type: OfferActionTypes.RESET_DELETE_OFFER_MODAL,
  };
  return (dispatch: OfferDispatchType) => dispatch(action);
}
