import TmfDropdown from "components/dropdown/dropdown";
import TmfDropdownItem from "components/dropdown/dropdownItem";
import { Dispatch } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { GoPencil } from "react-icons/go";
import { useDispatch } from "react-redux";
import { openDeleteTagModal, openEditTagModal } from "store/tag/action";



const TagActions = ({ tag}) => {
    const dispatch: Dispatch<any> = useDispatch();

    

    return (
        <div style={{ display: "flex", height: "40px" }}>
            <TmfDropdown name="court">
                <TmfDropdownItem
                    icon={<GoPencil />}
                    text="Edit"
                    props={{
                        onClick: () => {
                            dispatch(
                                openEditTagModal({
                                    showModal: true,
                                    isModalFormSubmitting: false,
                                    tag:tag
                                })
                            );
                        },
                    }}
                ></TmfDropdownItem>
                <TmfDropdownItem
                    icon={<FaRegTrashAlt />}
                    text="Delete"
                    props={{
                        onClick: () => {
                            dispatch(
                                openDeleteTagModal({
                                    showModal: true,
                                    isModalFormSubmitting: false,
                                    tag:tag
                                })
                            )
                        },
                    }}
                ></TmfDropdownItem>

            </TmfDropdown>
        </div>
    );
};

export default TagActions;
