import React, { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { CModal, CModalBody, CModalHeader, CModalTitle } from "@coreui/react";
import { TagMyFavLogo } from "../logo/Logo";

interface IconData {
    [key: string]: string;
}

interface IconSelectorProps {
    iconOptions: IconData;
    selectedIcon: string | null;
    setSelectedIcon: React.Dispatch<React.SetStateAction<string | null>>;
}

const IconSelector: React.FC<IconSelectorProps> = ({ iconOptions, selectedIcon, setSelectedIcon }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectIcon = (icon: string) => {
        setSelectedIcon(icon);
        setIsOpen(false);
    };

    return (
        <div className="icon-selector">
            <div>
                {/* Main icon */}
                <div className="icon-display" onClick={toggleDropdown} >
                    {selectedIcon ? (
                        <>
                            <div className="icon">
                                {selectedIcon === null ? (
                                    <img
                                        src={TagMyFavLogo}
                                        alt={"Logo"}
                                    />
                                ) : selectedIcon.includes("base") && !selectedIcon.includes("/img") ? (
                                    <img

                                        src={selectedIcon}
                                        alt={"Logo"}
                                    />
                                ) : selectedIcon.includes("/img") ? (
                                    <img

                                        src={TagMyFavLogo}
                                        alt={"Logo"}
                                    />
                                ) : selectedIcon.startsWith("<svg") ? (
                                    <span
                                        dangerouslySetInnerHTML={{ __html: selectedIcon }}
                                    />
                                ) : (
                                    <img
                                        src={`data:image/jpeg;base64,${selectedIcon}`}
                                        alt={"Logo"}
                                    />
                                )}
                            </div>

                            <RiArrowDropDownLine />
                        </>
                    ) : (
                        <>
                            <div
                                className="h-8 w-8 cursor-pointer text-gray-500"
                                dangerouslySetInnerHTML={{ __html: iconOptions.folder }}
                            />
                            <RiArrowDropDownLine className="text-larger-heading cursor-pointer" />
                        </>
                    )}
                </div>

                <CModal show={isOpen} onClose={toggleDropdown} closeOnBackdrop={true}>
                    <CModalHeader closeButton>
                        <CModalTitle>
                            {" "}
                            <div> Select an icon</div>{" "}
                        </CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <div >
                            <div className="icon-box" >
                                {Object.entries(iconOptions).map(([key, value]) => (
                                    <div
                                        key={key}
                                        onClick={() => selectIcon(value)}
                                    >
                                        <div style={{
                                            height:"2rem",
                                            width: "2rem"
                                        }}
                                            dangerouslySetInnerHTML={{ __html: value }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </CModalBody>
                </CModal>
            </div>
        </div>
    );
};

export default IconSelector;
