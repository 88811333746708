import { CDropdownItem } from "@coreui/react";

interface ITmfDropdownItem {
  icon?: React.ReactNode;
  text: string;
  props?: CDropdownItem;
  color?:string|null;
}

const TmfDropdownItem = ({ icon, text, color, props }: ITmfDropdownItem) => {
  return (
    <CDropdownItem className={`tmf-dropdown__item bg-${color} ${(color && color!=='white')?'text-white':''}`} {...props}>
      {icon && (
        <div className="tmf-dropdown__item__icon">
          {icon}
        </div>
      )}

      <div className="tmf-dropdown__item__text">{text}</div>
    </CDropdownItem>
  );
};

export default TmfDropdownItem;
