import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import "../src/index.css"


import { Provider } from "react-redux";
import { configureStore } from "configureStore";

const initialState: any = {};
const store = configureStore(initialState);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
  
        <App />
 
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
