const DataTableNoContent = () => {
  return (
    <div className="datatable-nocontent">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300 300"
        version="1.1"
      >
        <defs id="defs2" />
        <g
          id="g45"
          transform="matrix(0.26458333,0,0,0.26458333,24.308636,48.573332)"
        >
          <path
            d="M 618.24596,95.44019 H 333.08633 a 24.540787,25.141132 0 0 0 -24.51091,25.11053 v 649.27463 l -3.26812,1.02125 -69.95426,21.9466 A 13.083535,13.4036 0 0 1 219.0292,783.90408 L 10.947311,87.58897 A 13.077604,13.397523 0 0 1 19.624273,70.86535 L 127.42365,37.04984 439.93784,-60.948196 547.73639,-94.763711 a 13.054955,13.374321 0 0 1 16.32466,8.872388 L 617.24942,92.09212 Z"
            fill="#f2f2f2"
            id="path9"
            style={{ strokeWidth: 1.65392 }}
          />
          <path
            d="m 680.47117,92.09212 -64.10421,-214.5109 A 27.768712,28.448022 0 0 0 581.67584,-141.3019 L 430.11667,-93.77603 117.61927,4.2387799 -33.939907,51.78138 A 27.803877,28.484047 0 0 0 -52.372574,87.32115 L 166.72248,820.43148 a 27.822521,28.503148 0 0 0 26.57025,20.13864 27.434677,28.105816 0 0 0 8.12083,-1.25552 l 103.89374,-32.57673 3.26812,-1.0379 v -3.49874 l -3.26812,1.02116 -104.85757,32.8948 A 24.535248,25.135457 0 0 1 169.84376,819.46054 L -49.234539,86.33347 A 24.477173,25.075962 0 0 1 -32.976105,54.97879 L 118.58307,7.43618 431.08131,-90.561849 582.64047,-138.10445 a 24.76503,25.37086 0 0 1 7.20566,-1.10487 24.53479,25.134989 0 0 1 23.40028,17.76152 l 63.80976,213.53995 1.01331,3.34808 h 3.39898 z"
            fill="#3f3d56"
            id="path11"
            style={{
              fill: "#4f7dcc",
              fillOpacity: 0.705882,
              strokeWidth: 1.65392,
            }}
          />
          <path
            d="M 146.87635,71.41273 A 14.732205,15.092601 0 0 1 132.80491,60.75465 L 111.75759,-9.6750001 A 14.704654,15.064377 0 0 1 121.5149,-28.49155 l 287.49508,-90.16985 a 14.720717,15.080833 0 0 1 18.36802,9.99516 l 21.04731,70.43047 a 14.723364,15.083544 0 0 1 -9.75649,18.81736 L 151.17295,70.75145 a 14.667333,15.026142 0 0 1 -4.2966,0.66128 z"
            fill="#6c63ff"
            id="path13"
            style={{
              fill: "#6985b7",
              fillOpacity: 1,
              strokeWidth: 1.65392,
            }}
          />
          <circle
            cx="257.13065"
            cy="-100.78885"
            fill="#6c63ff"
            id="circle15"
            style={{
              fill: "#6985b7",
              fillOpacity: "1",
              strokeWidth: "1.65392",
            }}
            rx="32.681221"
            ry="33.480709"
          />
          <circle
            cx="257.13065"
            cy="-100.78885"
            fill="#ffffff"
            id="circle17"
            rx="20.694763"
            ry="21.201023"
            style={{ strokeWidth: "1.65392" }}
          />
          <path
            d="M 931.15308,832.01577 H 378.84042 A 13.90555,14.245723 0 0 1 364.9509,817.78647 V 139.80213 a 13.905059,14.245221 0 0 1 13.88952,-14.2293 h 552.31266 a 13.905566,14.24574 0 0 1 13.88952,14.2293 v 677.98434 a 13.906072,14.246259 0 0 1 -13.88952,14.2293 z"
            fill="#e6e6e6"
            id="path19"
            style={{ strokeWidth: 1.65392 }}
          />
          <path
            d="M 677.0562,92.09212 H 333.08633 a 27.818436,28.498963 0 0 0 -27.77903,28.4586 v 682.67164 l 3.26812,-1.02116 V 120.55072 a 24.540787,25.141132 0 0 1 24.51091,-25.11053 h 344.98318 z m 299.85021,0 H 333.08633 a 27.818436,28.498963 0 0 0 -27.77903,28.4586 v 766.70825 a 27.81842,28.498946 0 0 0 27.77903,28.4586 h 643.82008 a 27.81842,28.498946 0 0 0 27.77899,-28.4586 V 120.55072 a 27.818436,28.498963 0 0 0 -27.77899,-28.4586 z m 24.51089,795.16685 a 24.540787,25.141132 0 0 1 -24.51089,25.1105 H 333.08633 a 24.540787,25.141132 0 0 1 -24.51091,-25.1105 V 120.55072 a 24.540787,25.141132 0 0 1 24.51091,-25.11053 h 643.82008 a 24.540787,25.141132 0 0 1 24.51089,25.11053 z"
            fill="#3f3d56"
            id="path21"
            style={{
              fill: "#507dcc",
              fillOpacity: 1,
              strokeWidth: 1.65392,
            }}
          />
          <path
            d="M 805.33037,165.74968 H 504.66313 A 14.723136,15.08331 0 0 1 489.95658,150.68336 V 77.0258 a 14.723152,15.083327 0 0 1 14.70655,-15.06632 h 300.66724 a 14.723152,15.083327 0 0 1 14.70655,15.06632 v 73.65756 a 14.723136,15.08331 0 0 1 -14.70655,15.06632 z"
            fill="#6c63ff"
            id="path23"
            style={{
              fill: "#6985b7",
              fillOpacity: 1,
              strokeWidth: 1.65392,
            }}
          />
          <circle
            cx="654.9964"
            cy="33.500889"
            fill="#6c63ff"
            id="circle25"
            style={{
              fill: "#6985b7",
              fillOpacity: 1,
              strokeWidth: 1.65392,
            }}
            rx="32.681221"
            ry="33.480709"
          />
          <circle
            cx="654.9964"
            cy="33.500889"
            fill="#ffffff"
            id="circle27"
            rx="19.90592"
            ry="20.392883"
            style={{ strokeWidth: 1.65392 }}
          />
        </g>
      </svg>
      <div className="datatable-nocontent__message">No Content</div>
    </div>
  );
};

export default DataTableNoContent;
