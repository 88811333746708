import {
    CButton,
    CCol,
    CFormGroup,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api.service";
import Constants from "../../../constants/Constants";

interface IAddTagModal {
    refreshDetails: () => void,
    onClose: any,
    isOpen: boolean,
    title: string,

}

const AddTagModal: React.FC<IAddTagModal> = ({ refreshDetails, isOpen, onClose, title }) => {

    const [isSpinning, setSpinning] = useState(false);
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm();

    useEffect(()=>{
        setValue("IsGlobal",true)
    },[])


    const onSubmit = async (data: any) => {
        setSpinning(true);
        try {
            // Add Tag
            const res = await api.post(Constants.POST_GLOBAL_TAG, data);
            toast.success("😊Tags added successfully.", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            await onClose();
            refreshDetails();
        } catch (err:any) {
            // Handle errors
            if(err.status==409){
                toast.error("Tag Already Exist", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }else if(err.status==400){
                toast.error("Name Cannot be empty", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }else{
                toast.error("Something went wrong!!", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        } finally {
            setSpinning(false);
        }
    }

    return (
        <div>
            <CModal show={isOpen} onClose={onClose} closeOnBackdrop={true}>
                <CModalHeader closeButton>
                    <CModalTitle>
                        {" "}
                        <div> {title}</div>{" "}
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ fontWeight: "bold", fontSize: "12px" }}
                    >
                        <div className="form-title">
                            <label>Title*</label>
                            <input
                                type="text"
                                className="form-control "
                                {...register("Name", { required: true, maxLength: 60 })}
                                maxLength={60}
                            />
                            <small className="text-danger">
                                {errors.Name?.type === "required" && "Tag Name is required"}
                                {errors.Name?.type === "maxLength" && "Tag Name must not exceed 60 characters"}
                            </small>
                        </div>
                        <br />

                        <CRow>
                            <CCol>
                                <CFormGroup>
                                    <label>Description</label>
                                    <textarea
                                        placeholder="Optional"

                                        className="form-control"
                                        {...register("Description", { maxLength: 200 })}
                                        maxLength={200}
                                    ></textarea>
                                    <small className="text-danger">
                                        {errors.Description?.type === "maxLength" && "Collection Description must not exceed 60 characters"}
                                    </small>
                                </CFormGroup>
                            </CCol>
                        </CRow>

                        {!isSpinning && (
                            <div
                                style={{
                                    placeItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    display: "flex",
                                    marginTop: "10px",
                                    float: "right",
                                }}
                            >
                                <CButton
                                    style={{ background: "var(--primary)", color: "white" }}
                                    type="submit"
                                    size="sm"
                                >
                                    Add
                                </CButton>

                                <div className="ml-1">
                                    <CButton color="danger" onClick={onClose} size="sm">
                                        Close
                                    </CButton>
                                </div>
                            </div>
                        )}


                        {isSpinning && (
                            <div
                                style={{
                                    placeItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    display: "flex",
                                    marginTop: "10px",
                                    float: "right",
                                }}
                            >
                                <CButton
                                    disabled
                                    style={{  background: "var(--primary)", color: "white" }}
                                    size="sm"
                                >
                                    <i className="fa fa-spinner fa-spin"></i> Adding
                                </CButton>
                                <div className="ml-1">
                                    <CButton color="danger" onClick={onClose} size="sm">
                                        Close
                                    </CButton>
                                </div>
                            </div>
                        )}
                    </form>
                </CModalBody>
            </CModal>
        </div>
    );
};

export default AddTagModal;
