import { Dispatch } from "redux";
import apiUrls from "constants/Constants";
import api from "services/api.service";
import { addPlan, setAddPlanModal, setAllPlans, setEditPlanModal, setDeletePlanModal, deletePlan, updatePlan, setViewPlanModal, viewPlan, setPlanFilterDataLoader } from "store/plan/action";

const planApi = {
  filterPlans: (payload: IPlanFilter, dispatch: Dispatch<any>, pageSize, pageNo) => {
    dispatch(setPlanFilterDataLoader(true));
    return api
      .post(`${apiUrls.PLAN_FILTER_URI}/${pageSize}/${pageNo}`, payload)
      .then((res) => {
        dispatch(setAllPlans(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => dispatch(setPlanFilterDataLoader(false)));
  },

  fetchAllPlans: function (dispatch: Dispatch<any>) {
    return api
      .get(apiUrls.PLAN_GET_URI)
      .then((res) => {
        dispatch(setAllPlans(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  createNewPlan: function (dispatch: Dispatch<any>, payload: IPlanFormData) {
    dispatch(setAddPlanModal({ isModalFormSubmitting: true }));
    return api
      .post(apiUrls.PLAN_CREATE_POST_URI, payload)
      .then((res) => {
        dispatch(addPlan(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() =>
        dispatch(setAddPlanModal({ isModalFormSubmitting: false }))
      );
  },

  updatePlan: function (dispatch: Dispatch<any>, payload: IPlanFormData, planId?: number) {
    dispatch(setEditPlanModal({ isModalFormSubmitting: true }));
    return api
      .post(`${apiUrls.PLAN_UPDATE_POST_URI}/${planId}`, payload)
      .then((res) => {
        dispatch(updatePlan(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() =>
        dispatch(setEditPlanModal({ isModalFormSubmitting: false }))
      );
  },

  viewPlan: function (dispatch: Dispatch<any>,planId?: number) {
    dispatch(setViewPlanModal({ isModalFormSubmitting: false }));
    return api
      .post(`${apiUrls.PLAN_GETBYID_POST_URI}/${planId}`)
      .then((res) => {
        dispatch(viewPlan(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() =>
        dispatch(setViewPlanModal({ isModalFormSubmitting: false }))
      );
  },

  deletePlan: function (dispatch: Dispatch<any>, planId?: number) {
    dispatch(setDeletePlanModal({ isModalFormSubmitting: false }));
    return api
      .post(`${apiUrls.PLAN_DELETE_POST_URI}/${planId}`)
      .then((res) => {
        dispatch(deletePlan(res.data));
        //console.log(res.data);
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() =>
        dispatch(setDeletePlanModal({ isModalFormSubmitting: false }))
      );
  },
};

export default planApi;
