import AvishInput from "components/formElement/inputWrapper";

interface IOfferFormElementProps {
  offer?: IOffer | null;
}

const OfferFormElements = ({ offer = null }: IOfferFormElementProps) => {
  return (
    <>
      <AvishInput label="Offer Name" name="offerName" autoFocus defaultValue={offer?.offerName}></AvishInput>

      <div className="form-groups">
        <AvishInput
          label="Discount Value*"
          name="discountValue"
          type="number"
          required
          min={1}
          maxLength={4}
          defaultValue={offer?.discountValue}
        ></AvishInput>
        <AvishInput
          label="Discount Unit*"
          name="discountUnit"
          type="number"
          required
          min={1}
          maxLength={4}
          defaultValue={offer?.discountUnit}
        ></AvishInput>
      </div>
      <div className="form-groups">
        {" "}
        <AvishInput
          label="Minimum Order Value*"
          name="minimumOrderValue"
          type="number"
          required
          min={1}
          maxLength={4}
          defaultValue={offer?.minimumOrderValue}
        ></AvishInput>{" "}
        <AvishInput
          label="Maximum Discount Amount*"
          name="maximumDiscountAmount"
          type="number"
          required
          min={1}
          maxLength={4}
          defaultValue={offer?.maximumDiscountAmount}
        ></AvishInput>
      </div>

      <AvishInput
        label="Offer Description"
        name="offerDescription"
        inputType="textarea"
        defaultValue={offer?.offerDescription}
      ></AvishInput>
    </>
  );
};

export default OfferFormElements;
