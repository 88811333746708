export enum BookmarkActionTypes {
    SET_BOOKMARK_BY_ID_LOADER = "@@bookmark/SET_BOOKMARK_BY_ID_LOADER",
    SET_BOOKMARK_BY_ID="@@bookmark/SET_BOOKMARK_BY_ID",


    //modal
    SET_ADD_BOOKMARK_MODAL="@@bookmark/SET_ADD_BOOKMARK_MODAL",
    SET_EDIT_BOOKMARK_MODAL="@@bookmark/SET_EDIT_BOOKMARK_MODAL",
    SET_DELETE_BOOKMARK_MODAL="@@bookmark/SET_DELETE_BOOKMARK_MODAL",
    SET_TAG_BOOKMARK_MODAL="@@bookmark/SET_TAG_BOOKMARK_MODAL",
  }