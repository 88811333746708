import { IconActionTypes } from "./action.types";

export function setIcons(icons: IIcon[]) {
  const action: IconAction = {
    type: IconActionTypes.SET_ICONS,
    icons,
  };
  return (dispatch: OrganizationDispatchType) => dispatch(action);
}

export function setIconsLoader(isIconsLoading: boolean) {
  const action: IconAction = {
    type: IconActionTypes.SET_ICONS_LOADER,
    isIconsLoading,
  };
  return (dispatch: OrganizationDispatchType) => dispatch(action);
}
