import { CModalBody } from "@coreui/react";
import AvishModal from "components/modal/modal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ApplicationState } from "store";
import { resetViewPartnerModal } from "store/partner/action";

const ViewpartnerModal = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const viewPartnerModal = useSelector(
    (state: ApplicationState) => state.partner?.viewPartnerModal
  );

  function closeHandler() {
    dispatch(resetViewPartnerModal());
  }

  const ModalIconBuild = () => {
    return (
      <svg
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <defs>
          <linearGradient x1="16%" x2="84%" y1="0%" y2="100%" id="linear">
            <stop offset="0%" stopColor="#00B5FF" stopOpacity="1"></stop>
            <stop offset="55%" stopColor="#2F578A" stopOpacity="1"></stop>
          </linearGradient>
        </defs>
        <path
          stroke="url(#linear)"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
    );
  };
  return viewPartnerModal?.showModal ? (
    <AvishModal
      submitButtonType="submit"
      submitButtonValue="close"
      title="View Partner"
      icon={<ModalIconBuild></ModalIconBuild>}
      onClose={closeHandler}
      onSubmit={closeHandler}
      showModal={!!viewPartnerModal?.showModal}
      isFormSubmitting={!!viewPartnerModal?.isModalFormSubmitting}
      isSubmitView={false}
    >
      <CModalBody>
        <div className="row">
          <div className="col-md-6 text center">
            <span>Organization Name:</span>
          </div>
          <div className="col-md-6">
            <span>{viewPartnerModal?.partner?.organizationName}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 text center">
            <span>Partner Name:</span>
          </div>
          <div className="col-md-6">
            <span>{viewPartnerModal?.partner?.partnerName}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 text center">
            <span>Active:</span>
          </div>
          <div className="col-md-6">
            <span>{viewPartnerModal?.partner?.isActive ? "Yes" : "No"}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 text center">
            <span>Creation Date:</span>
          </div>
          <div className="col-md-6">
            <span>{moment(viewPartnerModal?.partner?.creationDate).format("MMM DD YYYY")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 text center">
            <span>Modified Date:</span>
          </div>
          <div className="col-md-6">
            <span>{moment(viewPartnerModal?.partner?.modifiedDate).format("MMM DD YYYY")}</span>
          </div>
        </div>
      </CModalBody>
    </AvishModal>

  ) : (
    <></>
  );
};

export default ViewpartnerModal;
