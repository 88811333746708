import { Dispatch } from "redux";
import apiUrls from "constants/Constants";
import api from "services/api.service";
import {
  addOffer,
  resetOfferModal,
  setAllOfferLoader,
  setAllOffers,
  setEditOfferModal,
  setOfferModal,
  setViewOfferModal,
  updateOffer,
} from "store/offer/action";

const offerApi = {
  filterOffers: (payload: IOfferFilter, dispatch: Dispatch<any>, pageSize, pageNo) => {
    dispatch(setAllOfferLoader(true));
    return api
      .post(`${apiUrls.OFFERS_FILTER_URI}/${pageSize}/${pageNo}`, payload)
      .then((res) => {
        dispatch(setAllOffers(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => dispatch(setAllOfferLoader(false)));
  },

  fetchAllOffers: function (dispatch: Dispatch<any>) {
    dispatch(setAllOfferLoader(true));
    return api
      .get(apiUrls.OFFERS_GET_ALL_URI)
      .then((res) => {
        dispatch(setAllOffers(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => dispatch(setAllOfferLoader(false)));
  },

  createNewOffer: function (dispatch: Dispatch<any>, payload: IOfferFormData) {
    dispatch(setOfferModal({ isModalFormSubmitting: true }));
    return api
      .post(apiUrls.OFFERS_CREATE_POST_URI, payload)
      .then((res) => {
        dispatch(addOffer(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => dispatch(setOfferModal({ isModalFormSubmitting: false })));
  },

  editOffer: function (dispatch: Dispatch<any>, payload: IOfferFormData, offerId?: number) {
    dispatch(setEditOfferModal({ isModalFormSubmitting: true }));
    return api
      .post(`${apiUrls.OFFERS_UPDATE_POST_URI}/${offerId}`, payload)
      .then((res) => {
        dispatch(updateOffer(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => dispatch(setEditOfferModal({ isModalFormSubmitting: false })));
  },

  viewOffer: function (dispatch: Dispatch<any>, payload: IOfferFormData, offerId?: number) {
    dispatch(setViewOfferModal({ isModalFormSubmitting: true }));
    return api
      .post(`${apiUrls.OFFERS_VIEW_POST_URI}/${offerId}`, payload)
      .then((res) => {
        dispatch(setViewOfferModal(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => dispatch(setViewOfferModal({ isModalFormSubmitting: false })));
  },
};

export default offerApi;
