import TmfDropdown from "components/dropdown/dropdown";
import TmfDropdownItem from "components/dropdown/dropdownItem";
import { Dispatch, } from "react";
import { FaCopy, FaRegTrashAlt, FaTag } from "react-icons/fa";
import { GoPencil } from "react-icons/go";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { openDeleteBookmarkModal, openEditBookmarkModal, openTagBookmarkModal } from "store/bookmark/action";

interface ICourtActionParams {
    bookmark: IBookmark;
}




const CourtActions = ({ bookmark }: ICourtActionParams) => {
    const dispatch: Dispatch<any> = useDispatch();

    const copyLink = async (link:string): Promise<void> => {
        try {
            await navigator.clipboard.writeText(link);
            toast.success("Link Copied.")
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };


    return (
        <div style={{ display: "flex", height: "40px" }}>
            <TmfDropdown name="court">

                <TmfDropdownItem
                    icon={<GoPencil />}
                    text="Edit"
                    props={{
                        onClick: () => {
                            dispatch(
                                openEditBookmarkModal({
                                    showModal: true,
                                    isModalFormSubmitting: false,
                                    bookmarkbyid: bookmark
                                })
                            );
                        },
                    }}
                ></TmfDropdownItem>

                <TmfDropdownItem
                    icon={<FaRegTrashAlt />}
                    text="Delete"
                    props={{
                        onClick: () => {
                            dispatch(
                                openDeleteBookmarkModal({
                                    showModal: true,
                                    isModalFormSubmitting: false,
                                    bookmarkbyid: bookmark
                                })
                            );
                        },
                    }}
                ></TmfDropdownItem>

                <TmfDropdownItem
                    icon={<FaTag />}
                    text="Tag"
                    props={{
                        onClick: () => {
                            dispatch(
                                openTagBookmarkModal({
                                    showModal: true,
                                    isModalFormSubmitting: false,
                                    bookmarkbyid: bookmark
                                })
                            );
                        },
                    }}
                ></TmfDropdownItem>


                <TmfDropdownItem
                    icon={<FaCopy />}
                    text="Copy"
                    props={{
                        onClick: () => {
                            copyLink(bookmark.path)
                        },
                    }}
                ></TmfDropdownItem>



            </TmfDropdown>
        </div>
    );
};

export default CourtActions;
