export enum CategoryActionTypes {
    SET_ALL_COURT_CATEGORY_LOADER = "@@organization/SET_ALL_CATEGORY_LOADER",
    SET_ALL_COURT_CATEGORY = "@@organization/SET_ALL_COURT_CATEGORY",
    SET_COURT_SUB_CATEGORY_LOADER="@@category/SET_ALL_COURT_SUB_CATEGORY_LOADER",
    SET_COURT_SUB_CATEGORY="@@category/SET_COURT_SUB_CATEGORY",

    //Modal
    EDIT_CATEGORY_MODAL="@@category/EDIT_CATEGORY_MODAL",
    ADD_SUB_CATEGORY_MODAL="@@category/ADD_SUB_CATEGORY_MODAL",
    DELETE_CATEGORY_MODAL="@@category/DELETE_CATEGORY_MODAL"
  }
  
