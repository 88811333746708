const TagTableVariables = {
  fields: [
    {
      key: "name",
      label: "Name",
      _style: { width: "40%", textAlign: "start", color: "#6B6B6B", fontWeight: "600", fontSize: "1rem", padding: "10px 0", paddingLeft: "30px" },
    },
    {
      key: "description",
      label: "Description",
      _style: { width: "40%", textAlign: "start", color: "#6B6B6B", fontWeight: "600", fontSize: "1rem", padding: "10px 0",paddingLeft:'12px' }
    },
    {
      key: "access",
      label: "Access",
      _style: { width: "40%", textAlign: "start", color: "#6B6B6B", fontWeight: "600", fontSize: "1rem", padding: "10px 0",paddingLeft:'12px' }
    },
    {
      key: "Action",
      label: "Action",
      _style: { width: "20%", textAlign: "center", color: "#6B6B6B", fontWeight: "600", fontSize: "1rem", padding: "10px 0" },
      sorter: false,
      filter: false,
    },
  ],
};

export default TagTableVariables;
