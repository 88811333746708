import { TagActionTypes } from "./action.types";

//Loader Global Tag
export function getAllGlobalTagLoader(isLoading: boolean) {
    const action: TagAction = {
      type: TagActionTypes.SET_ALL_TAG_LOADER,
      isLoading,
    };
    return (dispatch: TagDispatchType) => dispatch(action);
  }
  
  // All Global Tag
  export function getAllGlobalTag(alltag: IAllTag) {
    const action: TagAction = {
      type: TagActionTypes.SET_ALL_GLOBAL_TAG,
      alltag,
    };
    return (dispatch: TagDispatchType) => dispatch(action);
  }


  //Modal
  export function openEditTagModal(editTagModal: ITagModal) {
    const action:TagAction = {
      type: TagActionTypes.SET_EDIT_TAG_MODAL,
      editTagModal,
    };
    return (dispatch: TagDispatchType) => dispatch(action);
  }

  export function openDeleteTagModal(deleteTagModal: ITagModal) {
    const action:TagAction = {
      type: TagActionTypes.SET_DELETE_TAG_MODAL,
      deleteTagModal,
    };
    return (dispatch: TagDispatchType) => dispatch(action);
  }