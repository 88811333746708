const cookies =  {
    get(name: string) {
      let dc = document.cookie;
      let prefix = name + "=";
      let begin = dc.indexOf("; " + prefix);
      var end;
      if (begin === -1) {
        begin = dc.indexOf(prefix);
        if (begin !== 0) return null;
      } else {
        begin += 2;
        end = document.cookie.indexOf(";", begin);
        if (end === -1) {
          end = dc.length;
        }
      }
      return decodeURI(dc.substring(begin + prefix.length, end));
    },
  
    set(name: string, value: string, time: number) {
      let d = new Date();
      d.setTime(d.getTime() + time * 60 * 60 * 1000);
      var expires = "expires=" + d.toUTCString();
      document.cookie =
        name + "=" + value + ";" + expires + ";path=/;SameSite=None; Secure";
    },

    delete(name: string) {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  };

  export default cookies;
  