import TmfDropdown from "components/dropdown/dropdown";
import TmfDropdownItem from "components/dropdown/dropdownItem";
import { GoPencil } from "react-icons/go";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { setEditPartnerModal, setViewPartnerModal } from "store/partner/action";

interface IPartnerActionParams {
  partner: Ipartner;
}

const PartnerAction = ({ partner }: IPartnerActionParams) => {
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <div style={{ display: "flex", height: "40px", justifyContent: "center" }}>
      <button
        onClick={() => {
          dispatch(setViewPartnerModal({ showModal: true, partner }));
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid var(--primary, #1C63B7)",
          borderRadius: "25px 0px 0px 25px",
          background: "#fff",
          width: "84px",
          cursor: partner.isDeleted ? "not-allowed" : "pointer",
        }}
      >
        View
      </button>
      <TmfDropdown>
        {partner.isDeleted ? (
          <div className="px-2">No Actions</div>
        ) : (
          <>
            <TmfDropdownItem
              icon={<GoPencil/>}
              text="Edit"
              props={{
                onClick: () => {
                  dispatch(setEditPartnerModal({ showModal: true, partner }));
                },
              }}
            ></TmfDropdownItem>
          </>
        )}
      </TmfDropdown>
    </div>
  );
};

export default PartnerAction;
