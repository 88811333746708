import {
    CButton,
    CCol,
    CFormGroup,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CRow,
} from "@coreui/react";
import { Icon } from "components/common/iconselector/Icon";
import IconSelector from "components/common/iconselector/IconSelector";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api.service";
import Constants from "../../../constants/Constants";

interface IAddCourtCategoryModal {
    refreshDetails: () => void,
    onClose: any,
    isOpen: boolean,
    data: ICategory[],
    title: string,
    selectedNode?: ICategory | undefined | null,
    onSuccess: () => void

}

const AddCourtCategoryModal: React.FC<IAddCourtCategoryModal> = ({ refreshDetails, isOpen, onClose, data, title, selectedNode,onSuccess}) => {

    const [isSpinning, setSpinning] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState<string | null>(Icon.folder);
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm();


    const sortedNo = data.filter(node => node.parentID === null).length;

    useEffect(() => {
        setValue("isCourt", true)
    }, [])

    useEffect(() => {
        if (sortedNo) {
            setValue("SortOrder", sortedNo);
        }
        if (selectedIcon) {
            setValue("Icon", selectedIcon)
        }

        if (title = "Add New SubCategory") {
            if (selectedNode) {
                setValue("parentID", selectedNode.id)
            }
        }
    }, [selectedIcon, sortedNo, selectedNode])



    const onSubmit = async (data: any) => {
        setSpinning(true);
        try {
            // User does not exist, create the user
            const res = await api.post(Constants.POST_COURT_CATEGORY, data);
            toast.success("😊Category added successfully.", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            await onClose();
            refreshDetails();
            onSuccess();
        } catch (err:any) {
            
            // Handle errors
            if(err.status===409){
                toast.error("Category Name Already Exist", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }else{
                toast.error("Error Found", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        } finally {
            setSpinning(false);
        }
    }



    return (
        <div>
            <CModal show={isOpen} onClose={onClose} closeOnBackdrop={true}>
                <CModalHeader closeButton>
                    <CModalTitle>
                        {" "}
                        <div> {title}</div>{" "}
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ fontWeight: "bold", fontSize: "12px" }}
                    >
                        <div className="flex-two-row">
                            <IconSelector selectedIcon={selectedIcon} setSelectedIcon={setSelectedIcon} iconOptions={Icon} />
                            <div className="form-title">
                                <label>Title*</label>
                                <input
                                    type="text"
                                    className="form-control "
                                    {...register("Name", { required: true, maxLength: 60 })}
                                    maxLength={60}
                                />
                                <small className="text-danger">
                                    {errors.Name?.type === "required" && "Collection Name is required"}
                                    {errors.Name?.type === "maxLength" && "Collection Name must not exceed 60 characters"}
                                </small>
                            </div>
                        </div>


                        <CRow>
                            <CCol>
                                <CFormGroup>
                                    <label>Description</label>
                                    <textarea
                                        placeholder="Optional"

                                        className="form-control"
                                        {...register("Description", { maxLength: 200 })}
                                        maxLength={200}
                                    ></textarea>
                                    <small className="text-danger">
                                        {errors.Description?.type === "maxLength" && "Collection Description must not exceed 60 characters"}

                                    </small>
                                </CFormGroup>
                            </CCol>
                        </CRow>

                        {!isSpinning && (
                            <div
                                style={{
                                    placeItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    display: "flex",
                                    marginTop: "10px",
                                    float: "right",
                                }}
                            >
                                <CButton
                                    style={{
                                        background: "var(--primary)",
                                      
                                        color: "white"
                                    }}
                                    type="submit"
                                    size="sm"
                                >
                                    Add
                                </CButton>


                                <div className="ml-1">
                                    <CButton color="danger" onClick={onClose} size="sm">
                                        Close
                                    </CButton>
                                </div>
                            </div>
                        )}


                        {isSpinning && (
                            <div
                                style={{
                                    placeItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    display: "flex",
                                    marginTop: "10px",
                                    float: "right",
                                }}
                            >
                                <CButton
                                    disabled
                                    style={{              background: "var(--primary)",
                                        color: "white" }}
                                    size="sm"
                                >
                                    <i className="fa fa-spinner fa-spin"></i> Adding
                                </CButton>
                                <div className="ml-1">
                                    <CButton color="danger" onClick={onClose} size="sm">
                                        Close
                                    </CButton>
                                </div>
                            </div>
                        )}
                    </form>
                </CModalBody>
            </CModal>
        </div>
    );
};

export default AddCourtCategoryModal;
