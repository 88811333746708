import { CButton, CCard, CCardBody, CDataTable, CSwitch } from "@coreui/react";
import api from "services/api.service";
import moment from "moment";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Constants from "constants/Constants";
import Loader from "components/loader/Loader";
import AddPaymentModal from "./AddPaymentModal";

const PaymentHistory = () => {
  const [payment, setPayment] = useState([]);
  const [isLoading, setLoaded] = useState(false);
  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState(false);
  const [subscriptionID, setSubscriptionID] = useState(null);

  let { msThirdPartySubscriptionID } = useParams();

  useEffect(() => {
    loadPayment();
  });

  const handleAddPaymentModal = (msThirdPartySubscriptionID) => {
    setAddPaymentModalOpen(true);
    setSubscriptionID(msThirdPartySubscriptionID);
  };

  const loadPayment = () =>
    api
      .getWithParams(
        Constants.PAYMENT_ALL_SUBSCRIPTION,
        { withCredentials: true }
      )
      .then((res) => setPayment(res.data))
      .finally(() => setLoaded(true));

  const fields = [
    { key: "source", _style: { width: "20%" } },
    { key: "grossAmount", label: "Payment", _style: { width: "20%" } },
    { key: "isSuccess", _style: { width: "20%" } },
    { key: "transactionDate", _style: { width: "20%" } },
    { key: "paymentStatus", _style: { width: "20%" } },
  ];
  return (
    <div>
      {addPaymentModalOpen && (
        <AddPaymentModal
          isOpen={addPaymentModalOpen}
          onClose={() => setAddPaymentModalOpen(false)}
          subscriptionID={subscriptionID}
        />
      )}
      {isLoading ? (
        <>
         <Link className="btn btn-info btn-sm" to="/subscription-list">
            Back
          </Link>
          <CButton
            color="info"
            size="sm"
            onClick={() => handleAddPaymentModal(msThirdPartySubscriptionID)}
            style={{marginLeft: '10px'}}
          >
            <div className="d-flex ">
              <i className="fa fa-plus" aria-hidden="true"></i>
              <div className="ml-1">Add</div>
            </div>
          </CButton>
          <div className="text-center">Payment Details</div>
          <CCard>
            <CCardBody>
              <CDataTable
                items={payment}
                fields={fields}
                tableFilter={{ placeholder: "search Payment-History" }}
                itemsPerPageSelect
                itemsPerPage={5}
                sorter
                pagination
                scopedSlots={{
                  transactionDate: (item) => {
                    return (
                      <td>
                        {moment(item.transactionDate).format("MM/DD/YYYY")}
                      </td>
                    );
                  },
                  isSuccess: (item) => {
                    return (
                      <td>
                        <CSwitch
                          disabled
                          color="primary"
                          defaultChecked={item.isActive}
                          labelOn="On"
                          labelOff="Off"
                          shape="pill"
                          size="sm"
                        />
                      </td>
                    );
                  },
                  grossAmount: (item) => {
                    return (
                      <td>
                        <p className="textBold">
                          Gross Amount:{item.grossAmount}
                        </p>
                        <p className="textBold">Net Amount:{item.netAmount}</p>
                        <p className="textBold">
                          Payment GatewayFee:{item.paymentGatewayFee}
                        </p>
                      </td>
                    );
                  },
                }}
              />
            </CCardBody>
          </CCard>
        </>
      ) : (
        <div className="spinner">
          <Loader color="blue.500" size="2rem" />
        </div>
      )}
    </div>
  );
};

export default PaymentHistory;
