import axios from "axios";
import Constants from "constants/Constants";
import React, { useContext, useEffect, useState } from "react";
import { authContext } from "auth/auth.provider";

type annualLeadData = [number];
type annualSubscriptionData = [number];
interface ITotalCount {
  activeSubscription: number;
  inactiveSubscription: number;
  deletedSubscription: number;
  expiredSubscription: number;
  totalSubscriptions: number;

  activeUser: number;
  deletedUser: number;
  inactiveUser: number;
  totalUsers: number;

  activeOrganization: number;
  deletedOrganization: number;
  inactiveOrganization: number;
  totalOrganizations: number;

  totalLeads: number;
  totalSubscribedLeads: number;
  activePlans: number;
  inactivePlans: number;
  activeOffers: number;
  inactiveOffers: number;
  activePartners: number;
  inactivePartners: number;
  totalIcons: number;

  annualLeadData: annualLeadData;
  annualSubscriptionData: annualSubscriptionData;
}
const totalNoOf: ITotalCount = {
  activeSubscription: 0,
  deletedSubscription: 0,
  inactiveSubscription: 0,
  expiredSubscription: 0,
  totalSubscriptions: 0,

  activeUser: 0,
  deletedUser: 0,
  inactiveUser: 0,
  totalUsers: 0,

  activeOrganization: 0,
  deletedOrganization: 0,
  inactiveOrganization: 0,
  totalOrganizations: 0,

  totalLeads: 0,
  totalSubscribedLeads: 0,

  activePlans: 0,
  inactivePlans: 0,

  activeOffers: 0,
  inactiveOffers: 0,

  activePartners: 0,
  inactivePartners: 0,

  totalIcons: 0,

  annualLeadData: [0],
  annualSubscriptionData: [0],
};

export const TotalCountContext = React.createContext<ITotalCount>(totalNoOf);
function TotalCount({ children }) {
  const auth = useContext(authContext);
  const [totalData, setTotalData] = useState<ITotalCount>(totalNoOf);

  useEffect(() => {
    if (auth.isAuthenticated)
      axios.get(Constants.GET_TOTAL_DASHBOARD_COUNTS).then((res) => {
        setTotalData((prev) => ({ ...prev, ...res.data }));
      });
    axios.get(Constants.GET_LEADS_COUNT_BY_MONTH).then((res) => {
      setTotalData((prevData) => ({
        ...prevData,
        annualLeadData: res.data,
      }));
    });
    axios.get(Constants.GET_SUBSCRIPTION_COUNT_BY_MONTH).then((res) => {
      setTotalData((prevData) => ({
        ...prevData,
        annualSubscriptionData: res.data,
      }));
    });
  }, [auth.isAuthenticated]);
  return (
    <TotalCountContext.Provider value={totalData}>
      {children}
    </TotalCountContext.Provider>
  );
}

export default TotalCount;
