export enum UserActionTypes {
  SET_USERS = "@@user/SET_USERS",
  SET_USERS_LOADER = "@@user/SET_USERS_LOADER",
  SET_USERS_FILTER = "@@user/SET_USERS_FILTER",
  SET_USER_LOADER = "@@organization/SET_USER_LOADER",
  SET_USER_STATUS = "@@organization/SET_USER_STATUS",

  ADD_USER_MODAL = "@@user/ADD_USER_MODAL",
  EDIT_USER_MODAL = "@@user/EDIT_USER_MODAL",
  DELETE_USER_MODAL = "@@user/DELETE_USER_MODAL",
  VIEW_USER_MODAL = "@@user/VIEW_USER_MODAL",
  UNDO_DELETE_USER_MODAL = "@@user/UNDO_DELETE_USER_MODAL",
}

export const usersFilterInitial: IUserFilter = {
  BeginDate: null,
  EndDate: null,
  IsActive: null,
  OrganizationID: null,
};
