
import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import Loader from "components/loader/Loader";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api.service";
import Constants from "../../../constants/Constants";


const AddSubscriptionModal = ({ refreshDetails, isOpen, onClose }) => {
  const [subscription, setSubscription] = useState({
    autoRenew: false,
    startDate: " ",
    endDate: " ",
    purchasedLicenses: " ",
    planID: " ",
    subscriptionTypeID: "",
    perLicenseCost: "",
    paymentGateway: "",
  });
  // //console.log({ subscription });

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [dropdownOrganization, setDropDownOrganization] = useState(null);
  const [plan, setPlan] = useState([]);
  const [isloading, setLoaded] = useState(false);
  const [isSpinning, setSpinning] = useState(false);
  const [subscriptionTypes, setSubscriptionTypes] = useState(null);
  const [isPurchasedLicensesDisabled, setIsPurchasedLicensesDisabled] = useState(false);
  const [purchasedLicenses, setPurchaseLicense] = useState();
  // const [cost, setCost] = useState([]);
  // const [paymentGateway, setPaymentGateway] = useState([]);

  useEffect(() => {
    getuUnsubscribedOrganization();
    loadPlan();
    loadSubscriptionTypes();
  }, []);

  const onInputChange = (e) => {
    setSubscription({ ...subscription, [e.target.name]: e.target.value });
  };

  // function handleSelectChange(e) {
  //   const alldata = [];
  //   e.map((item) => {
  //     alldata.push(item.value);
  //   });
  //   setPaymentGateway(alldata);
  // }

  const loadPlan = () => {
    api.get(Constants.PLAN_GET_URI).then((res) => {
      setPlan(res.data);
    });
  };

  const getuUnsubscribedOrganization = () => {
    //setLoaded(true);
    api.get(Constants.UNSUBSCRIBED_ORGANIZATION_GET_URI, dropdownOrganization)
      .then((res) => {
        setDropDownOrganization(res.data);
        setLoaded(false);
      });
  };
  // //console.log({dropdownOrganization});
  const loadSubscriptionTypes = () => {
    api.get(Constants.SUBSCRIPTION_TYPES_GET_ALL_URI).then((res) => {
      setSubscriptionTypes(res.data);
    });
  };

  const onSubmit = () => {
    setSpinning(true);
    subscription.isActive = true;
    api.post(Constants.SUBSCRIPTION_CREATE_URI, subscription)
      .then(async (response) => {
        console.log("response data", response);
        toast.success("😊 Subscription added successfully.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        await onClose();
        refreshDetails();
      }).catch((error) => {
        toast.error(`😔 ${error.response.data.title}`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }).finally(() => {
        setSpinning(false);
      });
  };
  const handlePlanChange = (e) => {
    let event = e.target;
    let perLicenceCost = planDropDown.filter((item) => item.value == event.value)[0].perLicenceCost;
    setSubscription({
      ...subscription,
      planID: event.value,
      perLicenseCost: perLicenceCost,
    });
  }
  const planDropDown = plan.map((item) => {
    return {
      label: item.name,
      value: item.id,
      perLicenceCost: item.perLicenceCost,
    };
  });
  const StartDateField = register("startDate", {
    required: true,
  });
  const EndDateField = register("endDate", {
    required: true,
  });
  const purchasedLicensesField = register("purchasedLicenses", {
    required: false,
    min: 0,
  });
  // const planField = register("planID", {
  //   required: true,
  // });
  const OrganizationField = register("OrgID", {
    required: true,
  });
  const SubscriptionTypeField = register("subscriptionTypeID", {
    required: true,
  });


  // const options = [{ value: "Paypal", label: "PayPal" }];
  return (
    <div>
      {!isloading && plan && subscriptionTypes && dropdownOrganization && (
        <CModal show={isOpen} onClose={onClose}>
          <CModalHeader closeButton>
            <CModalTitle>
              <div>Add New subscription</div>
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ fontWeight: "bold", fontSize: "12px" }}
            >
              <CRow>
                <CCol>
                  {" "}
                  <CFormGroup>
                    <label className="control-label">Start Date</label>
                    <input
                      type="date"
                      name="startDate"
                      {...StartDateField}
                      onChange={(e) => {
                        StartDateField.onChange(e);
                        onInputChange(e);
                      }}
                      className="form-control"
                    />
                    <small className="text-danger">
                      {errors.startDate?.type === "required" &&
                        "Start Date is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label className="control-label">End Date</label>
                    <input
                      type="date"
                      name="endDate"
                      {...EndDateField}
                      onChange={(e) => {
                        EndDateField.onChange(e);
                        onInputChange(e);
                      }}
                      min={subscription.startDate}
                      className="form-control"
                    />
                    <small className="text-danger">
                      {errors.endDate?.type === "required" &&
                        "End Date is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  {" "}
                  <CFormGroup>
                    <label>Plan*</label>
                    <Controller
                      name="planID"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select
                          // options={planDropDown}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            handlePlanChange(e);
                          }}
                          className="form-control"
                        >
                          <option></option>
                          {
                            planDropDown.map((item,index) => {
                              return (
                                <React.Fragment key={index}>
                                  <option value={item.value} key={item.value}>
                                    {item.label}
                                  </option>
                                </React.Fragment>
                              );
                            })
                          }
                        </select>
                      )}
                    />
                    <small className="text-danger">
                      {errors.planID?.type === "required" && "Plan is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Per License Cost*</label>
                    <input
                      disabled
                      name="perLicenseCost"
                      value={subscription.perLicenseCost}
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                    />
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Subscription Type*</label>
                    <select
                      name="subscriptionTypeID"
                      {...SubscriptionTypeField}
                      onChange={(e) => {
                        SubscriptionTypeField.onChange(e);
                        onInputChange(e);
                        const selectedSubscriptionTypeID = parseInt(e.target.value);
                        if (selectedSubscriptionTypeID === 4) {
                          setSubscription((prevSubscription) => ({
                            ...prevSubscription,
                            purchasedLicenses: 0,
                          }));
                          setPurchaseLicense(0)
                          setIsPurchasedLicensesDisabled(true);
                        } else {
                          setIsPurchasedLicensesDisabled(false);
                          setPurchaseLicense();
                        }
                      }}
                      className="form-control"
                    >
                      <option ></option>
                      {subscriptionTypes.map((temp,index) => {
                        return (
                          <React.Fragment key={index}>
                            <option value={temp.id} name="subscriptionTypeID">
                              {temp.type}{" "}
                            </option>
                          </React.Fragment>
                        );
                      })}
                    </select>
                    <small className="text-danger">
                      {errors.subscriptionTypeID?.type === "required" &&
                        "Subscription Type is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Purchased Licenses*</label>
                    <input
                      type="number"
                      name="purchasedLicenses"
                      {...purchasedLicensesField}
                      onChange={(e) => {
                        purchasedLicensesField.onChange(e);
                        onInputChange(e);
                      }}
                      defaultValue={purchasedLicenses}
                      className="form-control"
                      disabled={isPurchasedLicensesDisabled}
                    />
                    <small className="text-danger">
                      {errors.purchasedLicenses?.type === "required" &&
                        "License is required"}
                      {errors.purchasedLicenses?.type === "min" && "Please enter a positive number"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Payment Gateway</label>
                    {/* <CreatableSelect
                      name="paymentGateway"
                      custom
                      isClearable
                      options={options}
                      onChange={(e) => handleSelectChange(e)}
                    /> */}
                    <input
                      className="form-control"
                      name="paymentGateway"
                      onChange={(e) => onInputChange(e)}
                    />
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Organization*</label>
                    <select
                      // name="OrgID"
                      {...OrganizationField}
                      onChange={(e) => {
                        OrganizationField.onChange(e);
                        onInputChange(e);
                      }}
                      className="form-control"
                    >
                      {/* <option value={user.organizationID}>
                        {user.organizationID}
                      </option> */}
                      <option></option>
                      {dropdownOrganization.map((item,index) => {
                        return (
                          <React.Fragment key={index}>
                            <option value={item.organizationID}>
                              {item.name}
                            </option>
                          </React.Fragment>
                        );
                      })}
                    </select>
                    <small className="text-danger">
                      {errors.OrgID?.type === "required" &&
                        "Organization is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <input
                      type="checkbox"
                      className="form-check-input ml-1"
                      name="autoRenew"
                      onChange={() =>
                        setSubscription((subscription) => ({
                          ...subscription,
                          autoRenew: !subscription.autoRenew,
                        }))
                      }
                    // onChange={() => setSubscription(!subscription.autoRenew)}
                    />
                    <label style={{ fontWeight: "bold", marginLeft: "2rem" }}>
                      Auto Renew
                    </label>
                  </CFormGroup>
                </CCol>
                <CCol></CCol>
              </CRow>
              {!isSpinning && (
                <div
                  style={{
                    placeItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <CButton style={{ background: "var(--primary)",color: "white" }}
                    type="submit" size="sm">
                    Add
                  </CButton>

                  <div className="ml-1">
                    <CButton color="danger" onClick={onClose} size="sm">
                      Close
                    </CButton>
                  </div>
                </div>
              )}
              {isSpinning && (
                <div
                  style={{
                    placeItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                    marginTop: "10px",
                    float: "right",
                  }}
                >
                  <CButton disabled style={{ background: "var(--primary)",color: "white" }}
                    size="sm">
                    <i className="fa fa-spinner fa-spin"></i> Adding
                  </CButton>
                  <div className="ml-1">
                    <CButton color="danger" onClick={onClose} size="sm">
                      Close
                    </CButton>
                  </div>
                </div>
              )}
            </form>
          </CModalBody>
        </CModal>
      )}
      {isloading && <Loader />}
    </div>
  );
};

export default AddSubscriptionModal;
