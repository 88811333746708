import { Reducer } from "redux";
import { PartnerActionTypes, partnersFilterInitial } from "./action.types";

export const initialState: partnerState = {
  allPartners: [],
  partnerFilter: partnersFilterInitial,
  isAllPartnersLoading: false,
};

const reducer: Reducer<partnerState> = (state = initialState, action) => {
  switch (action.type) {
    case PartnerActionTypes.SET_ALL_PARTNERS: {
      return {
        ...state,
        allPartners: [...action.allPartners],
      };
    }
    case PartnerActionTypes.SET_ALL_PARTNERS_LOADER: {
      return {
        ...state,
        isAllPartnersLoading: action.isAllPartnersLoading,
      };
    }

    case PartnerActionTypes.ADD_PARTNER: {
      return {
        ...state,
        allPartners: [action.partner, ...state.allPartners],
      };
    }
    case PartnerActionTypes.UPDATE_PARTNER: {
      return {
        ...state,
        allPartners: [
          ...state.allPartners.map((el) => {
            if (el.partnerID === action.partner.partnerID) {
              el = { ...el, ...action.partner };
            }
            return el;
          }),
        ],
      };
    }

    case PartnerActionTypes.ADD_PARTNER_MODAL: {
      return {
        ...state,
        addPartnerModal: {
          ...state.addPartnerModal,
          ...action.addPartnerModal,
        },
      };
    }

    case PartnerActionTypes.RESET_ADD_PARTNER_MODAL: {
      return {
        ...state,
        addPartnerModal: {
          showModal: false,
          isModalFormSubmitting: false,
        },
      };
    }

  case PartnerActionTypes.EDIT_PARTNER_MODAL: {
      return {
        ...state,
        editPartnerModal: {
          ...state.editPartnerModal,
          ...action.editPartnerModal,
        },
      };
    }
    case PartnerActionTypes.RESET_EDIT_PARTNER_MODAL: {
      return {
        ...state,
        editPartnerModal: {
          showModal: false,
          isModalFormSubmitting: false,
          partner: null,
        },
      };
    }
    case PartnerActionTypes.VIEW_PARTNER_MODAL: {
      return {
        ...state,
        viewPartnerModal: {
          ...state.viewPartnerModal,
          ...action.viewPartnerModal,
        },
      };
    }
    case PartnerActionTypes.RESET_VIEW_PARTNER_MODAL: {
      return {
        ...state,
        viewPartnerModal: {
          showModal: false,
          isModalFormSubmitting: false,
          partner: null,
        },
      };
    }
    
    case PartnerActionTypes.SET_PARTNER_TYPES: {
      return {
        ...state,
        partnerTypes: [...action.partnerTypes],
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as partnerReducer };
