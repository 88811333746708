import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import axios from "axios";
import Loader from "components/loader/Loader";
import Constants from "constants/Constants";
import moment from "moment";
import { useEffect, useState } from "react";

const ViewUserDetails = ({ userDetails, refreshDetails, isOpen, onClose }) => {
  const [isLoading, setLoaded] = useState(false);
  const [totalBookmarkCount, setTotalBookmarkCount] = useState<number>();

  const getAllBookmarkCount = () => {
    axios.post(Constants.USER_BOOKMARK_COUNT, userDetails.userID).then((res) => {
      console.log(res.data);
      setTotalBookmarkCount(parseInt(res.data))
    });
  };

  useEffect(() => {
    getAllBookmarkCount();
  }, []);

  return (
    <div>
      {!isLoading && isOpen && userDetails && (
        <>
          <CModal
            show={isOpen}
            onClose={onClose}
            closeOnBackdrop={false}
            style={{ borderTop: "5px solid #ccc" }}
          >
            <CModalHeader closeButton>
              <CModalTitle>
                <div>User's Details</div>
              </CModalTitle>
            </CModalHeader>
            <CModalBody>
              <div className="row p-2">
                <div className="col-md-4">
                  <span className="font-weight-bold">Salutation:</span>
                </div>
                <div className="col-md-8">
                  <span>{userDetails.title}</span>
                </div>
              </div>
              <hr className="my-0" />
              <div className="row p-2">
                <div className="col-md-4">
                  <span className="font-weight-bold">First Name:</span>
                </div>
                <div className="col-md-8">
                  <span>{userDetails.first_Name}</span>
                </div>
              </div>
              <hr className="my-0" />
              <div className="row p-2">
                <div className="col-md-4">
                  <span className="font-weight-bold">Middle Name :</span>
                </div>
                <div className="col-md-8">
                  <span>{userDetails.middle_Name}</span>
                </div>
              </div>
              <hr className="my-0" />
              <div className="row p-2">
                <div className="col-md-4">
                  <span className="font-weight-bold">Phone :</span>
                </div>
                <div className="col-md-8">
                  <span>{userDetails.phone}</span>
                </div>
              </div>
              <hr className="my-0" />
              <div className="row p-2">
                <div className="col-md-4">
                  <span className="font-weight-bold">Email :</span>
                </div>
                <div className="col-md-8">
                  <span>{userDetails.email}</span>
                </div>
              </div>
              <hr className="my-0" />
              <div className="row p-2">
                <div className="col-md-4">
                  <span className="font-weight-bold">Organization :</span>
                </div>
                <div className="col-md-8">
                  <span>{userDetails.name}</span>
                </div>
              </div>
              <hr className="my-0" />
              <div className="row p-2">
                <div className="col-md-4">
                  <span className="font-weight-bold">Active :</span>
                </div>
                <div className="col-md-8">
                  <span>{userDetails.isActive ? "Yes" : "No"}</span>
                </div>
              </div>
              <hr className="my-0" />
              <div className="row p-2">
                <div className="col-md-4">
                  <span className="font-weight-bold">Deleted :</span>
                </div>
                <div className="col-md-8">
                  <span>{userDetails.isDeleted ? "Yes" : "No"}</span>
                </div>
              </div>
              <hr className="my-0" />
              <div className="row p-2">
                <div className="col-md-4">
                  <span className="font-weight-bold">Creation Date :</span>
                </div>
                <div className="col-md-8">
                  <span>
                    {moment(userDetails.creationDate).format("MMM DD YYYY")}
                  </span>
                </div>
              </div>
              <hr className="my-0" />
              <div className="row p-2">
                <div className="col-md-4">
                  <span className="font-weight-bold">Modification Date :</span>
                </div>
                <div className="col-md-8">
                  <span>
                    {moment(userDetails.modifiedDate).format("MMM DD YYYY")}
                  </span>
                </div>
              </div>
              <hr className="my-0" />
              <div className="row p-2">
                <div className="col-md-4">
                  <span className="font-weight-bold">Notes :</span>
                </div>
                <div className="col-md-8">
                  <span>{userDetails.notes}</span>
                </div>
              </div>
              <hr className="my-0" />
              <div className="row p-2">
                <div className="col-md-4">
                  <span className="font-weight-bold">Descriptions :</span>
                </div>
                <div className="col-md-8">
                  <span>{userDetails.description}</span>
                </div>
              </div>

              <hr className="my-0" />
              <div className="row p-2">
                <div className="col-md-4">
                  <span className="font-weight-bold">Total Bookmarks:</span>
                </div>
                <div className="col-md-8">
                  <span>{totalBookmarkCount}</span>
                </div>
              </div>
            </CModalBody>
            <CModalFooter>
              <CButton color="danger" size="sm" onClick={onClose}>
                Close
              </CButton>
            </CModalFooter>
          </CModal>
        </>
      )}

      <div />
      {isLoading && (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default ViewUserDetails;
