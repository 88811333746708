import { Reducer } from "redux";
import { PlanActionTypes, plansFilterInitial } from "./action.types";

export const initialState: planState = {
  allPlans: [],
  planFilter: plansFilterInitial,
  isPlanFilterDataLoading: false,
  addPlanModal: {
    showModal: false,
    isModalFormSubmitting: false,
  },
  editPlanModal: {
    showModal: false,
    isModalFormSubmitting: false,
    plan: null,
  },
  viewPlanModal: {
    showModal: false,
    isModalFormSubmitting: false,
    plan: null,
  },
  deletePlanModal: {
    showModal: false,
    isModalFormSubmitting: false,
    plan: null,
  },
};

const reducer: Reducer<planState> = (state = initialState, action) => {
  switch (action.type) {
    case PlanActionTypes.SET_ALL_PLANS: {
      return {
        ...state,
        allPlans: [...action.allPlans],
      };
    }
    case PlanActionTypes.SET_PLAN_FILTER_DATA_LOADER: {
      return {
        ...state,
        isPlanFilterDataLoading: action.isPlanFilterDataLoading,
      };
    }

    case PlanActionTypes.ADD_PLAN: {
      return {
        ...state,
        allPlans: [action.plan, ...state.allPlans],
      };
    }
    case PlanActionTypes.UPDATE_PLAN: {
      return {
        ...state,
        allPlans: [
          ...state.allPlans.map((el) => {
            if (el.id === action.plan.id) {
              el = { ...el, ...action.plan };
            }
            return el;
          }),
        ],
      };
    }
    case PlanActionTypes.VIEW_PLAN: {
      return {
        ...state,
        allPlans: [
          ...state.allPlans.map((el) => {
            if (el.id === action.plan.id) {
              el = { ...el, ...action.plan };
            }
            return el;
          }),
        ],
      };
    }

    case PlanActionTypes.ADD_PLAN_MODAL: {
      return {
        ...state,
        addPlanModal: { ...state.addPlanModal, ...action.addPlanModal },
      };
    }

    case PlanActionTypes.RESET_ADD_PLAN_MODAL: {
      return {
        ...state,
        addPlanModal: {
          showModal: false,
          isModalFormSubmitting: false,
        },
      };
    }

    case PlanActionTypes.EDIT_PLAN_MODAL: {
      return {
        ...state,
        editPlanModal: { ...state.editPlanModal, ...action.editPlanModal },
      };
    }
    case PlanActionTypes.VIEW_PLAN_MODAL: {
      return {
        ...state,
        viewPlanModal: { ...state.viewPlanModal, ...action.viewPlanModal },
      };
    }

    case PlanActionTypes.RESET_EDIT_PLAN_MODAL: {
      return {
        ...state,
        editPlanModal: {
          showModal: false,
          isModalFormSubmitting: false,
          plan: null,
        },
      };
    }

    case PlanActionTypes.RESET_VIEW_PLAN_MODAL: {
      return {
        ...state,
        viewPlanModal: {
          showModal: false,
          isModalFormSubmitting: false,
          plan: null,
        },
      };
    }
    case PlanActionTypes.DELETE_PLAN_MODAL: {
      return {
        ...state,
        deletePlanModal: { ...state.deletePlanModal, ...action.deletePlanModal },
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as planReducer };