import { CButton, CModal, CModalBody } from "@coreui/react";
import api from "services/api.service";
import { useState } from "react";
import Constants from "../../constants/Constants";

const PlanStatusModal = ({
  isOpen,
  onClose,
  refreshDetails,
  id,
  event,
  name,
}) => {
  const [isLoading, setLoaded] = useState(false);

  const handlePlanToggle = async () => {
    setLoaded(true);
    await api
      .post(Constants.PLAN_TOGGLE_POST_URI + "/" + id + "/" + event)
      .then(async (res) => {
        onClose();
        await refreshDetails();
        setLoaded(false);

        if (event === false) {
          window.alert(`${name} is Activated!`);
        } else {
          window.alert(`${name} is Deactivated!`);
        }
      });
  };
  return (
    <div>
      <CModal
        show={isOpen}
        onClose={onClose}
        centered={true}
        closeOnBackdrop={false}
      >
        <CModalBody>
          <div style={{ textAlign: "center" }}>
            <div>
              {event === true ? (
                <div>
                  <div
                    className="col-md-10"
                    style={{ fontWeight: "bold", marginLeft: "2rem" }}
                  >
                    Are you sure, {name} to be Deactivated?
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      fontWeight: "bold",
                    }}
                    className="col-md-11"
                  >
                    Are you sure, {name} to be Activated?
                    {/* Are you sure,you want {name} to be Active? */}
                  </div>
                </div>
              )}
            </div>

            <div
              style={{
                marginTop: "0.5rem",
                display: "flex",
                marginLeft: "8rem",
              }}
            >
              <div style={{ marginLeft: "1rem" }}>
                <CButton onClick={onClose} color="secondary" size="sm">
                  No,cancel Plz!
                </CButton>
              </div>
              <div style={{ marginLeft: "0.5rem" }}>
                {!isLoading && (
                  <CButton
                    onClick={() => handlePlanToggle()}
                    color="danger"
                    size="sm"
                  >
                    {" "}
                    Yes
                  </CButton>
                )}
                {isLoading && (
                  <CButton
                    disabled
                    className="float-right"
                    color="danger"
                    size="sm"
                  >
                    {" "}
                    <i className="fa fa-spinner fa-spin"></i> Updating
                  </CButton>
                )}
              </div>
            </div>
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
};

export default PlanStatusModal;
