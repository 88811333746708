import { useReducer } from "react";
import {
  CButton,
  CCol,
  CFooter,
  CFormGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { v4 as uuidv4 } from "uuid";

const AddChildModal = ({ isOpen, onClose, create, id }) => {
  const [childInput, setChildInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      child: "",
    }
  );

  const onInputChange = (e) => {
    setChildInput({ ...childInput, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const newChild = { id: uuidv4(), child: childInput.child, parent: id };
    create(newChild);
    setChildInput({
      child: "",
    });
    onClose();
  };

  return (
    <div>
      <CModal show={isOpen} onClose={onClose} closeOnBackdrop={true} size="sm">
        <CModalHeader closeButton>
          <CModalTitle>Add New child</CModalTitle>
        </CModalHeader>
        <form onSubmit={(e) => onSubmit(e)}>
          <CModalBody>
            <CRow>
              <CCol>
                <CFormGroup>
                  <label>Name:</label>
                  <input
                    className="form-control"
                    id="child-folder"
                    name="child"
                    value={childInput.child}
                    onChange={(e) => onInputChange(e)}
                  />
                </CFormGroup>
              </CCol>
            </CRow>
          </CModalBody>
          <CFooter>
            <CButton
              type="submit"
              size="sm"
              className="float-right"
              color="info"
            >
              {" "}
              Add
            </CButton>
          </CFooter>
        </form>
      </CModal>
    </div>
  );
};

export default AddChildModal;
