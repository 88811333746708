import {
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";

const AddPaymentModal = ({ isOpen, onClose }) => {
  const [addPayment, setAddPayment] = useState([]);
  // const [payment, setPayment] = useState([]);

  const onInputChange = (e) => {
    setAddPayment({ ...addPayment, [e.target.name]: e.target.value });
  };

  //   const handleChange = (newValue: OnChangeValue) => {};
  const source = [
    {
      label: "Paypal",
      value: "Paypal",
    },
  ];

  const handleSelectChange = (opt, meta) => {
    setAddPayment(opt, meta);
  };
  //   function handleSelectChange(e) {
  //     const allPayment = "";
  //     allPayment.push(options.value);
  //     setPayment(allPayment);
  //     //console.log({ allPayment });
  //   }
  return (
    <div>
      <CModal show={isOpen} onClose={onClose} closeOnBackdrop={true}>
        <CModalHeader closeButton>
          <CModalTitle>
            {" "}
            <div>Add New Payment</div>{" "}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <form>
            <CRow>
              <CCol>
                <CFormGroup>
                  <label>Source</label>
                  <select
                    name="source"
                    className="form-control"
                    // onChange={(opt, meta) => //console.log(opt, meta)}
                    // onInputChange={(e) => onInputChange(e)}
                    onChange={(opt, meta) => handleSelectChange(opt, meta)}
                  >
                    {source.map((el, index) => {
                        return (
                          <option key={index} value={el.value}>
                            {el.label}
                          </option>
                        );
                      })}
                  </select>
                  {/* <input
                    type="text"
                    name="source"
                    className="form-control"
                    onChange={(e) => onInputChange(e)}
                  /> */}
                </CFormGroup>
              </CCol>
              <CCol>
                <CFormGroup>
                  <label>Currency</label>
                  <input
                    type="text"
                    name="currency"
                    className="form-control"
                    onChange={(e) => onInputChange(e)}
                  />
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CFormGroup>
                  <label>Gross Amount</label>
                  <input
                    type="number"
                    name="grossAmount"
                    className="form-control"
                    onChange={(e) => onInputChange(e)}
                  />
                </CFormGroup>
              </CCol>
              <CCol>
                <CFormGroup>
                  <label>Net Amount</label>
                  <input
                    type="number"
                    name="netAmount"
                    className="form-control"
                    onChange={(e) => onInputChange(e)}
                  />
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CFormGroup>
                  <label>Payment GatewayFee</label>
                  <input
                    type="number"
                    name="paymentGatewayFee"
                    className="form-control"
                    onChange={(e) => onInputChange(e)}
                  />
                </CFormGroup>
              </CCol>
              <CCol>
                <CFormGroup>
                  <label>Status</label>
                  <select name="status" className="form-control">
                    <option></option>
                    <option>Completed</option>
                    <option>Pay Later</option>
                  </select>
                </CFormGroup>
              </CCol>
            </CRow>
          </form>
        </CModalBody>
      </CModal>
    </div>
  );
};

export default AddPaymentModal;
