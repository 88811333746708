import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dispatch } from "redux";
import api from "services/api.service";
import { ApplicationState } from "store";
import { setAddOrganizationModal } from "store/organization/action";
import Constants from "../../../constants/Constants";

const AddOrganizationModal = ({ refreshDetails }) => {
  const dispatch: Dispatch<any> = useDispatch();
  const { countries, timezones } = useSelector(
    (state: ApplicationState) => state.global
  );
  const showAddOrganizationModal = useSelector(
    (state: ApplicationState) => state.organization.showAddOrganizationModal
  );
  const organizationTypes = useSelector(
    (state: ApplicationState) => state.organization.organizationTypes
  );
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [organization, setOrganization] = useState<IOrganization>(
    {} as IOrganization
  );
  const [stateList, setStateList] = useState([]);
  const [isSpinning, setSpinning] = useState<boolean>(false);

  useEffect(() => {
    if (showAddOrganizationModal) {
      organization.country = countries[0]?.id;
      loadStateList(organization.country);
    }
  }, [showAddOrganizationModal]);

  const onInputChange = (e) => {
    setOrganization({ ...organization!, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    setSpinning(true);
    api
      .post(Constants.ORGANIZATION_CREATE_POST_URI, organization!)
      .then(async (response) => {
        toast.success("😊 Organization added successfully.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        dispatch(setAddOrganizationModal(false));
        setSpinning(false);
        refreshDetails();
      })
      .catch((error) => {
        toast.error(`😔 Error while adding organization!`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  //Loading state after selecting country

  function loadStateList(countryID) {
    api.get(Constants.STATE_GET_URI + "/" + countryID).then((res) => {
      const allState = res.data.map((item) => {
        return {
          label: item.name,
          value: item?.id,
        };
      });
      setStateList(allState);
      organization.state = allState[0].value;
    });
  }

  const allCountry = countries.map((item) => {
    return {
      label: item.name,
      value: item?.id,
    };
  });

  const allTimezone = timezones.map((item) => {
    return {
      label: item.displayName,
      value: item?.id,
    };
  });
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(new Blob([file]));
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setOrganization({ ...organization!, logo: base64 });
  };

  const NameField = register("name", {
    required: true,
  });
  const EmailField = register("email", {
    required: true,
  });
  const PhoneNumberField = register("phone", {
    required: true,
  });
  const AddressField = register("address", {
    required: true,
  });
  const OrganizationTypeId = register("organizationTypeId", {
    required: true,
  });
  return (
    <>
      {showAddOrganizationModal && (
        <CModal
          size="lg"
          show={showAddOrganizationModal}
          onClose={() => {
            dispatch(setAddOrganizationModal(false));
          }}
          closeOnBackdrop={false}
          // style={{ maxHeight: "90vh", overflowY: "scroll" }}
        >
          <CModalHeader closeButton>
            <CModalTitle>
              <div>Add Organization</div>{" "}
            </CModalTitle>
          </CModalHeader>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ fontWeight: "bold", fontSize: "12px" }}
          >
            <CModalBody>
              {/* <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Logo</label>
                    <div style={{ marginTop: "0.5rem" }}>
                      <input
                        type="file"
                        name="logo"
                        accept=".jpeg, .png, .jpg"
                        onChange={(e) => handleFileUpload(e)}
                      />
                    </div>
                  </CFormGroup>
                </CCol>
                {organization?.logo && (
                  <CCol>
                    <CFormGroup>
                      <img
                        src={organization.logo}
                        alt="logo"
                        style={{
                          width: 95,
                          height: 95,
                        }}
                      />
                    </CFormGroup>
                  </CCol>
                )}
              </CRow> */}
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Logo</label>
                    <div style={{ marginTop: "0.5rem" }}>
                      <input
                        placeholder="Logo"
                        type="file"
                        name="logo"
                        accept=".jpeg, .png, .jpg"
                        onChange={(e) => handleFileUpload(e)}
                      />
                    </div>
                  </CFormGroup>
                </CCol>
                {organization?.logo && (
                  <CCol>
                    <CFormGroup>
                      <img
                        src={organization.logo}
                        alt="logo"
                        style={{
                          width: 95,
                          height: 95,
                        }}
                      />
                    </CFormGroup>
                  </CCol>
                )}
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label> Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      {...NameField}
                      onChange={(e) => {
                        NameField.onChange(e);
                        onInputChange(e);
                      }}
                    />
                    <small className="text-danger">
                      {errors.name?.type === "required" && "Name is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Type*</label>
                    <select
                      // name="OrganizationTypeId"
                      className="form-control"
                      {...OrganizationTypeId}
                      onChange={(e) => {
                        OrganizationTypeId.onChange(e);
                        onInputChange(e);
                      }}
                    >
                      <option></option>
                      {organizationTypes.map((item, index) => {
                        return (
                          <option value={item.organizationTypeId} key={index}>
                            {item.organizationType}
                          </option>
                        );
                      })}
                    </select>
                    <small className="text-danger">
                      {errors.organizationTypeId?.type === "required" &&
                        "OrganizationType is required"}
                    </small>
                    {/* <Controller
                      name="OrganizationTypeId"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select
                          className="form-control"
                          {...OrganizationTypeId}
                          onChange={(e) => onInputChange(e)}
                        >
                          <option></option>
                          {organizationTypes.map((item, index) => {
                            return (
                              <option
                                key={index}
                                value={item.organizationTypeId}
                              >
                                {item.organizationType}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    />
                    <small className="text-danger">
                      {errors.organizationTypeId?.type === "required" &&
                        "OrganizationType is required"}
                    </small> */}
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>E-mail*</label>
                    <input
                      type="email"
                      className="form-control"
                      {...EmailField}
                      onChange={(e) => {
                        EmailField.onChange(e);
                        onInputChange(e);
                      }}
                    />
                    <small className="text-danger">
                      {errors.email?.type === "required" && "Email is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Phone Number*</label>
                    <input
                      type="text"
                      className="form-control "
                      {...PhoneNumberField}
                      onChange={(e) => {
                        PhoneNumberField.onChange(e);
                        onInputChange(e);
                      }}
                    />
                    <small className="text-danger">
                      {errors.phone?.type === "required" &&
                        "Phone Number is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Fax*</label>
                    <input
                      placeholder="Fax"
                      type="number"
                      className="form-control "
                      name="fax"
                      onChange={(e) => onInputChange(e)}
                    />
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>City</label>
                    <input
                      placeholder="City"
                      type="text"
                      className="form-control"
                      name="city"
                      onChange={(e) => onInputChange(e)}
                    />
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Address*</label>
                    <input
                      type="text"
                      className="form-control"
                      {...AddressField}
                      name="address"
                      onChange={(e) => {
                        AddressField.onChange(e);
                        onInputChange(e);
                      }}
                    />
                    <small className="text-danger">
                      {errors.address?.type === "required" &&
                        "Address Filed is required"}
                    </small>
                  </CFormGroup>
                </CCol>

                <CCol>
                  <CFormGroup>
                    <label>Country*</label>
                    <Controller
                      name="country"
                      control={control}
                      // defaultValue={countries?.country}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select
                          className="form-control"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            const selectedCountryID = parseInt(e.target.value);
                            setOrganization((prevOrganization) => ({
                              ...prevOrganization,
                              country: selectedCountryID,
                            }));
                            loadStateList(selectedCountryID);
                          }}
                        >
                          <option value="">---</option>
                          {allCountry.map((country, index) => {
                            return (
                              <>
                                <option key={index} value={country.value}>
                                  {country.label}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      )}
                    />

                    <small className="text-danger">
                      {errors.country?.type === "required" &&
                        "Country is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>State*</label>
                    <Controller
                      name="state"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select
                          className="form-control"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            setOrganization({
                              ...organization!,
                              state: e.target.value,
                            });
                          }}
                        >
                          <option></option>
                          {stateList.map((state, index) => {
                            return (
                              <option key={index} value={state["value"]}>
                                {state["label"]}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    />

                    <small className="text-danger">
                      {errors.state?.type === "required" && "State is required"}
                    </small>
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Timezone*</label>
                    <Controller
                      name="timezone"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field }) => (
                        <select
                          className="form-control"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            setOrganization({
                              ...organization!,
                              timezone: parseInt(e.target.value),
                            });
                          }}
                        >
                          <option></option>
                          {allTimezone.map((timezone, index) => {
                            return (
                              <option key={index} value={timezone["value"]}>
                                {timezone["label"]}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    />

                    <small className="text-danger">
                      {errors.timezone?.type === "required" &&
                        "Timezone is required"}
                    </small>
                  </CFormGroup>
                </CCol>
                <CCol>
                  <CFormGroup>
                    <label>Website</label>
                    <input
                      placeholder="Website"
                      type="url"
                      className="form-control "
                      name="website"
                      onChange={(e) => onInputChange(e)}
                    />
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormGroup>
                    <label>Description</label>
                    <textarea
                      placeholder="Description"
                      className="form-control "
                      name="description"
                      onChange={(e) => onInputChange(e)}
                    ></textarea>
                  </CFormGroup>
                </CCol>
              </CRow>
              {!isSpinning && (
                <div
                  style={{
                    placeItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                    marginTop: "10px",
                    float: "right",
                    marginBottom: "0.5rem",
                  }}
                >
                  <CButton
                    style={{ background: "var(--primary)", color: "white" }}
                    type="submit"
                    size="sm"
                  >
                    Add
                  </CButton>

                  <div className="ml-1">
                    <CButton
                      color="danger"
                      onClick={() => {
                        dispatch(setAddOrganizationModal(false));
                      }}
                      size="sm"
                    >
                      Close
                    </CButton>
                  </div>
                </div>
              )}
              {isSpinning && (
                <div
                  style={{
                    placeItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    display: "flex",
                    marginTop: "10px",
                    float: "right",
                    marginBottom: "0.5rem",
                  }}
                >
                  <CButton
                    disabled
                    style={{ backgroundColor: "#1C6387", color: "white" }}
                    size="sm"
                  >
                    <i className="fa fa-spinner fa-spin"></i>Adding
                  </CButton>
                  <div className="ml-1">
                    <CButton
                      color="danger"
                      onClick={() => {
                        dispatch(setAddOrganizationModal(false));
                      }}
                      size="sm"
                    >
                      Close
                    </CButton>
                  </div>
                </div>
              )}
            </CModalBody>
          </form>
        </CModal>
      )}
    </>
  );
};

export default AddOrganizationModal;
