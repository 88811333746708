import { CDataTable, CSwitch } from "@coreui/react";
import axios from "axios";
import DataTableNoContent from "components/datatable/datatableNoContent";
import { DTablePagination } from "components/datatable/pagination";
import TMFSearch from "components/input/TMFSearch";
import commonConstants from "constants/commonConstants";
import Constants from "constants/Constants";
import { TotalCountContext } from "context/TotalCount";
import { useContext, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import offerApi from "services/apis/offer.api";
import { ApplicationState } from "store";
import { setLeadSearchValue } from "store/leads/action";
import { setDeleteOfferModal, setOfferModal } from "store/offer/action";
import AddOfferModal from "./addOffer/addOfferModal";
import DeleteOfferModal from "./DeleteOfferModal";
import EditOfferModal from "./editOffer/editOfferModal";
import OfferActions from "./offerActions";
import OfferStatusModal from "./OfferStatusModal";
import offerTableVariables from "./offerTableVariables";
import ViewOfferModal from "./viewOfferModal";

const Offer = () => {
  const totalData = useContext(TotalCountContext);
  const dispatch: Dispatch<any> = useDispatch();
  const offer = useSelector((state: ApplicationState) => state.offer);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(commonConstants.PAGE_SIZE);
  const [OfferID, setOfferID] = useState(null);
  const [privateEvent, setPrivateEvent] = useState(null);
  const [offerStatusModal, setOfferStatusModal] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [totalPage, setTotalPage] = useState<number>(0);

  const deleteOfferModal = useSelector(
    (state: ApplicationState) => state.offer?.deleteOfferModal
  );
  const offerFilter = useSelector(
    (state: ApplicationState) => state.offer.offerFilter
  );
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const handleClicks = () => {
    csvLinkRef.current?.link.click();
  };

  useEffect(() => {
    loadOffersCount();
    loadOffers();
  }, []);

  useEffect(() => {
    loadOffersFilter();
  }, [pageNo]);

  const loadOffers = () => offerApi.fetchAllOffers(dispatch);

  const handleOfferStatus = (event, id) => {
    setOfferStatusModal(true);
    setOfferID(id);
    setPrivateEvent(event);
  };

  const loadOffersFilter = async () => {
    dispatch(setLeadSearchValue(""));
    offerApi
      .filterOffers(offerFilter, dispatch, pageSize, pageNo)
      .then((res) => { });
    // leadsApi
    //   .filterLeads(leadsFilter, dispatch, pageSize, pageNo)
    //   .then((res) => {
    //     setLoaded(false);
    //   });
  };

  const loadOffersCount = () => {
    axios.post(Constants.OFFERS_TOTAL_COUNT_URI, offerFilter).then((res) => {
      setTotalPage(Math.ceil(Math.abs(res.data / commonConstants.PAGE_SIZE)));
    });
  };

  const handleCancelSearch = () => {
    loadOffers();
  };

  const OfferPageTitleBuild = () => {
    return (
      <>

        <div className="title">
          <div className="page-title">Offers</div>
          <div>
            <button
              className="create"
              onClick={() => dispatch(setOfferModal({ showModal: true }))}
            >
              Add New
            </button>
          </div>
        </div>



        {/* <small className="py-2">
          <strong>
            Total Offers : {totalData.activeOffers + totalData.inactiveOffers}
            &nbsp;|&nbsp;Active : {totalData.activeOffers}&nbsp;|&nbsp;Inctive:
            {totalData.inactiveOffers}
          </strong>
        </small> */}
      </>
    );
  };

  const OfferPageModals = () => {
    return (
      <>
        {offerStatusModal && (
          <OfferStatusModal
            isOpen={offerStatusModal}
            onClose={() => setOfferStatusModal(false)}
            refreshDetails={() => loadOffers()}
            id={OfferID}
            event={privateEvent}
          />
        )}
      </>
    );
  };

  const DeleteOfferModals = () => {
    return (
      <>
        {deleteOfferModal?.showModal && (
          <DeleteOfferModal
            id={deleteOfferModal?.offer?.id}
            isOpen={deleteOfferModal?.showModal}
            onClose={() => dispatch(setDeleteOfferModal({ showModal: false }))}
            refreshDetails={() => loadOffers()}
            name={deleteOfferModal?.offer?.offerName}
          />
        )}
      </>
    );
  };

  const OfferTableBuild = () => {
    return (
      <div className="tmf-table_wrapper">

        <div
          className={`tmf-datatable__wrapper tmf_hide_build_in_pagination ${isSearchActive && "tmf_paggination_state"
            }`}
          style={{
            width: "100%",
            maxWidth: "100%",
            overflowX: "auto"
          }}
        >
          <div
            style={{
              borderTopColor: "#fff",
              width: "1200px",
              minWidth: "100%",
              overflowX: "auto",
            }} >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                padding: "12px",
                backgroundColor: "#fff",
              }}
            >
              <TMFSearch
                sapi={Constants.OFFERS_SEARCH_URI}
                dispatch={dispatch}
                isSearchActive={(e) => setIsSearchActive(e)}
                searchIn="offers"
                onCalcelSearch={(e) => handleCancelSearch()}
                Placeholder="Search by Name"
              />


              <div className="table-filter-section">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ position: "relative" }}>
                    <div className="d-flex" >
                     
                          <button
                            className="exportcsvwithborderradius"
                            disabled={offer.allOffers.length === 0}
                            style={{
                              cursor: offer.allOffers.length === 0 ? "not-allowed" : "pointer"
                            }}
                            onClick={handleClicks}
                          >
                            Export to CSV
                          </button>

                          <CSVLink
                            // headers={offerTableVariables.CSV}
                            data={offer.allOffers}
                            filename="Offers.csv"
                            ref={csvLinkRef}
                            className="hidden"
                          >
                          </CSVLink>
                   
                    </div>
                  </div>


                  {
                    !isSearchActive &&
                    <span style={{ marginLeft: "auto" }}>
                      <DTablePagination
                        tPage={totalPage!}
                        cPage={pageNo}
                        onPageChange={(e) => setPageNo(e)}
                      ></DTablePagination>
                    </span>
                  }

                </div>
              </div>

            </div>
            <CDataTable
              fields={offerTableVariables.fields}
              items={offer.allOffers}
              // tableFilter={{ placeholder: "search Offer" }}

              loading={offer.isAllOffersLoading}
              noItemsViewSlot={<DataTableNoContent></DataTableNoContent>}
              hover
              // sorter
              //pagination
              scopedSlots={{
                offerName: (item) => {
                  return (
                    <td>
                     <div className="name">
                        {
                          item.isDeleted ? (
                            <span
                              style={{
                                fontSize: "1rem",
                                fontWeight: "500",
                                color: "#524467",
                                textDecoration: "line-through"
                              }}
                            >
                              {item.offerName ? item.offerName.charAt(0).toUpperCase() +
                                item.offerName.slice(1).toLowerCase()
                                : "N/A"}

                            </span>
                          ) : (
                            <span
                              style={{
                                fontSize: "1rem",
                                fontWeight: "500",
                                color: "var(--txt-primary, #23005B)",
                              }}
                            >
                              {item.offerName ? item.offerName.charAt(0).toUpperCase() +
                                item.offerName.slice(1).toLowerCase()
                                : "N/A"}

                            </span>
                          )
                        }

                      </div>
                    </td>
                  )
                },
                isActive: (item) => {
                  return (
                    <td>
                      {item.isDeleted ? (
                        <CSwitch
                          disabled
                          color="success"
                          defaultChecked={item.isActive}
                          labelOn="On"
                          labelOff="Off"
                          shape="pill"
                          variant="opposite"
                          size="sm"
                        />
                      ) : (
                        <CSwitch
                          color="success"
                          defaultChecked={item.isActive}
                          labelOn="On"
                          labelOff="Off"
                          shape="pill"
                          variant="opposite"
                          size="sm"
                          onChange={(e) =>
                            handleOfferStatus(item.isActive, item.id)
                          }
                          id={"offerSwitch" + item.isActive}
                        />
                      )}
                    </td>
                  );
                },
                Action: (item) => {
                  return (
                    <td>
                      <OfferActions offer={item}></OfferActions>
                    </td>
                  );
                },
              }}
            />
            {!isSearchActive && (
              <DTablePagination
                tPage={totalPage!}
                cPage={pageNo}
                onPageChange={(e) => setPageNo(e)}
              />
            )}
          </div>

        </div>
      </div>

    );
  };
  return (
    <>
      <AddOfferModal></AddOfferModal>
      <EditOfferModal></EditOfferModal>
      <ViewOfferModal></ViewOfferModal>
      <DeleteOfferModals></DeleteOfferModals>
      <OfferPageModals></OfferPageModals>
      <OfferPageTitleBuild></OfferPageTitleBuild>
      <OfferTableBuild></OfferTableBuild>


    </>
  );
};

export default Offer;
