import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import api from "services/api.service";
import Constants from "../../constants/Constants";
import { CCard, CCardBody, CDataTable } from "@coreui/react";
import moment from "moment";
import Loader from "components/loader/Loader";

const SubscriptionDetails = () => {
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  let { organizationId } = useParams();

  useEffect(() => {
    loadPaymentHistory();
  },[]);

  const loadPaymentHistory = () =>
    api
      .get(Constants.PAYMENT_HISTORY_URI + "/" + organizationId, {
        withCredentials: true,
      })
      .then((res) => setPaymentHistory(res.data))
      .finally(() => setLoading(true));

  const fields = [
    { key: "planName", _style: { width: "20%" } },
    { key: "purchaseDate", _style: { width: "20%" } },
    { key: "purchasedBy", _style: { width: "20%" } },
    { key: "purchasedLicenses", _style: { width: "20%" } },
    { key: "skuid", _style: { width: "20%" } },
    { key: "totalCost", _style: { width: "20%" } },
    {
      key: "Action",
      label: "",
      _style: { width: "1%" },
      sorter: false,
      filter: false,
    },
  ];

  return (
    <div>
      {loading ? (
        <div>
          <Link className="btn btn-info btn-sm" to="/organization">
            Back
          </Link>

          <div className="text-center">Subscription Details</div>
          <CCard>
            <CCardBody>
              <CDataTable
                items={paymentHistory}
                fields={fields}
                tableFilter={{ placeholder: "search Subscription-History" }}
                itemsPerPageSelect
                itemsPerPage={5}
                sorter
                pagination
                scopedSlots={{
                  Action: (paymentHistory, index) => {
                    return (
                      <td className="py-2">
                        <Link
                          className="btn btn-sm float-right"
                          to={`/invoice/${paymentHistory.subscriptionID}`}
                          style={{
                            backgroundColor: "#0091ea",
                            color: "#FFF",
                          }}
                        >
                          Invoice
                        </Link>
                      </td>
                    );
                  },
                  purchaseDate: (item) => {
                    return (
                      <td>{moment(item.purchaseDate).format("MM/DD/YYYY")}</td>
                    );
                  },
                }}
              />
            </CCardBody>
          </CCard>
        </div>
      ) : (
        <div className="spinner">
          <Loader
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="2em"
          />
        </div>
      )}
    </div>
  );
};

export default SubscriptionDetails;
