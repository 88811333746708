import apiUrls from "constants/Constants";
import { Dispatch } from "redux";
import api from "services/api.service";
import { setCountries, setTimezones } from "store/global/action";

const commonApi = {
  fetchUser: function (): Promise<IAuthUser> {
    return api
      .get(apiUrls.USER_ME_URI)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  fetchCountries: function (dispatch: Dispatch<any>): Promise<ICountry[]> {
    return api
      .get(apiUrls.COUNTRY_GET_URI)
      .then((res) => {
        dispatch(setCountries(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  fetchTimezones: function (dispatch: Dispatch<any>): Promise<ITimezone[]> {
    return api
      .get(apiUrls.TIMEZONE_GET_URI)
      .then((res) => {
        dispatch(setTimezones(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default commonApi;
