import { CButton, CModal, CModalBody } from "@coreui/react";
import api from "services/api.service";
import { useState } from "react";
import Constants from "../../constants/Constants";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeletePlanModal = ({ id, refreshDetails, isOpen, onClose, name }) => {
  const [isSpinning, setSpinning] = useState(false);

  const deletePlan = async () => {
    setSpinning(true);
    await api.post(Constants.PLAN_DELETE_POST_URI + "/" + id).then(async () => {
      toast.success("😊 Plan deleted successfully.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setSpinning(false);
      await refreshDetails();
      onClose();
    }).catch((error) => {
      toast.error(`😔 Error while deleting plan!`, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    })
  };

  return (
    <div>
      <CModal
        show={isOpen}
        onClose={onClose}
        centered={true}
        closeOnBackdrop={false}
      >
        <CModalBody>
          <div>Do you want to delete this plan?</div>
          {!isSpinning && (
            <div
              style={{
                placeItems: "center",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                // marginBottom: "1rem",
                float: "right",
              }}
            >
              <CButton color="danger" onClick={() => deletePlan()} size="sm">
                Ok
              </CButton>
              <CButton
                onClick={onClose}
                color="secondary"
                className="ml-2"
                size="sm"
              >
                Cancel
              </CButton>
            </div>
          )}
          {isSpinning && (
            <div
              style={{
                placeItems: "center",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                // marginBottom: "1rem",
                float: "right",
              }}
            >
              <CButton color="info" disabled size="sm">
                <i className="fa fa-spinner fa-spin"></i> Deleting
              </CButton>
              <CButton
                onClick={onClose}
                color="secondary"
                className="ml-2"
                size="sm"
              >
                Cancel
              </CButton>
            </div>
          )}
        </CModalBody>
      </CModal>
    </div>
  );
};

export default DeletePlanModal;
