import { Dispatch } from "redux";
import apiUrls from "constants/Constants";
import api from "services/api.service";
import {
  setAddPartnerModal,
  setAllPartnerLoader,
  setAllPartners,
  setEditPartnerModal,
  setPartnerTypes,
  setViewPartnerModal,
} from "store/partner/action";
import { setAllLeadLoader } from "store/leads/action";

const partnerApi = {
  filterPartners: (payload: IPartnerFilter, dispatch: Dispatch<any>, pageSize, pageNo) => {
    dispatch(setAllLeadLoader(true));
    return api
      .post(`${apiUrls.PARTNER_FILTER_URI}/${pageSize}/${pageNo}`, payload)
      .then((res) => {
        dispatch(setAllPartners(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => dispatch(setAllPartnerLoader(false)));
  },

  fetchAllPartner: function (dispatch: Dispatch<any>) {
    dispatch(setAllPartnerLoader(true));
    return api
      .get(apiUrls.PARTNER_GET_ALL_URI)
      .then((res) => {
        dispatch(setAllPartners(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => dispatch(setAllPartnerLoader(false)));
  },

  fetchPartnerTypes: function (dispatch: Dispatch<any>) {
    dispatch(setAllPartnerLoader(true));
    return api
      .get(apiUrls.PARTNER_TYPE_GET_ALL)
      .then((res) => {
        dispatch(setPartnerTypes(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => dispatch(setAllPartnerLoader(false)));
  },

  createNewPartner: function (dispatch: Dispatch<any>, payload: IPartnerFormData) {
    dispatch(setAddPartnerModal({ isModalFormSubmitting: true }));
    return api
      .post(apiUrls.PARTNER_CREATE_URL, payload)
      .then((res) => {
        dispatch(setAddPartnerModal(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => dispatch(setAddPartnerModal({ isModalFormSubmitting: false })));
  },
  updatePartner: function (dispatch: Dispatch<any>, payload: IPartnerFormData, partnerID?: number) {
    dispatch(setEditPartnerModal({ isModalFormSubmitting: true }));
    return api
      .post(`${apiUrls.PARTNER_UPDATE_URL}/${partnerID}`, payload)
      .then((res) => {
        dispatch(this.updatePartner(res.data, payload));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() =>
        dispatch(setEditPartnerModal({ isModalFormSubmitting: false }))
      );
  },
  viewPartner: function (dispatch: Dispatch<any>, partnerID?: number) {
    dispatch(setViewPartnerModal({ isModalFormSubmitting: false }));
    return api
      .post(`${apiUrls.PARTNER_VIEW_URL}/${partnerID}`)
      .then((res) => {
        dispatch(this.viewPartner(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() =>
        dispatch(setViewPartnerModal({ isModalFormSubmitting: false }))
      );
  },
};

export default partnerApi;
