import api from "services/api.service";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Constants from "../../constants/Constants";
import {
  CCard,
  CCardBody,
  CDataTable,
  CSwitch,
  CRow,
  CCol,
} from "@coreui/react";
import Loader from "components/loader/Loader";
import moment from "moment";
import SubscriptionMailModal from "../../components/modals/mail/SubscriptionMailModal";
import { Link } from "react-router-dom"; 

const ExpiryIn = () => {
  const [expiry, setExpiry] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mailModal, setMailModal] = useState({
    organization: null,
    status: false,
  });

  let { condition } = useParams();
  // alert(condition);
  useEffect(() => {
    loadExpiry();
    //console.log("Expired data", loadExpiry());
  }, []);

  const loadExpiry = async () => {
    const result = await api.getWithParams(
      Constants.SUBSCRIPTION_EXPIRED_URI + `/${condition}`,
      {
        withCredentials: true,
      }
    );
    setExpiry(result.data);
    setLoading(true);
  };

  const fields = [
    { key: "name", label: "Organization Name", _style: { width: "10%" } },
    { key: "isActive", _style: { width: "10%" } },
    { key: "startDate", _style: { width: "10%" } },
    { key: "endDate", _style: { width: "10%" } },
    { key: "sendMail", _style: { width: "10%" } },
  ];

  // const loadSubscriptionFilter = () => {
  //   api.post(Constants.SUBSCRIPTION_FILTER_URI, request).then((res) => {
  //     setReport(res.data);
  //     window.alert(`You are viewing Subscription Report!`);
  //   });
  // };

  const MailModalPage = () => {
    return (
      <>
        {mailModal && (
          <SubscriptionMailModal
            isOpen={mailModal.status}
            onClose={() => setMailModal({ status: false })}
            // refreshDetails={() => loadSubscriptionFilter()}
            orgData={mailModal.organization}
          />
        )}
      </>
    );
  };
  return (
    <div>
      <MailModalPage />
      {loading ? (
        <div>
          {/* <CsvExpiryInWeeks /> */}
          <div className="text-center mb-3">
            <CRow>
              <CCol md={1} sm={1}>
                <Link
                  type="none"
                  className="btn btn-info btn-sm"
                  to={`/subscription-history`}
                >
                  Back
                </Link>
              </CCol>
              <CCol>
                <span>Subscriptions Expiring in this {condition} </span>
              </CCol>
            </CRow>
          </div>

          <CCard>
            <CCardBody>
              <CDataTable
                items={expiry}
                fields={fields}
                // tableFilter={{ placeholder: "Search Organization" }}
                itemsPerPageSelect
                itemsPerPage={5}
                sorter
                pagination
                scopedSlots={{
                  isActive: (item) => {
                    return (
                      <td>
                        <CSwitch
                          color="success"
                          isDeleted={item.isDeleted ? true : false}
                          defaultChecked={item.isActive}
                          labelOn="On"
                          labelOff="Off"
                          shape="pill"
                          size="sm"
                          onChange={() => ""}
                        />
                      </td>
                    );
                  },
                  startDate: (item) => {
                    return (
                      <td>{moment(item.startDate).format("MM/DD/YYYY")}</td>
                    );
                  },
                  endDate: (item) => {
                    return <td>{moment(item.endDate).format("MM/DD/YYYY")}</td>;
                  },
                  sendMail: (item) => {
                    return (
                      <td>
                        <button
                          className="btn btn-sm btn-info"
                          onClick={() =>
                            setMailModal({ status: true, organization: item })
                          }
                        >
                          <i className="fa fa-envelope" aria-hidden="true"></i>
                        </button>
                      </td>
                    );
                  },
                }}
              />
            </CCardBody>
          </CCard>
        </div>
      ) : (
        <div className="spinner">
          <Loader color="blue.500" size="2rem" />
        </div>
      )}
    </div>
  );
};

export default ExpiryIn;
