import { CategoryActionTypes } from "./action.types";


//Loader category get
export function getAllCategoryLoader(isLoading: boolean) {
  const action: CategoryAction = {
    type: CategoryActionTypes.SET_ALL_COURT_CATEGORY_LOADER,
    isLoading,
  };
  return (dispatch: CategoryDispatchType) => dispatch(action);
}

// All Court Category
export function getAllCourtCategory(category: ICategory[]) {
  const action: CategoryAction = {
    type: CategoryActionTypes.SET_ALL_COURT_CATEGORY,
    category,
  };
  return (dispatch: CategoryDispatchType) => dispatch(action);
}

//LoaderSubCategory
export function getSubCategoryLoader(isLoading: boolean) {
  const action: CategoryAction = {
    type: CategoryActionTypes.SET_COURT_SUB_CATEGORY_LOADER,
    isLoading,
  };
  return (dispatch: CategoryDispatchType) => dispatch(action);
}

// All Court Sub Category
export function getCourtSubCategory(subCategory: ICategory[]) {
  const action: CategoryAction = {
    type: CategoryActionTypes.SET_COURT_SUB_CATEGORY,
    subCategory,
  };
  return (dispatch: CategoryDispatchType) => dispatch(action);
}





//Open Category Modal
export function setAddSubCategoryModal(addSubCategoryModal: ICategoryModal) {
  const action: CategoryAction = {
    type: CategoryActionTypes.ADD_SUB_CATEGORY_MODAL,
    addSubCategoryModal,
  };
  return (dispatch: UserDispatchType) => dispatch(action);
}

export function setEditCategoryModal(editCategoryModal: ICategoryModal) {
  const action: CategoryAction = {
    type: CategoryActionTypes.EDIT_CATEGORY_MODAL,
    editCategoryModal,
  };
  return (dispatch: UserDispatchType) => dispatch(action);
}

export function setDeleteCategoryModal(deleteCategoryModal: ICategoryModal) {
  const action: CategoryAction = {
    type: CategoryActionTypes.DELETE_CATEGORY_MODAL,
    deleteCategoryModal,
  };
  return (dispatch: UserDispatchType) => dispatch(action);
}


