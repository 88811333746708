import { CButton, CFormGroup, CInput, CModal, CModalBody } from "@coreui/react";
import { useState } from "react";
import api from "services/api.service";
import Constants from "../../constants/Constants";

const UpdateLeadsMailStatus = ({
  leadsData,
  refreshDetails,
  isOpen,
  onClose,
}) => {
  const [isSpinning, setSpinning] = useState(false);
  const [emailStatusValue, setEmailStatusValue] = useState("");

  const updateLeadMailStatus = async () => {
    const data = {
      email: leadsData.email,
      mailStatus: emailStatusValue,
    };
    setSpinning(true);
    await api.post(Constants.LEAD_MAIL_UPDATE_URI, data).then(async () => {
      setSpinning(false);
      await refreshDetails();
      onClose();
    });
  };
  const handleInputChange = (e) => {
    setEmailStatusValue(e.target.value);
  };

  return (
    <div>
      <CModal
        show={isOpen}
        onClose={onClose}
        centered={true}
        closeOnBackdrop={false}
      >
        <CModalBody>
          <CFormGroup>
            <CInput
              type="text"
              onChange={() => {}}
              placeholder="Add mail status..."
              defaultValue={leadsData?.mailStatus}
              onChangeCapture={(e) => {
                handleInputChange(e);
              }}
            />
          </CFormGroup>
          {/* <CFormInput type="text" placeholder="Add Email status"/> */}
          {!isSpinning && (
            <div
              style={{
                placeItems: "center",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                // marginBottom: "1rem",
                float: "right",
              }}
            >
              <CButton
                color="info"
                onClick={() => updateLeadMailStatus()}
                size="sm"
              >
                Change
              </CButton>
              <CButton
                onClick={onClose}
                color="danger"
                className="ml-2"
                size="sm"
              >
                Cancel
              </CButton>
            </div>
          )}
          {isSpinning && (
            <div
              style={{
                placeItems: "center",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                // marginBottom: "1rem",
                float: "right",
              }}
            >
              <CButton color="info" disabled size="sm">
                <i className="fa fa-spinner fa-spin"></i> Adding
              </CButton>
              <CButton
                onClick={onClose}
                color="secondary"
                className="ml-2"
                size="sm"
              >
                Cancel
              </CButton>
            </div>
          )}
        </CModalBody>
      </CModal>
    </div>
  );
};

export default UpdateLeadsMailStatus;
