const SearchLoader = () => {
    <style>
        {`
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
    `}
    </style>
    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
            }}
        >
            <img
                style={{
                    height: '1.25rem', // Equivalent to h-5
                    width: '1.25rem', // Equivalent to w-5
                    animation: 'spin 1s linear infinite', // Basic spin animation
                }}
                src="https://www.svgrepo.com/show/199956/loading-loader.svg"
                alt="Loading icon"
            />
        </div>
    );
}

export default SearchLoader;