import { Reducer } from "redux";
import { BookmarkActionTypes } from "./action.types";

export const initialState: bookmarkState = {
    isLoading: false,
    bookmark: {
        data: [],
        totalcount: 0
    }
};

const reducer: Reducer<bookmarkState> = (state = initialState, action) => {
    switch (action.type) {
        case BookmarkActionTypes.SET_BOOKMARK_BY_ID_LOADER: {
            return {
                ...state,
                isLoading: action.isLoading,
            };
        }

        case BookmarkActionTypes.SET_BOOKMARK_BY_ID: {
            return {
                ...state,
                bookmark: action.bookmark,
            };
        }

        case BookmarkActionTypes.SET_ADD_BOOKMARK_MODAL: {
            return {
                ...state,
                addBookmarkModal: { ...state.addBookmarkModal, ...action.addBookmarkModal },
            };
        }

        case BookmarkActionTypes.SET_EDIT_BOOKMARK_MODAL: {
            return {
                ...state,
                editBookmarkModal: { ...state.editBookmarkModal, ...action.editBookmarkModal },
            };
        }
        case BookmarkActionTypes.SET_DELETE_BOOKMARK_MODAL: {
            return {
                ...state,
                deleteBookmarkModal: { ...state.deleteBookmarkModal, ...action.deleteBookmarkModal },
            };
        }
        case BookmarkActionTypes.SET_TAG_BOOKMARK_MODAL: {
            return {
                ...state,
                tagBookmarkModal: { ...state.tagBookmarkModal, ...action.tagBookmarkModal },
            };
        }



        default: {
            return state;
        }

    }

}


export { reducer as bookmarkReducer };
