import { GlobalActionTypes } from "./action.types";

export function setCountries(countries: ICountry[]) {
  const action: GlobalAction = {
    type: GlobalActionTypes.SET_COUNTRIES,
    countries,
  };
  return (dispatch: GlobalDispatchTypes) => dispatch(action);
}

export function setTimezones(timezones: ITimezone[]) {
  const action: GlobalAction = {
    type: GlobalActionTypes.SET_TIMEZONES,
    timezones,
  };
  return (dispatch: GlobalDispatchTypes) => dispatch(action);
}

export function setSelectData(currentSelectData: ISelectData) {
  const action: GlobalAction = {
    type: GlobalActionTypes.SET_SELECT_DATA,
    currentSelectData,
  };
  return (dispatch: GlobalDispatchTypes) => dispatch(action);
}
export function resetSelectData() {
  const action: GlobalAction = {
    type: GlobalActionTypes.RESET_SELECT_DATA,
  };
  return (dispatch: GlobalDispatchTypes) => dispatch(action);
}

export function setSelectSearch(currentSelectSearch: string) {
  const action: GlobalAction = {
    type: GlobalActionTypes.SET_SELECT_SEARCH,
    currentSelectSearch,
  };
  return (dispatch: GlobalDispatchTypes) => dispatch(action);
}
