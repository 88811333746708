import {
    CButton,
    CCol,
    CFormGroup,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CRow,
} from "@coreui/react";
import { Icon } from "components/common/iconselector/Icon";
import IconSelector from "components/common/iconselector/IconSelector";
import SearchLoader from "components/loader/SearchLoader";
import isValidUrl from "helper/UrlChecker";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api.service";
import Constants from "../../../constants/Constants";

interface IEditBookmarkModal {
    refreshDetails: () => void,
    onClose: any,
    isOpen: boolean,
    title: string,
    selectedBookmark: IBookmark | null | undefined,
    selectedCategoryId: string | null
}

const EditBookmarkModal: React.FC<IEditBookmarkModal> = ({ refreshDetails, isOpen, onClose, title, selectedBookmark, selectedCategoryId }) => {
    const [link, setLink] = useState('');
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const [isLinkFromApi, setIsLinkFromApi] = useState(false);
    const [resolvedData, setResolvedData] = useState<IBookmark>();
    const [checked, setChecked] = useState<boolean>(false);
    const [isInvalidUrl, setInvalidUrl] = useState<boolean>(false);
    const [isLinkNameEmpty, setIsLinkNameEmpty] = useState(false);

    const [isSpinning, setSpinning] = useState(false);
    const [isResolveSpinning, setResolveSpinning] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState<string | null>(Icon.folder);
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm();


    const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLink(event.target.value);
        setValue("Path", event.target.value)
        setValue("ShortUrl", event.target.value)
        setIsLinkFromApi(false);
    };

    useEffect(() => {
        if (link === '') return;

        if (selectedBookmark && selectedBookmark.path === link) return;

        if (checked === false) return;
        if (!isValidUrl(link)) {
            setInvalidUrl(true);
            setIsLinkNameEmpty(false);
            return;
        } else {
            setInvalidUrl(false);
            setIsLinkNameEmpty(false);
        }
        if (timer) {
            clearTimeout(timer);
        }

        if (!isLinkFromApi) {
            const newTimer = setTimeout(async () => {
                if (link.trim() !== '') {
                    const data = {
                        Path: link,
                        CategoryID: selectedCategoryId
                    }

                    setResolveSpinning(true);
                    try {
                        const res = await api.post(Constants.GET_POST_RESOLVED_BOKMARK_DATA, data);
                        setResolvedData(res.data)
                    } catch (ex) {
                        setResolvedData(undefined);
                    } finally {
                        setResolveSpinning(false);
                    }
                } else {
                    setResolvedData(undefined);
                }
            }, 1500);

            setTimer(newTimer);
        }


        // Clean up the timer on unmount or input change
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [link, checked]);


    const handleCheckTagBlur = () => {
        if (link === "") {
            setIsLinkNameEmpty(true);
        } else {
            setIsLinkNameEmpty(false);
        }
    }

    const handleCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(e.target.checked)
    }

    //Change the value when bookmark data are available
    useEffect(() => {
        if (resolvedData) {
            setValue("Icon", resolvedData.icon)
            setValue("Path", resolvedData.path)
            setValue("ShortUrl", resolvedData.shortUrl)
            setValue("Title", resolvedData.title)
            setValue("Description", resolvedData.description)
            setLink(resolvedData.path)
            setSelectedIcon(resolvedData.icon)
            setIsLinkFromApi(true);
        }
    }, [resolvedData])



    useEffect(() => {
        if (selectedIcon) {
            setValue("Icon", selectedIcon)
        }
    }, [selectedIcon])

    useEffect(() => {
        if (selectedBookmark) {
            setValue("CategoryID", selectedBookmark.categoryID)
            setValue("createdBy", selectedBookmark.createdBy)
            setValue("creationDate", selectedBookmark.creationDate)
            setValue("fetchMetaData", selectedBookmark.fetchMetaData)
            setValue("Icon", selectedBookmark.icon)
            setValue("Id", selectedBookmark.id)
            setValue("internalNotes", selectedBookmark.internalNotes)
            setValue("isActive", selectedBookmark.isActive)
            setValue("isDeleted", selectedBookmark.isDeleted)
            setValue("isDublicate", selectedBookmark.isDublicate)
            setValue("isPinned", selectedBookmark.isPinned)
            setValue("isPublic", selectedBookmark.isPublic)
            setValue("isPrivate", selectedBookmark.isPrivate)
            setValue("isShared", selectedBookmark.isShared)
            setValue("modifiedBy", selectedBookmark.modifiedBy)
            setValue("Path", selectedBookmark.path)
            setValue("ShortUrl", selectedBookmark.shortUrl || "")
            setValue("Type", selectedBookmark.type)
            setValue("Title", selectedBookmark.title)
            setValue("Description", selectedBookmark.description || "")
            setValue("userID", selectedBookmark.userID)
            setSelectedIcon(selectedBookmark.icon)
            setLink(selectedBookmark.path)
        }
    }, [selectedBookmark])



    const onSubmit = async (data: any) => {
        setSpinning(true);
        try {
            const res = await api.put(Constants.UPDATE_BOOKMARK, data);

            if (res.status === 200) {
                toast.success("😊 Category Updated successfully.", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                await onClose();
                refreshDetails();
            }
        } catch (err) {
            // Handle errors
            toast.error("Error Found", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        } finally {
            setSpinning(false);
        }
    }

    return (
        <div>
            <CModal show={isOpen} onClose={onClose} closeOnBackdrop={true}>
                <CModalHeader closeButton>
                    <CModalTitle>
                        {" "}
                        <div> {title}</div>{" "}
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ fontWeight: "bold", fontSize: "12px" }}
                    >

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <label style={{ display: 'inline-block' }}>
                                <span style={{ display: 'block', color: '#4B5563', fontWeight: 'normal', marginBottom: '0.25rem' }}>
                                    <input
                                        type="checkbox"
                                        style={{ height: '1rem', width: '1rem', color: '#3B82F6', verticalAlign: 'middle', marginRight: '0.25rem' }}
                                        checked={checked}
                                        onChange={(e) => handleCheckedChange(e)}
                                    />
                                    Resolve URL
                                </span>
                            </label>
                        </div>

                        <div className="flex-two-row">
                            <IconSelector selectedIcon={selectedIcon} setSelectedIcon={setSelectedIcon} iconOptions={Icon} />
                            <div className="form-title">
                                <label>Title*</label>
                                <input
                                    type="text"
                                    className="form-control "
                                    {...register("Title", { required: true, maxLength: 60 })}
                                    maxLength={60}
                                />
                                <small className="text-danger">
                                    {errors.Name?.type === "required" && "Collection Name is required"}
                                    {errors.Name?.type === "maxLength" && "Collection Name must not exceed 60 characters"}
                                </small>
                            </div>
                        </div>

                        <CRow>
                            <CCol>
                                <CFormGroup>
                                    <label>Link*</label>
                                    <div style={{ position: "relative" }}>
                                        <input
                                            type="url"
                                            className="form-control "
                                            value={link}
                                            {...register("Path", { required: true, maxLength: 60 })}
                                            onChange={handleLinkChange}
                                            onBlur={handleCheckTagBlur}
                                            maxLength={60}
                                        />
                                        {
                                            isResolveSpinning && (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        border: 'none',
                                                        backdropFilter: 'blur(10px)',
                                                        height: '90%',
                                                        right: '0.5px',
                                                        top: '0.5px',
                                                        borderRadius: '0.375rem',
                                                        padding: '0.5rem 0.5rem',
                                                    }}
                                                >
                                                    <SearchLoader />
                                                </div>
                                            )
                                        }


                                    </div>
                                    <small className="text-danger">
                                        {isLinkNameEmpty && "Link name is empty"}
                                        {isInvalidUrl && "Link is not valid"}
                                    </small>
                                </CFormGroup>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol>
                                <CFormGroup>
                                    <label>Description</label>
                                    <textarea
                                        placeholder="Optional"

                                        className="form-control"
                                        {...register("Description", { maxLength: 200 })}
                                        maxLength={200}
                                    ></textarea>
                                    <small className="text-danger">
                                        {errors.Description?.type === "maxLength" && "Collection Description must not exceed 60 characters"}

                                    </small>
                                </CFormGroup>
                            </CCol>
                        </CRow>

                        {!isSpinning && (
                            <div
                                style={{
                                    placeItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    display: "flex",
                                    marginTop: "10px",
                                    float: "right",
                                }}
                            >
                                <CButton
                                    style={{
                                        background: "var(--primary)",
                                        color: "white"
                                    }}
                                    type="submit"
                                    size="sm"
                                >
                                    Add
                                </CButton>

                                <div className="ml-1">
                                    <CButton color="danger" onClick={onClose} size="sm">
                                        Close
                                    </CButton>
                                </div>
                            </div>
                        )}

                        {isSpinning && (
                            <div
                                style={{
                                    placeItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    display: "flex",
                                    marginTop: "10px",
                                    float: "right",
                                }}
                            >
                                <CButton
                                    disabled
                                    style={{
                                        background: "var(--primary)",
                                        color: "white"
                                    }}
                                    size="sm"
                                >
                                    <i className="fa fa-spinner fa-spin"></i> Adding
                                </CButton>
                                <div className="ml-1">
                                    <CButton color="danger" onClick={onClose} size="sm">
                                        Close
                                    </CButton>
                                </div>
                            </div>
                        )}

                    </form>
                </CModalBody>
            </CModal>
        </div>
    );
};

export default EditBookmarkModal;
