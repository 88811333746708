import { Reducer } from "redux";
import { UserActionTypes, usersFilterInitial } from "./action.types";

export const initialState: userState = {
  users: [],
  isUsersLoading: false,
  usersFilter: usersFilterInitial,
  isUserLoading: { id: null, isStatusLoading: false },
};

const reducer: Reducer<userState> = (state = initialState, action) => {
  switch (action.type) {
    case UserActionTypes.SET_USERS: {
      return {
        ...state,
        users: [...action.users],
      };
    }
    case UserActionTypes.SET_USERS_LOADER: {
      return {
        ...state,
        isUsersLoading: action.isUsersLoading,
      };
    }

    case UserActionTypes.SET_USER_LOADER: {
      return {
        ...state,
        isUserLoading: {
          ...state.isUserLoading,
          ...action.isUserLoading,
        },
      };
    }
    case UserActionTypes.SET_USERS_FILTER: {
      return {
        ...state,
        usersFilter: {
          ...state.usersFilter,
          ...action.usersFilter,
        },
      };
    }

    case UserActionTypes.SET_USER_STATUS: {
      return {
        ...state,
        users: state.users.map((el) => {
          if (el.userID === action.user.userID) {
            el.isActive = action.user.isActive;
          }
          return el;
        }),
      };
    }
    case UserActionTypes.EDIT_USER_MODAL: {
      return {
        ...state,
        editUserModal: { ...state.editUserModal, ...action.editUserModal },
      };
    }
    case UserActionTypes.DELETE_USER_MODAL: {
      return {
        ...state,
        deleteUserModal: { ...state.deleteUserModal, ...action.deleteUserModal },
      };
    }
    case UserActionTypes.VIEW_USER_MODAL: {
      return {
        ...state,
        viewUserModal: { ...state.viewUserModal, ...action.viewUserModal },
      };
    }
    case UserActionTypes.UNDO_DELETE_USER_MODAL: {
      return {
        ...state,
        undoDeleteUserModal: { ...state.undoDeleteUserModal, ...action.undoDeleteUserModal },
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as userReducer };
