import axios from "axios";
import "./apiHeader.service";

const api =  {
  /**
   * a common get function to handle get requests
   */
  async get(url: string) {
    return await axios
      .get(url)
      .then((resp) => {
        return resp;
      })
      .catch(async (err) => {
        throw err.request;
      });
  },

  /**
   * a common get function with params to handle get requests
   */
  async getWithParams(url: string, params: { [key: string]: any }) {
    return await axios
      .get(url, {
        params: { ...params },
      })
      .then((resp) => {
        return resp;
      })
      .catch(async (err) => {
        throw err.request;
      });
  },

  /**
   * a common post function to handle post requests
   */
  async post(url: string, payload?: { [key: string]: any }) {
    return await axios
      .post(url, payload)
      .then((resp) => {
        return resp;
      })
      .catch(async (err) => {
        throw err.request;
      });
  },

  /**
   * a common delete function to handle delete requests
   */
  async delete(url: string) {
    return await axios
      .delete(url)
      .then((resp) => {
        return resp;
      })
      .catch(async (err) => {
        throw err.request;
      });
  },
  /**
   * a common put function to handle put requests
   */
  async put(url: string, payload: { [key: string]: any }) {
    return await axios
      .put(url, payload)
      .then((resp) => {
        return resp;
      })
      .catch(async (err) => {
        throw err.request;
      });
  },
};

export default api;
