interface IInputProps extends React.ComponentProps<"input"> {}

const Input = (props: IInputProps) => {
  return (
    <input
      className="input"
      autoComplete="off"
      autoCorrect="off"
      {...props}
    />
  );
};

export default Input;
