import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import AvishSelect from "../../formElement/select";

interface IPartnerFormElementProps {
  partner?: Ipartner | null;
}

const PartnerFormElements = ({ partner = null }: IPartnerFormElementProps) => {
  const subscriptionTypes = useSelector(
    (state: ApplicationState) => state.subscription?.subscriptionTypes
  );

  const partnerTypes = useSelector(
    (state: ApplicationState) => state.partner?.partnerTypes
  );

  const mappedSubscriptionTypes = subscriptionTypes.reduce((attr, el) => {
    let subscriptionType: ISelectData = {
      label: el.type,
      value: el.id.toString(),
    };
    attr.push(subscriptionType);
    return attr;
  }, [] as ISelectData[]);

  const [selectedSubscriptionType, setSelectedSubscriptionType] =
    useState<ISelectData>(mappedSubscriptionTypes[0]);
  const [changeInDefaultValue, setChangeInDefaultValue] =
    useState<boolean>(false);
  const allOrganizations = useSelector(
    (state: ApplicationState) => state.organization?.allOrganizations
  );

  useEffect(() => {
    if (partner) {
      setSelectedSubscriptionType({
        label: partner.organizationName,
        value: partner?.organizationName.toString(),
      });
    } else {
      setSelectedSubscriptionType(mappedSubscriptionTypes[0]);
    }
    setChangeInDefaultValue(true);
  }, [partner]);

  return (
    <>
      <div className="form-groups">
        <AvishSelect
          options={allOrganizations.reduce((attr, el) => {
            var type: ISelectData = {
              value: el.organizationID.toString(),
              label: el.name,
            };
            attr.push(type);
            return attr;
          }, [] as ISelectData[])}
          name="orgID"
          required
          label="Organization*"
         
        ></AvishSelect>
        <AvishSelect
          options={partnerTypes?.reduce((attr, el) => {
            var type: ISelectData = {
              value: el.partnerTypeId.toString(),
              label: el.partnerName,
            };
            attr.push(type);
            return attr;
          }, [] as ISelectData[]) || []}
          name="partnerTypeID"
          required
          label="Partner Type*"
        ></AvishSelect>
       
      </div>
    </>
  );
};

export default PartnerFormElements;
