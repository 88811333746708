import {
  CButton,
  CDataTable,
  CSwitch
} from "@coreui/react";
import axios from "axios";
import DataTableNoContent from "components/datatable/datatableNoContent";
import { DTablePagination } from "components/datatable/pagination";
import TMFSearch from "components/input/TMFSearch";
import AddSubscriptionModal from "components/modals/subscription/AddSubscriptionModal";
import DeleteSubscriptionModal from "components/modals/subscription/DeleteSubscriptionModal";
import EditSubscriptionModal from "components/modals/subscription/EditSubscriptionModal";
import UndoDeleteSubscriptionModal from "components/modals/subscription/UndoDeleteSubscriptionModal";
import ViewSubscriptionDetails from "components/modals/subscription/ViewSubscriptionDetails";
import Constants from "constants/Constants";
import commonConstants from "constants/commonConstants";
import { TotalCountContext } from "context/TotalCount";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import organizationApi from "services/apis/organization.api";
import planApi from "services/apis/plan.api";
import subscriptionApi from "services/apis/subscription.api";
import { ApplicationState } from "store";
import { setLeadSearchValue } from "store/leads/action";
import {
  setDeleteSubscriptionModal,
  setEditSubscriptionModal,
  setFilteredSubscriptions,
  setUndoDeleteSubscriptionModal,
  setViewSubscriptionMailModal,
  setViewSubscriptionModal
} from "store/subscription/action";
import SubscriptionMailModal from "../../components/modals/mail/SubscriptionMailModal";
import SubscriptionStatusModal from "./SubscriptionStatusModal";
import SubscriptionActions from "./subscriptionActions";
import SubscriptionFilter from "./subscriptionFilter";
import subscriptionHistoryVariables from "./subscriptionHistoryVariables";

const Subscription = () => {
  // const totalData = useContext(TotalCountContext);
  const dispatch: Dispatch<any> = useDispatch();
  const subscription = useSelector(
    (state: ApplicationState) => state.subscription
  );
  // const [editSubscritionModal, setEditSubscriptionModal] = useState(false);
  // const [deleteSubscriptionModal, setDeleteSubscritionModal] = useState(false);
  // const [request, setRequest] = useState({
  //   year: null,
  //   monthName: null,
  //   PlanID: null,
  //   OrgID: null,
  //   IsActive: null,
  //   SubscriptionTypeID: null,
  //   BeginDate: null,
  //   EndDate: null,
  // });
  // const [report, setReport] = useState([]);
  // const csvLinkRef = useRef<
  //   CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  // >(null);

  const [addSubscriptionModal, setAddSubscriptionModal] = useState(false);
  const [pageSize, setPageSize] = useState(commonConstants.PAGE_SIZE);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [subscriptionCount, setSubscriptionCount] = useState();
  const [totalSubscriptionFilter, setTotalSubscriptionFilter] = useState("");
  const [subscriptionStatusModal, setSubscriptionStatusModal] = useState(false);
  const [subscriptionID, setSubscriptionID] = useState(Number);
  const [subscriptionEvent, setSubscriptionEvent] = useState(Boolean);
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const isFirstMount = useRef(true)

  const searchValue = useSelector(
    (state: ApplicationState) => state.lead.leadSearchValue
  );


  const initialLoadSubscriiption = () => {
    subscriptionApi.fetchFilteredSubscriptions(
      subscription.subscriptionsFilter,
      dispatch,
      pageSize,
      pageNo
    );
  }

  const loadSubscriptionFilter = () => {
    if (searchValue) {
      axios
        .get(`${Constants.SUBSCRIPTION_SEARCH_URI}/${searchValue}`)
        .then((res) => {
          dispatch(setFilteredSubscriptions(res.data));


        })
    } else {
      setIsSearchActive(false);
      subscriptionApi.fetchFilteredSubscriptions(
        subscription.subscriptionsFilter,
        dispatch,
        pageSize,
        pageNo
      );
    }
  };

  const cancelSubscriptionData = () => {
    subscriptionApi.fetchFilteredSubscriptions(
      subscription.subscriptionsFilter,
      dispatch,
      pageSize,
      pageNo
    );
  }

  const getAllSubscriptionDataCount = () => {
    axios
      .post(Constants.GET_SUBSCRIPTION_COUNT, subscription.subscriptionsFilter)
      .then((res) => {
        setTotalPage(Math.ceil(Math.abs(res.data / commonConstants.PAGE_SIZE)));
        setSubscriptionCount(res.data);
      });
  };

  const getOrganizationList = () => organizationApi.fetchAllOrganizations(dispatch);

  const loadPlan = () => planApi.fetchAllPlans(dispatch);

  const getTotalSubscriptionList = () => {
    dispatch(setLeadSearchValue(""));
    axios
      .post(Constants.SUBSCRIPTION_FILTER_URI, subscription.subscriptionsFilter)
      .then((res) => {
        // dispatch(setFilteredSubscriptions(res.data));
        setTotalSubscriptionFilter(res.data);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false
      initialLoadSubscriiption();
      getAllSubscriptionDataCount();
      loadPlan();
      getOrganizationList();
      getTotalSubscriptionList();
    } else {
      loadSubscriptionFilter();
    }
  }, [pageNo]);



  const handleSearchCancel = () => {
    setIsSearchActive(false);
    getTotalSubscriptionList();
    cancelSubscriptionData();
  };

  // const handleSubscriptionStatusModal = (item: ISubscription) => {
  //   let confirmation = window.confirm(
  //     `Do you want to update "${item.organizationName + "'s"}" status?`
  //   );
  //   if (confirmation) subscriptionApi.toggleSubscriptionStatus(item, dispatch);
  //   else dispatch(setSubscriptionStatus({ ...item }));
  // };

  const handleSubscriptionStatus = (item: ISubscription) => {
    setSubscriptionStatusModal(true);
    setSubscriptionID(item.id);
    setSubscriptionEvent(item.isActive);
  };
  const handleManageSubscription = (subscription: ISubscription) => {
    dispatch(
      setEditSubscriptionModal({
        showModal: true,
        isModalFormSubmitting: false,
        subscription,
      })
    );
  };

  const SubscriptionPageTitleBuild = () => {
    return (

      <div className="title">
        <div className="page-title">Subscriptions</div>
        <div>
          <button
            className="create"
            onClick={() => {
              setAddSubscriptionModal(true);
            }}
          >
            Add New
          </button>
        </div>
      </div>

    );
    // return (
    //   <>
    //     <div className="organization-page__title_wrapper mb-0">
    //       <div className="page-title">Subscriptions</div>
    //       <div className="d-flex">
    //         <CButton
    //           size="sm"
    //           style={{ backgroundColor: "#1C6387", color: "white" }}
    //           onClick={() => setAddSubscriptionModal(true)}
    //         >
    //           <div className="d-flex ">
    //             <i className="fa fa-plus" aria-hidden="true"></i>
    //             <div className="ml-1">Add</div>
    //           </div>
    //         </CButton>
    //         {report && (
    //           // <CButton size="sm" color="info" className="ml-1">
    //           //   <div className="d-flex">
    //           //     <i className="fa fa-download" aria-hidden="true"></i>
    //           //     {/* <div className="ml-1"> Export to CSV </div> */}
    //           //     <CSVLink
    //           //       headers={subscriptionHistoryVariables.CSVHeaders}
    //           //       data={subscription.filteredSubscriptions}
    //           //       filename="Subscription.csv"
    //           //       ref={csvLinkRef}
    //           //       className="ml-1"
    //           //       style={{ textDecoration: "none", color: "#fff" }}
    //           //     >
    //           //       Export to
    //           //     </CSVLink>
    //           //     <MdArrowDropDown style={{ fontSize: "large", color: "#ffff"}} />
    //           //   </div>
    //           // </CButton>
    //           <CDropdown className="d-flex ">
    //             <CDropdownToggle
    //               caret
    //               style={{
    //                 backgroundColor: "#1C6387",
    //                 color: "white",
    //                 fontSize: "12px",
    //                 lineHeight: "18px",
    //               }}
    //               className="ml-1"
    //             >
    //               <i className="fa fa-download mr-1" aria-hidden="true"></i>
    //               Export to CSV
    //             </CDropdownToggle>
    //             <CDropdownMenu className="mt-1">
    //               <div className="m-1">
    //                 <MdDoNotDisturbOnTotalSilence />
    //                 <CSVLink
    //                   headers={subscriptionHistoryVariables.CSVHeaders}
    //                   data={totalSubscriptionFilter}
    //                   filename="AllSubscription.csv"
    //                   ref={csvLinkRef}
    //                   className="ml-1"
    //                   style={{
    //                     textDecoration: "none",
    //                     color: "#5d6c77",
    //                     fontSize: "12px",
    //                     lineHeight: "18px",
    //                   }}
    //                 >
    //                   Export All
    //                 </CSVLink>
    //               </div>
    //               <div className="m-1">
    //                 <BiSelectMultiple />
    //                 <CSVLink
    //                   headers={subscriptionHistoryVariables.CSVHeaders}
    //                   data={subscription.filteredSubscriptions}
    //                   filename="CurrentPageSubscription.csv"
    //                   ref={csvLinkRef}
    //                   className="ml-1"
    //                   style={{
    //                     textDecoration: "none",
    //                     color: "#5d6c77",
    //                     fontSize: "12px",
    //                     lineHeight: "18px",
    //                   }}
    //                 >
    //                   Export Current Page
    //                 </CSVLink>{" "}
    //               </div>
    //             </CDropdownMenu>
    //           </CDropdown>
    //         )}
    //       </div>
    //     </div>
    //     {/* <small className="py-2">
    //       <strong>
    //         Total Subscription :{" "}
    //         {totalData.activeSubscription + totalData.inactiveSubscription}
    //         &nbsp;|&nbsp;Active:{totalData.activeSubscription}
    //         &nbsp;|&nbsp;Inctive:{totalData.inactiveSubscription}
    //         &nbsp;|&nbsp;Expired:{totalData.expiredSubscription}
    //       </strong>
    //     </small> */}
    //   </>
    // );
  };
  const csvLinkRefCurrentPage = useRef<
    HTMLAnchorElement & CSVLink & { link: HTMLAnchorElement }
  >(null);
  const handleCSVClick = () => {
    csvLinkRefCurrentPage.current?.link.click();
  };

  const SubscriptionTableBuild = () => {
    return (
      <div className="tmf-table_wrapper">
        <div
          className={`tmf-datatable__wrapper tmf_hide_build_in_pagination ${isSearchActive && "tmf_paggination_state"
            }`}
          style={{
            width: "100%",
            maxWidth: "100%",
            overflowX: "auto"
          }}
        >
          <div style={{
            borderTopColor: "#fff",
            width: "1200px",
            minWidth: "100%",
            overflowX: "auto",
          }}>
            <div className="table-filter-section">
              <TMFSearch
                sapi={Constants.SUBSCRIPTION_SEARCH_URI}
                dispatch={dispatch}
                isSearchActive={(e) => setIsSearchActive(e)}
                searchIn="subscriptions"
                onCalcelSearch={(e) => handleSearchCancel()}
                Placeholder="Search by Name,Plan"
              />

              <div
                style={{ display: "flex", gap: "30px", alignItems: "center" }}
              >

                <div style={{ position: "relative" }}>
                  <div className="d-flex" >
                    <button
                      className="exportcsv"
                    >
                      Export To CSV
                    </button>
                    <button
                      className="open-button"
                      onClick={() => setIsOpen((prev) => !prev)}
                    >
                      {
                        isOpen ? <RiArrowDropUpLine style={{ width: '25px', height: "25px" }} /> : <RiArrowDropDownLine style={{ width: '25px', height: "25px" }} />
                      }
                    </button>

                  </div>

                  {
                    isOpen && (
                      <div className={`dropdownmenubox ${isOpen ? "show" : "hide"}`}   >
                        <button style={{ color: "var(--txt-primary)", background: "none", outline: "none", border: "none" }} onClick={() => setIsOpen((prev) => !prev)}>
                          <CSVLink
                            headers={subscriptionHistoryVariables.CSVHeaders}
                            data={totalSubscriptionFilter}
                            filename="AllSubscription.csv"
                            ref={csvLinkRefCurrentPage}

                            style={{ color: "var(--txt-primary)" }}
                          >
                            Export All
                          </CSVLink>
                        </button>
                        <div>
                          <button
                            onClick={() => { handleCSVClick(); setIsOpen((prev) => !prev); }}
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                              color: "var(--txt-primary)",
                              outline: "none",
                              cursor: "pointer"
                            }}
                          >
                            Export Current Page Data
                          </button>
                          <CSVLink
                            headers={subscriptionHistoryVariables.CSVHeaders}
                            data={subscription.filteredSubscriptions}
                            filename="CurrentPageSubscriptionsList.csv"
                            ref={csvLinkRefCurrentPage}
                          />
                        </div>

                      </div>
                    )
                  }
                </div>



                {
                  !isSearchActive &&
                  <span style={{ marginLeft: "auto" }}>
                    <DTablePagination
                      cPage={pageNo}
                      tPage={totalPage!}
                      onPageChange={(e) => {
                        setPageNo(e);
                        getTotalSubscriptionList();
                      }}

                    ></DTablePagination>
                  </span>

                }

              </div>
            </div>


            <CDataTable
              items={subscription.filteredSubscriptions}
              fields={subscriptionHistoryVariables.fields}
              // tableFilter={{ placeholder: "Search Subscriptions" }}
              // tableFilterValue=""
              // itemsPerPage={!isSearchActive ? 50 : undefined}
              loading={subscription.isFilteredSubscriptionsLoaded}
              noItemsViewSlot={<DataTableNoContent></DataTableNoContent>}
              hover
              responsive={true}
              scopedSlots={{
                organizationName: (item) => (
                  <td>
                    <div className="name">
                      <span
                        style={{
                          fontSize: "1.25rem",
                          fontWeight: "500",
                          color: "var(--txt-primary, #23005B)",
                          textDecoration: item.isDeleted ? "line-through" : ""
                        }}
                      >
                        {item.organizationName ? item.organizationName.charAt(0).toUpperCase() +
                          item.organizationName.slice(1).toLowerCase()
                          : "N/A"}
                      </span>

                      <span style={{
                        display: "flex",
                        gap: "0.8rem",
                        fontSize: "0.875rem",
                        fontWeight: "400"
                      }}>
                        <span >Created Date: <span style={{
                          fontWeight: "600"
                        }}>{item.creationDate.split("T")[0]}</span> </span>
                      </span>
                    </div>
                  </td>
                ),
                isActive: (item) => {
                  return (
                    <td>
                      {item.isDeleted ? (
                        <CSwitch
                          disabled
                          color="success"
                          defaultChecked={item.isActive}
                          labelOn="On"
                          labelOff="Off"
                          shape="pill"
                          variant="opposite"
                          size="sm"
                        />
                      ) : (
                        <CSwitch
                          color="success"
                          defaultChecked={item.isActive}
                          labelOn="On"
                          labelOff="Off"
                          shape="pill"
                          variant="opposite"
                          size="sm"
                          onClick={() => handleSubscriptionStatus(item)}
                          id={"UserSwitch" + item.isActive}
                        />
                      )}
                    </td>
                  );
                },
                subscriptionDate: (item) => {
                  return (
                    <td
                      className={
                        moment(item.endDate).format("YYYY-MM-DD") <=
                          moment().format("YYYY-MM-DD")
                          ? "text-danger"
                          : ""
                      }
                    >
                      <label>{moment(item.startDate).format("MM/DD/YYYY")}</label>
                      <br />
                      <label>{moment(item.endDate).format("MM/DD/YYYY")}</label>
                    </td>
                  );
                },
                purchasedLicenses: (item) => {
                  return (
                    <td>
                      {item.subscriptionTypeID === 4
                        ? "Unlimited"
                        : item.purchasedLicenses}
                    </td>
                  );
                },
                manageLicense: (item) => {
                  return (
                    <td>
                      <CButton

                        size="sm"
                        className="mt-3"
                        disabled={item.isDeleted}
                        onClick={() => {
                          handleManageSubscription(item);
                        }}
                        style={{
                          cursor: item.isDeleted ? "not-allowed" : "pointer",
                          background: "var(--primary)",
                          color: "white"
                        }}
                      >
                        Manage License
                      </CButton>
                    </td>
                  );
                },
                Action: (item) => {
                  return (
                    <td>
                      <SubscriptionActions
                        subscription={item}
                      ></SubscriptionActions>
                    </td>
                  );
                },
              }}
            />

            {!isSearchActive && (
              <DTablePagination
                tPage={totalPage!}
                cPage={pageNo}
                onPageChange={(e) => setPageNo(e)}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const editSubscritionModal = useSelector(
    (state: ApplicationState) => state.subscription?.editSubscriptionModal
  );
  const deleteSubscritionModal = useSelector(
    (state: ApplicationState) => state.subscription?.deleteSubscriptionModal
  );
  const undoDeleteSubscritionModal = useSelector(
    (state: ApplicationState) => state.subscription?.undoDeleteSubscriptionModal
  );
  const viewSubscritionModal = useSelector(
    (state: ApplicationState) => state.subscription?.viewSubscriptionModal
  );
  const viewSubscritionMailModal = useSelector(
    (state: ApplicationState) => state.subscription?.viewSubscriptionMailModal
  );

  const SubscriptionPageModalBuild = () => (
    <>
      {viewSubscritionModal?.showModal && (
        <ViewSubscriptionDetails
          isOpen={viewSubscritionModal.showModal}
          onClose={() =>
            dispatch(setViewSubscriptionModal({ showModal: false }))
          }
          refreshDetails={() => loadSubscriptionFilter()}
          subscriptionDetails={viewSubscritionModal.subscription}
        />
      )}
      {editSubscritionModal?.showModal && (
        <EditSubscriptionModal
          isOpen={editSubscritionModal.showModal}
          onClose={() =>
            dispatch(setEditSubscriptionModal({ showModal: false }))
          }
          refreshDetails={() => loadSubscriptionFilter()}
          id={editSubscritionModal.subscription?.id}
        />
      )}
      {deleteSubscritionModal?.showModal && (
        <DeleteSubscriptionModal
          isOpen={deleteSubscritionModal.showModal}
          onClose={() =>
            dispatch(setDeleteSubscriptionModal({ showModal: false }))
          }
          refreshDetails={() => loadSubscriptionFilter()}
          id={deleteSubscritionModal.subscription?.id}
        />
      )}
      {addSubscriptionModal && (
        <AddSubscriptionModal
          isOpen={addSubscriptionModal}
          onClose={() => setAddSubscriptionModal(false)}
          refreshDetails={() => loadSubscriptionFilter()}
        />
      )}
      {undoDeleteSubscritionModal?.showModal && (
        <UndoDeleteSubscriptionModal
          isOpen={undoDeleteSubscritionModal.showModal}
          onClose={() =>
            dispatch(setUndoDeleteSubscriptionModal({ showModal: false }))
          }
          refreshDetails={() => loadSubscriptionFilter()}
          id={undoDeleteSubscritionModal.subscription?.id}
        />
      )}
      {viewSubscritionMailModal?.showModal && (
        <SubscriptionMailModal
          isOpen={viewSubscritionMailModal.showModal}
          onClose={() =>
            dispatch(setViewSubscriptionMailModal({ showModal: false }))
          }
          refreshDetails={() => loadSubscriptionFilter()}
          subscriptionDetails={viewSubscritionMailModal.subscription}
          orgDataID={viewSubscritionMailModal.subscription?.orgID}
          endDate={viewSubscritionMailModal.subscription?.endDate}
        />
      )}
      {subscriptionStatusModal && (
        <SubscriptionStatusModal
          isOpen={subscriptionStatusModal}
          onClose={() => setSubscriptionStatusModal(false)}
          refreshDetails={() => loadSubscriptionFilter()}
          subscriptionID={subscriptionID}
          event={subscriptionEvent}
        />
      )}
    </>
  );
  return (
    <>
      <SubscriptionPageTitleBuild></SubscriptionPageTitleBuild>
      <SubscriptionFilter
        cPage={pageNo}
        onPageChange={(e) => {
          setPageNo(e);
          getAllSubscriptionDataCount();
        }}
        setIsSearchActive={setIsSearchActive}
      ></SubscriptionFilter>
      <SubscriptionTableBuild></SubscriptionTableBuild>

      <SubscriptionPageModalBuild></SubscriptionPageModalBuild>
    </>
  );
};

export default Subscription;
