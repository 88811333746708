import { CButton, CModal, CModalBody } from "@coreui/react";
import api from "services/api.service";
import { useState } from "react";
import Constants from "../../constants/Constants";

const SubscriptionStatusModal = ({
  isOpen,
  onClose,
  refreshDetails,
  subscriptionID,
  event,
}) => {
  const [isLoading, setLoaded] = useState(false);
  const handleUserToggle = async () => {
    setLoaded(true);
    await api
      .post(Constants.SUBSCRIPTION_TOGGLE_STATUS_URI + "/" + subscriptionID + "/" + event)
      .then(async (res) => {
        await onClose();
        // window.alert(`User Status Updated Successfully!`);
        refreshDetails();
        setLoaded(false);
      });
  };
  return (
    <div>
      <CModal
        show={isOpen}
        onClose={onClose}
        centered={true}
        closeOnBackdrop={false}
      >
        <CModalBody>
          <div style={{ textAlign: "center" }}>
            <div>
              {event === false ? (
                <div>
                  <div
                    className="col-md-10"
                    style={{ fontWeight: "bold", marginLeft: "2rem" }}
                  >
                    Are you sure,you want this Subscription to be Active?
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      fontWeight: "bold",
                    }}
                    className="col-md-11"
                  >
                    Are you sure,you want this Subscription to be InActive?
                  </div>
                </div>
              )}
            </div>

            <div
              style={{
                marginTop: "0.5rem",
                display: "flex",
                marginLeft: "8rem",
              }}
            >
              <div style={{ marginLeft: "1rem" }}>
                <CButton onClick={onClose} color="secondary" size="sm">
                  No,cancel Plz!
                </CButton>
              </div>
              <div style={{ marginLeft: "0.5rem" }}>
                {!isLoading && (
                  <CButton
                    onClick={() => handleUserToggle()}
                    color="danger"
                    size="sm"
                  >
                    {" "}
                    Yes
                  </CButton>
                )}
                {isLoading && (
                  <CButton
                    disabled
                    className="float-right"
                    color="danger"
                    size="sm"
                  >
                    {" "}
                    <i className="fa fa-spinner fa-spin"></i> Updating
                  </CButton>
                )}
              </div>
            </div>
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
};

export default SubscriptionStatusModal;
