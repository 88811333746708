import { PlanActionTypes } from "./action.types";

export function setAllPlans(allPlans: Iplan[]) {
  const action: PlanAction = {
    type: PlanActionTypes.SET_ALL_PLANS,
    allPlans,
  };
  return (dispatch: PlanDispatchType) => dispatch(action);
}

export function setPlanFilterDataLoader(isPlanFilterDataLoading: boolean) {
  const action: PlanAction = {
    type: PlanActionTypes.SET_PLAN_FILTER_DATA_LOADER,
    isPlanFilterDataLoading,
  };
  return (dispatch: PlanDispatchType) => dispatch(action);
}

export function addPlan(plan: Iplan) {
  const action: PlanAction = {
    type: PlanActionTypes.ADD_PLAN,
    plan
  };
  return (dispatch: PlanDispatchType) => dispatch(action);
}

export function updatePlan(plan: Iplan) {
  const action: PlanAction = {
    type: PlanActionTypes.UPDATE_PLAN,
    plan,
  };
  return (dispatch: PlanDispatchType) => dispatch(action);
}

export function viewPlan(plan: Iplan) {
  const action: PlanAction = {
    type: PlanActionTypes.VIEW_PLAN,
    plan,
  };
  return (dispatch: PlanDispatchType) => dispatch(action);
}

export function deletePlan(plan: Iplan) {
  const action: PlanAction = {
    type: PlanActionTypes.DELETE_PLAN,
    plan,
  };
  return (dispatch: PlanDispatchType) => dispatch(action);
}

export function setAddPlanModal(addPlanModal: IAddPlanModal) {
  const action: PlanAction = {
    type: PlanActionTypes.ADD_PLAN_MODAL,
    addPlanModal,
  };
  return (dispatch: PlanDispatchType) => dispatch(action);
}

export function resetAddPlanModal() {
  const action: PlanAction = {
    type: PlanActionTypes.RESET_ADD_PLAN_MODAL,
  };
  return (dispatch: PlanDispatchType) => dispatch(action);
}

export function setEditPlanModal(editPlanModal: IEditPlanModal) {
  const action: PlanAction = {
    type: PlanActionTypes.EDIT_PLAN_MODAL,
    editPlanModal,
  };
  return (dispatch: PlanDispatchType) => dispatch(action);
}


export function setViewPlanModal(viewPlanModal: IViewPlanModal) {
  const action: PlanAction = {
    type: PlanActionTypes.VIEW_PLAN_MODAL,
    viewPlanModal,
  };
  return (dispatch: PlanDispatchType) => dispatch(action);
}

export function resetEditPlanModal() {
  const action: PlanAction = {
    type: PlanActionTypes.RESET_EDIT_PLAN_MODAL,
  };
  return (dispatch: PlanDispatchType) => dispatch(action);
}


export function resetViewPlanModal() {
  const action: PlanAction = {
    type: PlanActionTypes.RESET_VIEW_PLAN_MODAL,
  };
  return (dispatch: PlanDispatchType) => dispatch(action);
}

export function setDeletePlanModal(deletePlanModal: IDeletePlanModal) {
  const action: PlanAction = {
    type: PlanActionTypes.DELETE_PLAN_MODAL,
    deletePlanModal,
  };
  return (dispatch: PlanDispatchType) => dispatch(action);
}
export function resetDeletePlanModal() {
  const action: PlanAction = {
    type: PlanActionTypes.RESET_DELETE_PLAN_MODAL,
  };
  return (dispatch: PlanDispatchType) => dispatch(action);
}
