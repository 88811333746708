const partnerTableVariables = {
  fields: [
    { key: "organizationName", label: "Org Name", _style: { width: "30%" } },
    { key: "partnerName", label: "Partner Type", _style: { width: "30%" } },
    { key: "isActive", _style: { width: "15%" } },
    { key: "creationDate", _style: { width: "15%" } },

    {
      key: "Action",
      label: "Action",
      _style: { width: "10%" },
      sorter: false,
      filter: false,
    },
  ],
};

export default partnerTableVariables;
