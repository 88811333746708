import React from 'react';
import { Tooltip } from "@mui/material";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { MdAddCircleOutline } from "react-icons/md";
import { PiNotePencilLight, PiTrashThin } from "react-icons/pi";
import { useDispatch } from "react-redux";
import { setAddSubCategoryModal, setDeleteCategoryModal, setEditCategoryModal } from "store/court/action";
import NodeIcon from "./NodeIcon";
import SearchLoader from 'components/loader/SearchLoader';

interface TreeNodeProps {
  node: ICategory;
  depth: number;
  selectedNodeId: string | null;
  setSelectedNodeId: React.Dispatch<React.SetStateAction<string | null>>;
  handleNodeClick: (node: ICategory) => void;
  openNodes: Set<string>;
  loadingNodes: Set<string | number>;
  handleToggle: (node: ICategory) => Promise<void>;
  children: { [key: string]: ICategory[] };
}

const TreeNode: React.FC<TreeNodeProps> = ({
  node,
  depth,
  selectedNodeId,
  setSelectedNodeId,
  handleNodeClick,
  handleToggle,
  children,
  openNodes,
  loadingNodes,
}) => {
  const [hover, setHover] = React.useState<boolean>(false);
  const isOpen = openNodes.has(node.id);
  const paddingLeft = `${depth * 1}rem`;
  const isSelected = selectedNodeId === node.id;

  const dispatch = useDispatch();

  return (
    <>
      <div
        className={`dnd-section ${isSelected ? 'selected-box' : ''}`}
        style={{ paddingLeft }}
        onClick={() => handleNodeClick(node)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className='title-display' >
          {node.hasChildren ? (
            <>
              {loadingNodes.has(node.id) ? (
                <>
                    <SearchLoader/>
                </>
              ) : (
                <span onClick={(e) => { e.stopPropagation(); handleToggle(node); }} className='togglebtn'>
                  {isOpen ? <FaChevronDown size={20} /> : <FaChevronRight size={20} />}
                </span>
              )
              }
            </>
          ) : (
            <span className="w-6 mr-2"></span>
          )}

          {node.icon && <NodeIcon icon={node.icon} />}
          <span className="name">{node.name}</span>
        </div>

        {hover && (
          <div className='action-buttons'>
            <Tooltip title="Add Sub Collection" arrow>
              <span>
                <MdAddCircleOutline
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(setAddSubCategoryModal({
                      showModal: true,
                      isModalFormSubmitting: false,
                      categorybyid: node,
                    }));
                  }}
                />
              </span>
            </Tooltip>
            <Tooltip title="Edit Collection" arrow>
              <span>
                <PiNotePencilLight
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(setEditCategoryModal({
                      showModal: true,
                      isModalFormSubmitting: false,
                      categorybyid: node,
                    }));
                  }}
                />
              </span>
            </Tooltip>
            <Tooltip title="Delete Collection" arrow>
              <span>
                <PiTrashThin
                  fill="red"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(setDeleteCategoryModal({
                      showModal: true,
                      isModalFormSubmitting: false,
                      categorybyid: node,
                    }));
                  }}
                />
              </span>
            </Tooltip>
          </div>
        )}
      </div>

      {isOpen && children[node.id] && children[node.id].length > 0 && (
        <div>
          {children[node.id].map((child) => (
            <TreeNode
              key={child.id}
              node={child}
              depth={depth + 1}
              selectedNodeId={selectedNodeId}
              setSelectedNodeId={setSelectedNodeId}
              handleNodeClick={handleNodeClick}
              openNodes={openNodes}
              loadingNodes={loadingNodes}
              handleToggle={handleToggle}
              children={children}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default TreeNode;