import Loader from "components/loader/Loader";

interface IButtonProps extends React.ComponentProps<"button"> {
  value: string;
  isButtonLoading?: boolean;
  isButtonDisabled?: boolean;
}

const AvishButton = ({
  value,
  isButtonLoading = false,
  isButtonDisabled = false,
  className = "",
  onClick = () => {},
}: IButtonProps) => {
  var buttonElementClasses = [
    "avish-button",
    className,
    isButtonLoading ? "loading" : "",
    isButtonDisabled || isButtonLoading ? "disabled" : "",
  ];

  return (
    <button
      className={buttonElementClasses.join(" ")}
      onClick={onClick}
      style={{ backgroundColor: "#1C6387", color: "white" }}
    >
      {value}
      {isButtonLoading && <Loader></Loader>}
    </button>
  );
};
export default AvishButton;
