import AvishButton from "components/button/button";
import AvishModal from "components/modal/modal";
import { useContext, useState } from "react";
import "styles/_select.scss";
import AvishInput from "./inputWrapper";
import SelectProvider, { selectContext } from "./select/select.provider";
import SelectModalBody from "./select/selectModalBody";
import SelectSearch from "./select/selectSearch";
import SingleSelectInput from "./select/singleSelectInput";

interface ISelectProps {
  options: ISelectData[];
  name: string;
  label?: string;
  required?: boolean;
}

const AvishSelect = ({
  options = [],
  name,
  label,
  required = false,
}: ISelectProps) => {
  const { resetSelectedOption } = useContext(selectContext);

  //STATE DECLERATIONS
  const [showSelectModal, setShowSelectModal] = useState(false);
  const [requestCloseModal, setRequestCloseModal] = useState(false);

  //HELPER FUNCTIONS
  function selectClickHandler() {
    setShowSelectModal(true);
  }

  function closeSelectModalHandler() {
    setShowSelectModal(false);
    setRequestCloseModal(false);
  }

  function resetOption() {
    resetSelectedOption();
  }

  //UI BUILDS
  const SelectInputBuild = () => {
    return (
      <SingleSelectInput
        onClick={selectClickHandler}
        name={name}
        required={required}
      ></SingleSelectInput>
    );
  };

  const SuffixBuild = () => {
    return (
      <>
        <div
          className="avish-select__selector__action avish-select__selector__clear"
          title="Clear Select"
          onClick={resetOption}
        >
          <svg
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      </>
    );
  };

  const SelectModalActionsBuild = () => {
    return (
      <>
        <AvishButton
          value="Ok"
          className="primary"
          onClick={() => {
            setRequestCloseModal(true);
          }}
          type="button"
        ></AvishButton>
      </>
    );
  };

  const SelectModalBuild = () => {
    return (
      <AvishModal
        title={`Select ${label}`}
        onClose={closeSelectModalHandler}
        closeModal={requestCloseModal}
        showModal={showSelectModal}
        isModalForm={false}
        className="avish-select__modal"
        portalClass="avish-select"
        footerActionContent={
          <SelectModalActionsBuild></SelectModalActionsBuild>
        }
        headerContent={<SelectSearch></SelectSearch>}
      >
        <SelectModalBody options={options}></SelectModalBody>
      </AvishModal>
    );
  };
  return (
    <SelectProvider optionsData={options}>
      <div className="avish-select">
        <div className="avish-select__selector">
          <AvishInput
            label={label}
            input={<SelectInputBuild></SelectInputBuild>}
            suffix={<SuffixBuild></SuffixBuild>}
          ></AvishInput>
          {showSelectModal && <SelectModalBuild></SelectModalBuild>}
        </div>
      </div>
    </SelectProvider>
  );
};

export default AvishSelect;
