import { CButton, CCol, CFormGroup, CModal, CModalBody, CModalHeader, CModalTitle, CRow } from '@coreui/react';
import axios from 'axios';
import AvishButton from 'components/button/button';
import AvishModal from 'components/modal/modal';
import Constants from 'constants/Constants';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdOutlineFileUpload } from 'react-icons/md';
import Swal from 'sweetalert2';
// interface Record {
//     id: number;
//     name: string;
//     value: number;
// }

interface IImportLeads{
    onClose: () => void;
    isOpen: boolean;
    refreshLeads: () => void;
}

function ImportLeads({onClose,isOpen,refreshLeads}:IImportLeads) {
    // const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
    const [isSpinning, setIsSpinning] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const { handleSubmit } = useForm();

    const [file, setFile] = useState<File | null>(null);


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setFile(file);
        }
        if (file?.type !== "text/csv") {
            setError(true);
        } else {
            setError(false);
        }
    };
    const downLoadMeeting = () => {
        axios({
            url: Constants.LEAD_TEMPLATE_DOWNLOAD,
            method: "post",
            responseType: "blob", // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "LeadTemplateSample.csv"); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    };
    const submit = async () => {
        if (!file) return;
        if (file.type === "text/csv") {
            const formData = new FormData();
            formData.append('file', file);
            // setIsFormSubmitting(true);
            setIsSpinning(true);
            try {
                const response = await axios.post(Constants.IMPORT_LEAD_CSV, formData);
                if (response.data < 1) {
                    Swal.fire({
                        title: "No data imported!",
                        icon: "error",
                        position: "top-end",
                        timer: 2000,
                        showConfirmButton: false,
                        customClass: {
                            title: "title-class h6",
                            icon: "icon-class small font-weight-200",
                        },
                    });
                } else {
                    Swal.fire({
                        title: `${response.data} data imported`,
                        icon: "success",
                        position: "top-end",
                        timer: 2000,
                        showConfirmButton: false,
                        customClass: {
                            title: "title-class h6",
                            icon: "icon-class small font-weight-200",
                        },
                    });
                    await onClose();
                    refreshLeads()
                }
            } catch (error) {
                console.error(error);
            } finally {
                // setIsFormSubmitting(false);
                setIsSpinning(true);
                await onClose();
                refreshLeads();
            }
        }
    }

    return (
        // <div>
        //     <AvishModal
        //         submitButtonType="submit"
        //         submitButtonValue="Import"
        //         title="Import Leads"
        //         icon={<i className='fa fa-upload'></i>}
        //         onClose={props.onClose}
        //         onSubmit={submit}
        //         showModal={true}
        //         isFormSubmitting={isFormSubmitting}
        //     >
        //         <div className="form-group">
        //             <div className="mb-2 small">
        //                 You can import leads using a comma separated values file (CSV).
        //                 For best results, make sure that the file has UTF-8 encoding.
        //             </div>
        //             <AvishButton
        //                 onClick={() => downLoadMeeting()}
        //                 value="Download"
        //             ></AvishButton>
        //             <label htmlFor="leadCsvFile" className='small text-info d-block w-100 mt-2' style={{ cursor: "pointer" }}>
        //                 <span>Please upload your csv file.*</span>

        //                 <div className="form-control w-100">
        //                     <i className="fa fa-upload fa-7x"></i><span className='pl-2 my-2 small font-weight-bold'>{file?.name}</span>
        //                 </div>
        //             </label>
        //             <input type="file" onChange={handleFileChange} id="leadCsvFile" className='form-control' style={{ paddingBottom: "32px", display: "none", position: "absolute" }} accept=".csv" />
        //             {error && <span className='text-danger small'>please check your file type!</span>}
        //         </div>
        //     </AvishModal>
        // </div >

        <CModal show={isOpen} onClose={onClose}>
            <CModalHeader closeButton>
                <CModalTitle>
                    <div> Import Leads</div>
                </CModalTitle>
            </CModalHeader>
            <CModalBody>
                <form
                    onSubmit={handleSubmit(submit)}
                    style={{ fontWeight: "bold", fontSize: "12px" }}
                >
                    <CRow>
                        <CCol>
                            {" "}
                            <CFormGroup>
                                <p className="control-label">
                                    You can import leads using a comma separated values file (CSV).
                                    For best results, make sure that the file has UTF-8 encoding.
                                </p>
                                <AvishButton
                                    onClick={() => downLoadMeeting()}
                                    value="Download"
                                ></AvishButton>
                                <label htmlFor="leadCsvFile" className='small text-info d-block w-100 mt-2' style={{ cursor: "pointer" }}>
                                    <span>Please upload your csv file.*</span>

                                    <div className="form-control w-100">
                                    <MdOutlineFileUpload style={{
                                       fontSize:"18px"
                                    }} /><span className='pl-2 my-2 small font-weight-bold'>{file?.name}</span>
                                    </div>
                                </label>
                                <input type="file" onChange={handleFileChange} id="leadCsvFile" className='form-control' style={{ paddingBottom: "32px", display: "none", position: "absolute" }} accept=".csv" />
                                {error && <span className='text-danger small'>please check your file type!</span>}
                            </CFormGroup>
                        </CCol>
                    </CRow>

                    {!isSpinning && (
                        <div
                            style={{
                                placeItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                                display: "flex",
                                marginTop: "10px",
                                float: "right",
                            }}
                        >
                            <CButton style={{ background: "var(--primary)", color: "white" }}
                                type="submit" size="sm">
                                Add
                            </CButton>

                            <div className="ml-1">
                                <CButton color="danger" onClick={onClose} size="sm">
                                    Close
                                </CButton>
                            </div>
                        </div>
                    )}
                    {isSpinning && (
                        <div
                            style={{
                                placeItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                                display: "flex",
                                marginTop: "10px",
                                float: "right",
                            }}
                        >
                            <CButton disabled style={{ background: "var(--primary)", color: "white" }}
                                size="sm">
                                <i className="fa fa-spinner fa-spin"></i> Adding
                            </CButton>
                            <div className="ml-1">
                                <CButton color="danger" onClick={onClose} size="sm">
                                    Close
                                </CButton>
                            </div>
                        </div>
                    )}

                </form>
            </CModalBody>
        </CModal>

    );
}

export default ImportLeads