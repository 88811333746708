import { Reducer } from "redux";
import { SidebarActionTypes } from "./action.types";

export const initialState: sidebarState = {
    sidebar: {
        showSidebar: "responsive",
    },
};

const reducer: Reducer<sidebarState> = (state = initialState, action) => {
    switch (action.type) {
        case SidebarActionTypes.SET_SIDEBAR: {
            return {
                sidebar: {...state.sidebar,
                    ...action.sidebar,}
            };
        }
        default: {
            return state;
        }
    }
};

export { reducer as sidebarReducer };