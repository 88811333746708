import Loader from "components/loader/Loader";
import { ReactNode } from "react";
import Input from "./input";
import Textarea from "./textarea";

interface IInputError {
  errorText: string;
  hasError: boolean;
}
interface IInputProps extends React.ComponentProps<"input"> {
  label?: string;
  isLoading?: boolean;
  error?: IInputError;
  inputType?: "input" | "textarea";
  onChange?: (
    e?:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  input?: ReactNode;
  suffix?: ReactNode;
}

const AvishInput = ({
  label,
  name,
  type,
  onChange,
  placeholder,
  isLoading = false,
  disabled,
  inputType = "input",
  error = {
    errorText: "This field is required",
    hasError: false,
  },
  required = false,
  autoFocus = false,
  maxLength,
  defaultValue,
  input,
  suffix,
  className,
}: IInputProps) => {
  const inputTypeSelectorBuild = () => {
    var inputComponent: JSX.Element;
    switch (inputType) {
      case "textarea": {
        inputComponent = (
          <Textarea
            name={name}
            placeholder={placeholder}
            disabled={isLoading || disabled}
            onChange={onChange}
            required={required}
            autoFocus={autoFocus}
            defaultValue={defaultValue}
          ></Textarea>
        );
        break;
      }
      default: {
        inputComponent = (
          <Input
            type={type}
            name={name}
            placeholder={placeholder}
            disabled={isLoading || disabled}
            onChange={onChange}
            required={required}
            autoFocus={autoFocus}
            maxLength={maxLength}
            defaultValue={defaultValue}
          ></Input>
        );
        break;
      }
    }

    return inputComponent;
  };

  return (
    <div
      className={[
        "form-element",
        error.hasError ? "error" : "",
        isLoading ? "loading" : "",
        className,
      ].join(" ")}
    >
      {label && <label htmlFor={name}>{label}</label>}
      <span className={`input-wrapper ${className}`}>
        {input || (
          <>
            {inputTypeSelectorBuild()}
            {isLoading && <Loader></Loader>}
          </>
        )}

        {suffix && <div className="input-suffix">{suffix}</div>}

        {/* {inputType === "input" && (
          <div className="clear-input" title="Clear input" onClick={() => {
            //console.log(inputTypeSelectorBuild())
            inputTypeSelectorBuild()["value"] = ""
          }}>
            <svg
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        )} */}
      </span>
      {error.hasError && (
        <div className="input-error-message">
          <svg
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>{error.errorText}</span>
        </div>
      )}
    </div>
  );
};

export default AvishInput;
