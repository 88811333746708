import {
    CButton,
    CModal,
    CModalBody
} from "@coreui/react";
import { useState } from "react";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api.service";
import Constants from "../../../constants/Constants";

interface IDeleteCourtCategoryModal {
    refreshDetails: () => void,
    onClose: any,
    isOpen: boolean,
    title: string,
    selectedNodeId: string,
    onSuccess: () => void

}

const DeleteCourtCategoryModal: React.FC<IDeleteCourtCategoryModal> = ({ refreshDetails, isOpen, onClose, title, selectedNodeId,onSuccess }) => {

    const [isSpinning, setSpinning] = useState(false);
    const onSubmit = async (id: string) => {
        setSpinning(true);
        try {
            const res = await api.delete(`${Constants.DELETE_COURT_CATEGORY}/${id}`);
            if (res.status === 200) {
                toast.success("😊 Category Deleted successfully.", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                await onClose();
                refreshDetails();
                onSuccess();
              
            }

        } catch (err:any) {
            
            //Handle errors
            if(err.status===409){
                toast.error("Category Name Already Exist", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }else{
                toast.error("Error Found", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        } finally {
            setSpinning(false);
        }
    }

    return (
        <CModal
            show={isOpen}
            onClose={onClose}
            centered={true}
            closeOnBackdrop={false}
        >
            <CModalBody>
                <div>{title}</div>
                {!isSpinning && (
                    <div
                        style={{
                            placeItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            display: "flex",
                            // marginBottom: "1rem",
                            float: "right",
                        }}
                    >
                        <CButton color="danger" onClick={() => onSubmit(selectedNodeId)} size="sm">
                            Ok
                        </CButton>
                        <CButton
                            onClick={onClose}
                            color="secondary"
                            className="ml-2"
                            size="sm"
                        >
                            Cancel
                        </CButton>
                    </div>
                )}
                {isSpinning && (
                    <div
                        style={{
                            placeItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            display: "flex",
                            // marginBottom: "1rem",
                            float: "right",
                        }}
                    >
                        <CButton color="primary" disabled size="sm">
                            Deleting
                        </CButton>
                        <CButton
                            onClick={onClose}
                            color="secondary"
                            className="ml-2"
                            size="sm"
                        >
                            Cancel
                        </CButton>
                    </div>
                )}
            </CModalBody>
        </CModal>
    );
};

export default DeleteCourtCategoryModal;
