import { CButton, CDataTable } from '@coreui/react';
import { Tooltip } from '@mui/material';
import axios from 'axios';
import { TagMyFavLogo } from 'components/common/logo/Logo';
import DataTableNoContent from 'components/datatable/datatableNoContent';
import { DTablePagination } from 'components/datatable/pagination';
import TreeNode from 'components/dnd/TreeNode';
import SearchLoader from 'components/loader/SearchLoader';
import AddBookmarkModal from 'components/modals/bookmark/AddBookmarkModal';
import DeleteBookmarkModal from 'components/modals/bookmark/DeleteBookmarkModal';
import EditBookmarkModal from 'components/modals/bookmark/EditBookmarkModal';
import TagBookmarkModal from 'components/modals/bookmark/TagBookmarkModal';
import AddCourtCategoryModal from 'components/modals/court/AddCourtCategoryModal';
import DeleteCourtCategoryModal from 'components/modals/court/DeleteCourtCategoryModal';
import EditCourtCategoryModal from 'components/modals/court/EditCourtCategoryModal';
import calculatePageNumber from 'helper/calculatePageNumber';
import React, { Dispatch, useEffect, useState } from 'react';
import { AiOutlineStop } from "react-icons/ai";
import { MdOutlineImportExport } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import bookmarkApi from 'services/apis/bookmark.api';
import courtApi from 'services/apis/court.api';
import { ApplicationState } from 'store';
import { openAddBookmarkModal, openDeleteBookmarkModal, openEditBookmarkModal, openTagBookmarkModal } from 'store/bookmark/action';
import { setAddSubCategoryModal, setDeleteCategoryModal, setEditCategoryModal } from 'store/court/action';
import CourtActions from './CourtActions';
import CourtTableVariables from './CourtTableVariables';



const Court = () => {

  const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const [totalnumber, setTotalNumber] = useState<number>(0)

  const [openNodes, setOpenNodes] = useState<Set<string>>(new Set());
  const [loadingNodes, setLoadingNodes] = useState<Set<number | string>>(new Set());
  const [children, setChildren] = useState<{ [key: string]: ICategory[] }>({});
  const [selectedCategoryName, setSeletedCategoryName] = useState<string>("")

  const dispatch: Dispatch<any> = useDispatch();

  const { isLoading, category } = useSelector(
    (state: ApplicationState) => state.category
  );
  const { bookmark, isLoading: isBookmarkLoading } = useSelector(
    (state: ApplicationState) => state.bookmark
  )





  useEffect(() => {
    if (bookmark.totalcount) {
      const number = calculatePageNumber(bookmark.totalcount)
      setTotalNumber(number)
    }
  }, [bookmark])


  //Category Modal
  const editCategoryModal = useSelector(
    (state: ApplicationState) => state.category.editCategoryModal
  );
  const addSubCategoryModal = useSelector(
    (state: ApplicationState) => state.category.addSubCategoryModal
  );
  const deleteCategoryModal = useSelector(
    (state: ApplicationState) => state.category.deleteCategoryModal
  );

  //Bookmark Modal
  const addBookmarkModal = useSelector(
    (state: ApplicationState) => state.bookmark.addBookmarkModal
  );

  const editBookmarkModal = useSelector(
    (state: ApplicationState) => state.bookmark.editBookmarkModal
  )
  const deleteBookmarkModal = useSelector(
    (state: ApplicationState) => state.bookmark.deleteBookmarkModal
  )
  const tagBookmarkModal = useSelector(
    (state: ApplicationState) => state.bookmark.tagBookmarkModal
  )

  const loadData = () => {
    courtApi.fetchAllCourtCategory(dispatch, false);
  }

  const loadBookmarkData = () => {
    bookmarkApi.fetchBookmarkById(dispatch, selectedNodeId!, pageNo)
  }

  useEffect(() => {
    courtApi.fetchAllCourtCategory(dispatch, false);
  }, [])

  useEffect(() => {
    if (selectedNodeId) {
      bookmarkApi.fetchBookmarkById(dispatch, selectedNodeId, pageNo)
    }
  }, [selectedNodeId, pageNo])

  const CourtsTableBuild = () => {
    return (
      <div className="court-table">

        <CDataTable
          items={bookmark.data}
          fields={CourtTableVariables.fields}
          responsive={true}
          loading={isBookmarkLoading}
          noItemsViewSlot={<DataTableNoContent></DataTableNoContent>}
          hover
          scopedSlots={{
            title: (item: any) => {
              return (
                (
                  <td>
                    <div className='court-link-title'>
                      <Tooltip title={item.title} arrow>
                        <span>
                          {item.icon === null ? (
                            <img
                              src={TagMyFavLogo}
                              alt={item.title}
                            />
                          ) : item.icon.includes("base") && !item.icon.includes("/img") ? (
                            <img

                              src={item.icon}
                              alt={item.title}
                            />
                          ) : item.icon.includes("/img") ? (
                            <img

                              src={TagMyFavLogo}
                              alt={item.title}
                            />
                          ) : item.icon.startsWith("<svg") ? (
                            <div className='image' dangerouslySetInnerHTML={{ __html: item.icon }} />
                          ) : (
                            <img

                              src={`data:image/jpeg;base64,${item.icon}`}
                              alt={item.title}
                            />
                          )}

                          <a href={item.path} target="_blank" rel="noopener noreferrer">
                            <p>
                              {item.title.length > 50
                                ? item.title.slice(0, 50).toUpperCase() + '...'
                                : item.title.toUpperCase()}
                            </p>
                          </a>
                        </span>
                      </Tooltip>
                    </div>
                  </td>
                )
              )
            },
            tag: (item: any) => {

              return (
                <td >
                  {
                    item.tags.length === 0 ? (
                      <>

                        <p style={{
                          textAlign: "start",
                          color: " var(--txt-primary)",
                          margin: "0"
                        }}
                        >
                          No Tags Found
                        </p>

                      </>
                    ) : (
                      <>
                        <div style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "5px"
                        }}>
                          {
                            item.tags.map((elem, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <span
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: "500",
                                      color: " var(--txt-primary)",
                                      borderRadius: "10px",
                                      backgroundColor: "rgb(239 236 236)",
                                      padding: "6px 10px"
                                    }}
                                  >
                                    {elem.name}
                                  </span>

                                </React.Fragment>
                              )
                            })
                          }
                        </div>
                      </>
                    )
                  }
                </td>

              )
            },
            Action: (item) => {
              return (
                <td>
                  <CourtActions bookmark={item}></CourtActions>
                </td>
              );
            },
          }}
        />
        {
          totalnumber > 0 && (
            <DTablePagination
              tPage={totalnumber}
              cPage={pageNo}
              onPageChange={(e) => setPageNo(e)}
              name="court"
            />
          )
        }


      </div>
    );
  };

  const handleNodeClick = (node: ICategory) => {
    setSelectedNodeId(node.id);
    setSeletedCategoryName(node.name)
  };

  const fetchSubcategories = async (nodeId: string) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/court/subcategories/${nodeId}`);
      return response.data;
    } catch (err: any) {
      toast.error("Error fetching subcategories");
      return [];
    }
  }

  const handleAddCategorySuccess = async () => {
    loadData();

    // Refresh subcategories for all previously opened nodes
    const refreshedChildren: { [key: string]: ICategory[] } = {};
    for (const nodeId of Object.keys(children)) {
      const subcategories = await fetchSubcategories(nodeId);
      refreshedChildren[nodeId] = subcategories;
    }
    setChildren(refreshedChildren);
  };

  const handleToggle = async (node: ICategory) => {
    if (node.hasChildren) {
      const isExpanded = openNodes.has(node.id);

      if (isExpanded) {
        setOpenNodes((prev) => {
          const newSet = new Set(prev);
          const removeDescendants = (nodeId: string) => {
            newSet.delete(nodeId);
            if (children[nodeId]) {
              children[nodeId].forEach(child => removeDescendants(child.id));
            }
          };
          removeDescendants(node.id);
          return newSet;
        });
      } else {
        setOpenNodes((prev) => new Set(prev).add(node.id));

        if (!children[node.id]) {
          try {
            setLoadingNodes((prev) => new Set(prev).add(node.id));
            const response = await axios.get(`${process.env.REACT_APP_BASEURL}/api/court/subcategories/${node.id}`);

            setChildren((prev) => ({ ...prev, [node.id]: response.data }));
          } catch (err: any) {
            toast.error("Error Found");
          } finally {
            setLoadingNodes((prev) => {
              const newLoadingNodes = new Set(prev);
              newLoadingNodes.delete(node.id);
              return newLoadingNodes;
            });
          }
        }
      }
    }
  };


  const CourtCategoryPage = () => {
    return (
      <>
        <div className='category-page'>

          <div className="cat-page">
            <div className='court-title'>
              <h1>Court Category</h1>
            </div>

            <div className='buttons'>
              <CButton
                className=" apply"
                size="sm"
                onClick={() => setAddCategoryModalOpen(true)}
              >
                <div style={{ display: "flex", gap: "2px", alignItems: "center", justifyContent: "center" }}>
                  <MdOutlineImportExport size="20px" style={{ color: "white" }} />
                  <div className="ml-1" style={{ color: "white" }}>
                    Category
                  </div>
                </div>
              </CButton>
            </div>
          </div>

          <div className='dnd-box'>
            {
              isLoading ? (
                <>
                  <SearchLoader />
                </>
              ) : (
                <>
                  {
                    category.length == 0 ? (
                      <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "8px",
                        padding: "20px 0px",
                        borderTop: "1px solid rgb(200 203 205)",
                        margin: "0px 10px"

                      }}>
                        <AiOutlineStop style={{
                          height: "40px",
                          width: "40px",
                          color: "red"
                        }} />
                        <p style={{
                          color: "#5D6C77"
                        }}>No Data Available</p>
                      </div>
                    ) : (
                      <>
                        <div className='dnd-wrapper'>
                          {/* DND Provider  */}
                          {category.map((node) => (
                            <TreeNode
                              key={node.id}
                              node={node}
                              depth={1}
                              selectedNodeId={selectedNodeId}
                              setSelectedNodeId={setSelectedNodeId}
                              handleNodeClick={handleNodeClick}
                              handleToggle={handleToggle}
                              openNodes={openNodes}
                              loadingNodes={loadingNodes}
                              children={children}
                            />
                          ))}
                        </div>
                      </>
                    )
                  }
                </>
              )
            }
          </div>
        </div>
      </>
    );
  };

  const CourtLinkPage = () => {
    return (
      <div className='link-page'>

        <div className="cat-page">
          <div className='court-title'>
            <h1>Court Links</h1>
            {
              selectedCategoryName && (
                <p>{`(#${selectedCategoryName})`}</p>
              )
            }

          </div>
          <div className='link-setting'>

            {
              selectedNodeId !== null && (
                <CButton
                  className=" apply"
                  size="sm"
                >
                  <div style={{ display: "flex", gap: "2px", alignItems: "center", justifyContent: "center" }} onClick={() => {
                    dispatch(
                      openAddBookmarkModal({
                        showModal: true,
                        isModalFormSubmitting: false,
                      })
                    );
                  }}>
                    <MdOutlineImportExport size="20px" style={{ color: "white" }} />
                    <div
                      className="ml-1"
                      style={{ color: "white" }}

                    >
                      Add Link
                    </div>
                  </div>
                </CButton>
              )
            }

            {
              totalnumber > 0 && (
                <DTablePagination
                  tPage={totalnumber}
                  cPage={pageNo}
                  onPageChange={(e) => setPageNo(e)}
                  name="court"
                />
              )
            }
          </div>
        </div>

        <CourtsTableBuild />



      </div>
    );
  }



  const CourtActionModals = () => {

    return (
      <>
        {addCategoryModalOpen && (
          <AddCourtCategoryModal
            isOpen={addCategoryModalOpen}
            onClose={() => setAddCategoryModalOpen(false)}
            refreshDetails={() => loadData()}
            data={category}
            title='Add New Category'
            onSuccess={handleAddCategorySuccess}
          />
        )}

        {addSubCategoryModal?.showModal && (
          <AddCourtCategoryModal
            isOpen={addSubCategoryModal.showModal}
            onClose={() => dispatch(setAddSubCategoryModal({ showModal: false }))}
            refreshDetails={() => loadData()}
            data={category}
            title='Add New Sub Category'
            selectedNode={addSubCategoryModal.categorybyid}
            onSuccess={handleAddCategorySuccess}

          />
        )}

        {
          editCategoryModal?.showModal && (
            <EditCourtCategoryModal
              isOpen={editCategoryModal.showModal}
              onClose={() => dispatch(setEditCategoryModal({ showModal: false }))}
              refreshDetails={() => loadData()}
              title='Edit Category'
              selectedNode={editCategoryModal.categorybyid}
              onSuccess={handleAddCategorySuccess}

            />
          )
        }

        {
          deleteCategoryModal?.showModal && (
            <DeleteCourtCategoryModal
              isOpen={deleteCategoryModal.showModal}
              onClose={() => dispatch(setDeleteCategoryModal({ showModal: false }))}
              refreshDetails={() => loadData()}
              title=' Do you want to delete this Category?'
              selectedNodeId={deleteCategoryModal!.categorybyid!.id}
              onSuccess={handleAddCategorySuccess}

            />
          )
        }

        {
          addBookmarkModal?.showModal && (
            <AddBookmarkModal
              isOpen={addBookmarkModal.showModal}
              onClose={() => dispatch(openAddBookmarkModal({ showModal: false }))}
              refreshDetails={() => loadBookmarkData()}
              title='Create New Court Link'
              selectedBookmark={addBookmarkModal.bookmarkbyid}
              selectedCategoryId={selectedNodeId}
            />
          )
        }

        {
          editBookmarkModal?.showModal && (
            <EditBookmarkModal
              isOpen={editBookmarkModal.showModal}
              onClose={() => dispatch(openEditBookmarkModal({ showModal: false }))}
              refreshDetails={() => loadBookmarkData()}
              title='Edit Court Link'
              selectedBookmark={editBookmarkModal.bookmarkbyid}
              selectedCategoryId={selectedNodeId}
            />
          )
        }

        {
          deleteBookmarkModal?.showModal && (
            <DeleteBookmarkModal
              isOpen={deleteBookmarkModal.showModal}
              onClose={() => dispatch(openDeleteBookmarkModal({ showModal: false }))}
              refreshDetails={() => loadBookmarkData()}
              title=' Do you want to delete this Court Link?'
              selectedBookmark={deleteBookmarkModal.bookmarkbyid}
            />
          )
        }
        {
          tagBookmarkModal?.showModal && (
            <TagBookmarkModal
              isOpen={tagBookmarkModal.showModal}
              onClose={() => dispatch(openTagBookmarkModal({ showModal: false }))}
              refreshDetails={() => loadBookmarkData()}
              title=' Add Tags'
              selectedBookmark={tagBookmarkModal.bookmarkbyid}
              selectedCategoryId={selectedNodeId}
            />
          )
        }

      </>
    )
  }

  return (
    <div className='court'>
      <CourtCategoryPage />
      <CourtLinkPage />
      <CourtActionModals></CourtActionModals>
    </div>
  )
}

export default Court
