const colors = {
    getStringColor: function (str: string) {
        var arr1:string[] = [];
        for (var n = 0, l = str.length; n < l; n++) {
            var hex = Number(str.charCodeAt(n)).toString(16);
            arr1.push(hex);
        }
        return this.HEXtoHSL(arr1.join("").slice(0, 6));
    },
    HEXtoHSL: function (hex: string) {
        hex = hex.replace(/#/g, "");
        if (hex.length === 3) {
            hex = hex
                .split("")
                .map(function (hex) {
                    return hex + hex;
                })
                .join("");
        }
        var result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})[\da-z]{0,0}$/i.exec(
            hex
        );
        if (!result) {
            return null;
        }
        var r = parseInt(result[1], 16);
        var g = parseInt(result[2], 16);
        var b = parseInt(result[3], 16);
        r /= 255;
        g /= 255;
        b /= 255;
        var max = Math.max(r, g, b),
            min = Math.min(r, g, b);
        var h: number = 0;
        if (max === min) {
            h = 0;
        } else {
            var d = max - min;
           
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }
        h = Math.round(360 * h);

        return `hsl(${h}, 71%, 40%)`;
    },

}

export default colors;