const OrganizationTableVariables = {
  fields: [
    { key: "name", label: "Name", _style: { width: "25%" , textAlign:"start",color: "#6B6B6B", fontWeight: "600",fontSize:"1.25rem",padding:"10px 0",paddingLeft:"20px"} },
    { key: 'detail', label: 'Details',_style: { width: "20%" ,textAlign:"start",color: "#6B6B6B", fontWeight: "600",fontSize:"1.25rem",padding:"10px 0",paddingLeft:"20px"} },
    { key: 'address', label: 'Address',_style: { width: "15%" ,textAlign:"start",color: "#6B6B6B", fontWeight: "600",fontSize:"1.25rem",padding:"10px 0",paddingLeft:"20px"} },
    { key: 'record', label: 'Record',_style: { width: "30%" ,textAlign:"start",color: "#6B6B6B", fontWeight: "600",fontSize:"1.25rem",padding:"10px 0",paddingLeft:"4px"} },
    {
      key: "Action",
      label: "Action",
      _style: { width: "10%",textAlign:"center",color: "#6B6B6B", fontWeight: "600",fontSize:"1rem",padding:"10px 0" },
      sorter: false,
      filter: false,
    },
  ],

  headers: [
    { key: "name", label: "Name" },
    { key: "email", label: "E-mail" },
    { key: "phone", label: "Phone" },
    { key: "address", label: "Address" },
    { key: "city", label: "City" },
    { key: "displayName", label: "Timezone" }, 
    { key: "country_Name", label: "Country" },
    { key: "isActive", label: "Is Active" },
    { key: "CourtLink", label: "CourtLink" },
    { key: "SafeLink", label: "SafeLink" },
    { key: "totalUserCount", label: "Total Users" },
    { key: "creationDate", label: "Creation Date" },
    { key: "copilot",  label: "Copilot" },
    // { key: "website", label: "website" },
  ],
};

export default OrganizationTableVariables;
