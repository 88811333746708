import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import Loader from "components/loader/Loader";
import moment from "moment";
import { useState } from "react";

const ViewLeads = ({ isOpen, onClose, leads }) => {
  const [isLoading, setLoaded] = useState(false);
  return (
    <div>
      {!isLoading && isOpen && leads && (
        <CModal
          show={isOpen}
          onClose={onClose}
          closeOnBackdrop={false}
          style={{ borderTop: "5px solid #ccc" }}
        >
          <CModalHeader closeButton>
            <CModalTitle>
              <div>Leads Details</div>
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">First Name:</span>
              </div>
              <div className="col-md-6">
                <span>{leads.firstName ? leads.firstName : ""}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Last Name:</span>
              </div>
              <div className="col-md-6">
                <span>{leads.lastName ? leads.lastName : ""}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Email:</span>
              </div>
              <div className="col-md-6">
                <span>{leads.email ? leads.email : ""}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Phone:</span>
              </div>
              <div className="col-md-6">
                <span>{leads.phone ? leads.phone : ""}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Country:</span>
              </div>
              <div className="col-md-6">
                <span>{leads.country ? leads.country : ""}</span>
              </div>
            </div>
            {/* <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Company:</span>
              </div>
              <div className="col-md-6">
                <span>{leads.company ? leads.company : ""}</span>
              </div>
            </div> */}
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Details:</span>
              </div>
              <div className="col-md-6">
                <span>{leads.details ? leads.details : ""}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Title:</span>
              </div>
              <div className="col-md-6">
                <span>{leads.title ? leads.title : ""}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">LeadSource:</span>
              </div>
              <div className="col-md-6">
                <span>{leads.leadSource ? leads.leadSource : ""}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Action Code:</span>
              </div>
              <div className="col-md-6">
                <span>{leads.actionCode ? leads.actionCode : ""}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Description:</span>
              </div>
              <div className="col-md-6">
                <span>{leads.description ? leads.description : ""}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Offer Title:</span>
              </div>
              <div className="col-md-6">
                <span>{leads.offerTitle ? leads.offerTitle : ""}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Attempt:</span>
              </div>
              <div className="col-md-6">
                <span>{leads.attempt ? leads.attempt : ""}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Visited Date:</span>
              </div>
              <div className="col-md-6">
                <span>{moment(leads.visitDate).format("MMM DD YYYY")}</span>
              </div>
            </div>
            <hr className="my-0" />
            <div className="row p-2">
              <div className="col-md-6">
                <span className="font-weight-bold">Mail Status:</span>
              </div>
              <div className="col-md-6">
                <span>{leads.mailStatus ? leads.mailStatus : ""}</span>
              </div>
            </div>
          </CModalBody>
          <CModalFooter>
            <CButton color="danger" size="sm" onClick={onClose}>
              Close
            </CButton>
          </CModalFooter>
        </CModal>
      )}

      <div />
      {isLoading && (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default ViewLeads;
