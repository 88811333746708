import React from "react";

const NodeIcon: React.FC<{
  icon:string;
}> = ({  icon }) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: icon,
        }}
        style={{ width: "20px"}} 
      />
    );
};

export default NodeIcon;
