import AllUsers from "views/users/Users.page";
import Dashboard from "views/dashboard/Dashboard";
import ExpiryIn from "views/subscription/ExpiryIn";
import Icons from "views/icons/Icons.page";
import Invoice from "views/organization/invoice/Invoice";
import IRoute from "../interfaces/route";
import Login from "components/login/Login.page";
import LoginSuccess from "components/pages/login/LoginSuccess";
import Offer from "views/Offers/Offer.page";
import Organization from "views/organization/Organization.page";
import OrganizationDetails from "views/organization/OrganizationDetails";
import Page404 from "components/pages/page404/Page404";
import Page500 from "components/pages/page500/Page500";
import Partner from "views/partner/Partner.page";
import Plans from "views/plans/Plans.page";
import PaymentHistory from "components/payment/PaymentHistory";
import Register from "components/pages/register/Register";
import SubscriptionDetails from "views/subscription/SubscriptionDetails";
import SubscriptionHistory from "views/subscription/SubscriptionHistory.page";
import Template from "components/template/Template";
import LicenseDetails from "views/organization/license/LicenseDetails";
import Page401 from "../components/pages/page401/Page401";
import Leads from "views/leads/Leads.page";
import Court from "views/court/Court.Page";
import Tags from "views/tags/Tags.Page";
// import LinkActions from "views/link/LinkActions";
// import Links from "views/Links/Links.page";

const routes: IRoute[] = [
  {
    path: "/",
    name: "Home Page",
    component: Dashboard,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/login",
    name: "Login Page",
    component: Login,
    exact: true,
    isProtected: false,
    withNavigation: false,
  },
  {
    path: "/login-success",
    name: "Login Success Page",
    component: LoginSuccess,
    exact: true,
    isProtected: true,
    withNavigation: false,
  },
  {
    path: "/register",
    name: "Register Page",
    component: Register,
    exact: true,
    isProtected: false,
    withNavigation: false,
  },
  {
    path: "/subscription-list",
    name: "Subscription History",
    component: SubscriptionHistory,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/leads",
    name: "Leads",
    component: Leads,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/organization",
    name: "Organization",
    component: Organization,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/tags",
    name: "Tags",
    component: Tags,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  // {
  //   path: "/links",
  //   name: "Links",
  //   component: LinkActions,
  //   exact: true,
  //   isProtected: true,
  //   withNavigation: true,
  // },
  {
    path: "/icons",
    name: "Icons",
    component: Icons,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/subscription/:organizationId",
    name: "Subscription Details",
    component: SubscriptionDetails,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/payment/:msThirdPartySubscriptionID",
    name: "Payment History",
    component: PaymentHistory,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/details/:organizationId",
    name: "Organization Details",
    component: OrganizationDetails,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/invoice/:subscriptionID",
    name: "Invoice",
    component: Invoice,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/court-links",
    name: "Courts",
    component:Court,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/users",
    name: "Users",
    component: AllUsers,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/expire-in/:condition",
    name: "Expiry In",
    component: ExpiryIn,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/plans",
    name: "Plans",
    component: Plans,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/offers",
    name: "Offers",
    component: Offer,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/partners",
    name: "Partners",
    component: Partner,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },

  {
    path: "/template",
    name: "Template",
    component: Template,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
  {
    path: "/404",
    name: "404",
    component: Page404,
    exact: true,
    isProtected: false,
    withNavigation: false,
  },
  {
    path: "/401",
    name: "401",
    component: Page401,
    exact: true,
    isProtected: false,
    withNavigation: false,
  },
  {
    path: "/500",
    name: "Server Error Page",
    component: Page500,
    exact: true,
    isProtected: false,
    withNavigation: false,
  },
  {
    path: "/license/:organizationId",
    name: "Licence Details",
    component: LicenseDetails,
    exact: true,
    isProtected: true,
    withNavigation: true,
  },
];

export default routes;
