import { CCollapse } from "@coreui/react";
import Loader from "components/loader/Loader";
import { ReactNode, useState } from "react";

interface IDataFilterProps {
  isLoading?: boolean;
  children: ReactNode;
}

const DataFilter = ({ isLoading = false, children }: IDataFilterProps) => {
  const [collapse, setCollapse] = useState(true);
  const toggle = (e) => {
    setCollapse(!collapse);
    e.preventDefault();
  };
  return (
    <div className="tmf-filter">
      <div color="success" className={"tmf-filter__toggle-button"}>
        {isLoading ? (
          <Loader size="2rem"></Loader>
        ) : (
          <i
            // className="fa fa-filter tmf-filter__toggle-button__filter"
            aria-hidden="true"
          ></i>
        )}
      </div>
      {!isLoading && <CCollapse show={collapse}>{children}</CCollapse>}
    </div>
  );
};
export default DataFilter;
