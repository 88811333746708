import { CDataTable, CSwitch } from "@coreui/react";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
// import AddPartner from "./AddPartner";
import axios from "axios";
import DataTableNoContent from "components/datatable/datatableNoContent";
import { DTablePagination } from "components/datatable/pagination";
import TMFSearch from "components/input/TMFSearch";
import Constants from "constants/Constants";
import commonConstants from "constants/commonConstants";
import { TotalCountContext } from "context/TotalCount";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import partnerApi from "services/apis/partner.api";
import { ApplicationState } from "store";
import { setLeadSearchValue } from "store/leads/action";
import { setAddPartnerModal } from "store/partner/action";
import AddPartnerModal from "../../components/modals/partnerModal/addPartnerModal";
import EditPartnerModal from "../../components/modals/partnerModal/editPartnerModal";
import ViewPartnerModal from "../../components/modals/partnerModal/viewPartnerModal";
import PartnerStatusModal from "./PartnerStatusModal";
import PartnerAction from "./partnerAction";
import partnerTableVariables from "./partnerTableVariables";
import partnerVariables from "./partnerVariables";
const Partner = () => {
  const totalData = useContext(TotalCountContext);
  const dispatch: Dispatch<any> = useDispatch();
  const partner = useSelector((state: ApplicationState) => state.partner);
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [isLoading, setLoaded] = useState(false);
  const [pageSize, setPageSize] = useState(commonConstants.PAGE_SIZE);
  const [pageNo, setPageNo] = useState(1);
  const [partnerStatusModal, setPartnerStatusModal] = useState(false);
  const [privateEvent, setPrivateEvent] = useState(null);
  const [partnerID, setPartnerID] = useState(null);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [partnerTypeName, setPartnerName] = useState(null);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const partnerFilter = useSelector(
    (state: ApplicationState) => state.partner.partnerFilter
  );

  useEffect(() => {
    getAllPartnersCount();
    loadPartner();
  }, []);

  useEffect(() => {
    loadPartnersFilter();
  }, [pageNo]);

  const loadPartner = async () => {
    setLoaded(true);
    partnerApi.fetchAllPartner(dispatch).then((res) => {
      setLoaded(false);
    });
  };

  const loadPartnersFilter = async () => {
    dispatch(setLeadSearchValue(""));
    setLoaded(true);
    partnerApi
      .filterPartners(partnerFilter, dispatch, pageSize, pageNo)
      .then((res) => {
        setLoaded(false);
      });
  };
  
  
  const handleClicks = () => {
    csvLinkRef.current?.link.click();
  };

  const handlePartnerStatus = (event, id, name) => {
    setPartnerStatusModal(true);
    setPartnerID(id);
    setPrivateEvent(event);
  };
  const getAllPartnersCount = () => {
    axios.post(Constants.PARTNER_TOTAL_COUNT_URI, partnerFilter).then((res) => {
      setTotalPage(Math.ceil(Math.abs(res.data / commonConstants.PAGE_SIZE)));
    });
  };

  const PartnerPageTitleBuild = () => {
    return (
      <>
        <div className="title ">
          <div className="page-title">Partners</div>
          <div>
            <button
              className="create"
              onClick={() => dispatch(setAddPartnerModal({ showModal: true }))}
            >
              Add New
            </button>
          </div>
        </div>
        {/* <small className="py-2">
          <strong>
            Total Partners :{" "}
            {totalData.activePartners + totalData.inactivePartners}
            &nbsp;|&nbsp;Active : {totalData.activePartners}
            &nbsp;|&nbsp;Inctive:{totalData.inactivePartners}
          </strong>
        </small> */}
      </>
    );
  };

  const PartnerPageModals = () => {
    return (
      <>
        {partnerStatusModal && (
          <PartnerStatusModal
            isOpen={partnerStatusModal}
            onClose={() => setPartnerStatusModal(false)}
            refreshDetails={() => loadPartner()}
            id={partnerID}
            event={privateEvent}
            name={partnerTypeName}
          />
        )}
      </>
    );
  };

  const PartnerTableBuild = () => {
    return (
      <div className="tmf-table_wrapper">
        <div className={`tmf-datatable__wrapper `}
          style={{
            width: "100%",
            maxWidth: "100%",
            overflowX: "auto"
          }}>

          <div
            style={{
              borderTopColor: "#fff",
              width: "1200px",
              minWidth: "100%",
              overflowX: "auto",
            }} >
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                  padding: "12px",
                  backgroundColor: "#fff",
                }}>
                <TMFSearch
                  sapi={Constants.LEADS_SEARCH_URI}
                  dispatch={dispatch}
                  isSearchActive={(e) => setIsSearchActive(e)}
                  searchIn="partners"
                  onCalcelSearch={(e) => loadPartner()}
                  Placeholder="Search by Name"
                />

                <div className="table-filter-section">

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ position: "relative" }}>
                      <div className="d-flex" >
                        <button
                          className="exportcsvwithborderradius"
                          disabled={partner.allPartners.length === 0}
                          style={{
                            cursor:partner.allPartners.length === 0 ? "not-allowed" : "pointer"
                          }}
                          onClick={handleClicks}
                        >
                          Export to CSV
                        </button>
                        <CSVLink
                          headers={partnerVariables.CSVHeaders}
                          data={partner.allPartners}
                          filename="Partners.csv"
                          ref={csvLinkRef}
                          className="ml-1"
                          style={{ textDecoration: "none", color: "black" }}
                        >
                        </CSVLink>
                      </div>
                    </div>

                    {
                      !isSearchActive &&
                      <span style={{ marginLeft: "auto" }}>
                        <DTablePagination
                          tPage={totalPage!}
                          cPage={pageNo}
                          onPageChange={(e) => setPageNo(e)}
                        />
                      </span>
                    }

                  </div>
                </div>
              </div>
              <CDataTable
                items={partner.allPartners}
                fields={partnerTableVariables.fields}
                // tableFilter={{ placeholder: "Search Partners" }}
                loading={partner.isAllPartnersLoading}
                noItemsViewSlot={<DataTableNoContent></DataTableNoContent>}
                hover
                sorter
                // pagination
                scopedSlots={{
                  organizationName: (item) => {
                    return (
                      <td>
                        <div className="name">
                          {
                            item.isDeleted ? (
                              <span
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "500",
                                  color: "#524467",
                                  textDecoration: "line-through"
                                }}
                              >
                                {item.organizationName ? item.organizationName.charAt(0).toUpperCase() +
                                  item.organizationName.slice(1).toLowerCase()
                                  : "N/A"}

                              </span>
                            ) : (
                              <span
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "500",
                                  color: "var(--txt-primary, #23005B)",
                                }}
                              >
                                {item.organizationName ? item.organizationName.charAt(0).toUpperCase() +
                                  item.organizationName.slice(1).toLowerCase()
                                  : "N/A"}

                              </span>
                            )
                          }

                        </div>
                      </td>
                    )
                  },
                  isActive: (item) => {
                    return (
                      <td>
                        {item.isDeleted ? (
                          <CSwitch
                            disabled
                            color="success"
                            defaultChecked={item.isActive}
                            labelOn="On"
                            labelOff="Off"
                            shape="pill"
                            variant="opposite"
                            size="sm"
                          />
                        ) : (
                          <CSwitch
                            color="success"
                            defaultChecked={item.isActive}
                            labelOn="On"
                            labelOff="Off"
                            shape="pill"
                            variant="opposite"
                            size="sm"
                            onChange={(e) =>
                              handlePartnerStatus(
                                item.isActive,
                                item.partnerID,
                                item.name
                              )
                            }
                            id={"planSwitch" + item.isActive}
                          />
                        )}
                      </td>
                    );
                  },
                  creationDate: (item) => {
                    return <td>{moment(item.creationDate).format("MMM DD YYYY")}</td>;
                  },
                  Action: (item) => {
                    return (
                      <td>
                        <PartnerAction partner={item}></PartnerAction>
                      </td>
                    );
                  },
                }}
              />
              {!isSearchActive && (
                <DTablePagination
                  tPage={totalPage!}
                  cPage={pageNo}
                  onPageChange={(e) => setPageNo(e)}
                />
              )}
            </div>

          </div>

        </div>

      </div>

    );
  };
  // const PartnerPageModalsBuild = () => {
  //   return (
  //     <>
  //       {AddPartnerModalOpen && (
  //         <AddPartner
  //           isOpen={AddPartnerModalOpen}
  //           onClose={() => setAddPartnerModalOpen(false)}
  //           refreshDetails={() => loadPartner()}
  //         />
  //       )}
  //     </>
  //   );
  // };

  return (
    <>
      <PartnerPageTitleBuild></PartnerPageTitleBuild>
      <PartnerTableBuild></PartnerTableBuild>

      <PartnerPageModals></PartnerPageModals>
      <AddPartnerModal></AddPartnerModal>
      <ViewPartnerModal></ViewPartnerModal>
      <EditPartnerModal></EditPartnerModal>
    </>
  );
};

export default Partner;
