const CourtTableVariables = {
  fields: [
    {
      key: "title",
      label: "Title",
      _style: { color: "#6B6B6B", fontWeght: "400", fontSize: "0.875rem",width: "40%",  },
    },
    {
      key: "tag",
      label: "Tag",
      _style: { color: "#6B6B6B", fontWeght: "400", fontSize: "0.875rem",width: "40%",  },
    },
    {
      key: "Action",
      label: "Action",
      _style: { color: "#6B6B6B", fontWeght: "400", fontSize: "0.875rem",textAlign:"center",width: "5%",  },
    },
  ],
};

export default CourtTableVariables