import { Dispatch } from "redux";
import apiUrls from "constants/Constants";
import api from "services/api.service";
import { setUserLoader, setUsers, setUsersLoader, setUserStatus } from "store/user/action";

const userApi = {
  // filterUsers: (payload: IUserFilter, dispatch: Dispatch<any>) => {
  //   dispatch(setUsersLoader(true));
  //   return api
  //     .post(apiUrls.DATE_WISE_USER_FILTER_URI, payload)
  //     .then((res) => {
  //       dispatch(setUsers(res.data));
  //       return res.data;
  //     })
  //     .catch((error) => {
  //       throw error;
  //     })
  //     .finally(() => dispatch(setUsersLoader(false)));
  // },
  filterUsers: (payload: IUserFilter, dispatch: Dispatch<any>, pageSize, pageNo) => {
    dispatch(setUsersLoader(true));
    return api
      .post(`${apiUrls.DATE_WISE_USER_FILTER_URI}/${pageSize}/${pageNo}`, payload)
      .then((res) => {
        dispatch(setUsers(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => dispatch(setUsersLoader(false)));
  },


  toggleUserStatus: function (
    user: IUser,
    dispatch: Dispatch<any>
  ) {
    dispatch(
      setUserLoader({
        id: user.userID,
        isStatusLoading: true,
      })
    );
    return api
      .post(
        apiUrls.USER_TOGGLE_STATUS_URI + `/${user.userID}/${!user.isActive}`
      )
      .then((res) => {
        dispatch(
          setUserStatus({
            ...user,
            isActive: !user.isActive,
          })
        );
        //console.log("user status updated successfull");
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() =>
        dispatch(
          setUserLoader({
            id: null,
            isStatusLoading: false,
          })
        )
      );
  },
};

export default userApi;
