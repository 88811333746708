import { CModalBody } from "@coreui/react";
import AvishModal from "components/modal/modal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ApplicationState } from "store";
import { resetViewOfferModal } from "store/offer/action";

const ViewOfferModal = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const viewOfferModal = useSelector(
    (state: ApplicationState) => state.offer?.viewOfferModal
  );

  function closeHandler() {
    dispatch(resetViewOfferModal());
  }

  const ModalIconBuild = () => {
    return (
      <svg
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <defs>
          <linearGradient x1="16%" x2="84%" y1="0%" y2="100%" id="linear">
            <stop offset="0%" stopColor="#00B5FF" stopOpacity="1"></stop>
            <stop offset="55%" stopColor="#2F578A" stopOpacity="1"></stop>
          </linearGradient>
        </defs>
        <path
          stroke="url(#linear)"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
    );
  };

  return viewOfferModal?.showModal ? (
    <AvishModal
      submitButtonType="submit"
      submitButtonValue="close"
      title="View Offer"
      icon={<ModalIconBuild></ModalIconBuild>}
      onClose={closeHandler}
      onSubmit={closeHandler}
      showModal={!!viewOfferModal?.showModal}
      isFormSubmitting={!!viewOfferModal?.isModalFormSubmitting}
      isSubmitView={false}
    >
      <CModalBody>
        <div className="row">
          <div className="col-md-6 text center">
            <span>Id:</span>
          </div>
          <div className="col-md-6">
            <span>{viewOfferModal?.offer?.id}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 text center">
            <span>Offer Name:</span>
          </div>
          <div className="col-md-6">
            <span>{viewOfferModal?.offer?.offerName}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 text center">
            <span>Offer Description:</span>
          </div>
          <div className="col-md-6">
            <span>{viewOfferModal?.offer?.offerDescription}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 text center">
            <span>Active:</span>
          </div>
          <div className="col-md-6">
            <span>{viewOfferModal?.offer?.isActive ? "Yes" : "No"}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 text center">
            <span>Deleted:</span>
          </div>
          <div className="col-md-6">
            <span>{viewOfferModal?.offer?.isDeleted ? "Yes" : "No"}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 text center">
            <span>Creation:</span>
          </div>
          <div className="col-md-6">
            <span>{moment(viewOfferModal?.offer?.creationDate).format("MMM DD YYYY")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 text center">
            <span>Modified:</span>
          </div>
          <div className="col-md-6">
            <span>{moment(viewOfferModal?.offer?.modifiedDate).format("MMM DD YYYY")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 text center">
            <span>Discount Value:</span>
          </div>
          <div className="col-md-6">
            <span>{viewOfferModal?.offer?.discountValue}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 text center">
            <span>Discount Unit:</span>
          </div>
          <div className="col-md-6">
            <span>{viewOfferModal?.offer?.discountUnit}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 text center">
            <span>Max Discount Amount:</span>
          </div>
          <div className="col-md-6">
            <span>{viewOfferModal?.offer?.maximumDiscountAmount}</span>
          </div>
        </div>
      </CModalBody>
    </AvishModal>

  ) : (
    <></>
  );
};

export default ViewOfferModal;
