import { Reducer } from "redux";
import { OfferActionTypes, offersFilterInitial } from "./action.types";

export const initialState: offerState = {
  allOffers: [],
  offerFilter: offersFilterInitial,
  isAllOffersLoading: false,
  addOfferModal: {
    showModal: false,
    isModalFormSubmitting: false,
  },
  editOfferModal: {
    showModal: false,
    isModalFormSubmitting: false,
    offer: null,
  },
  viewOfferModal: {
    showModal: false,
    isModalFormSubmitting: false,
    offer: null,
  },
  deleteOfferModal: {
    showModal: false,
    isModalFormSubmitting: false,
    offer: null,
  },
};

const reducer: Reducer<offerState> = (state = initialState, action) => {
  switch (action.type) {
    case OfferActionTypes.SET_ALL_OFFERS: {
      return {
        ...state,
        allOffers: [...action.allOffers],
      };
    }
    case OfferActionTypes.SET_ALL_OFFERS_LOADER: {
      return {
        ...state,
        isAllOffersLoading: action.isAllOffersLoading,
      };
    }

    case OfferActionTypes.ADD_OFFER: {
      return {
        ...state,
        allOffers: [action.offer, ...state.allOffers],
      };
    }

    case OfferActionTypes.UPDATE_OFFER: {
      return {
        ...state,
        allOffers: [
          ...state.allOffers.map((el) => {
            if (el.id === action.offer.id) {
              el = { ...el, ...action.offer };
            }
            return el;
          }),
        ],
      };
    }

    case OfferActionTypes.SET_OFFER_MODAL: {
      return {
        ...state,
        addOfferModal: { ...state.addOfferModal, ...action.addOfferModal },
      };
    }

    case OfferActionTypes.RESET_OFFER_MODAL: {
      return {
        ...state,
        addOfferModal: {
          showModal: false,
          isModalFormSubmitting: false,
        },
      };
    }

    case OfferActionTypes.VIEW_OFFER_MODAL: {
      return {
        ...state,
        viewOfferModal: { ...state.viewOfferModal, ...action.viewOfferModal },
      };
    }

    case OfferActionTypes.EDIT_OFFER: {
      return {
        ...state,
        editOfferModal: { ...state.editOfferModal, ...action.editOfferModal },
      };
    }

    case OfferActionTypes.RESET_EDIT_OFFER_MODAL: {
      return {
        ...state,
        editOfferModal: {
          showModal: false,
          isModalFormSubmitting: false,
          offer: null,
        },
      };
    }
    case OfferActionTypes.VIEW_OFFER: {
      return {
        ...state,
        allOffers: [
          ...state.allOffers.map((el) => {
            if (el.id === action.offer.id) {
              el = { ...el, ...action.offer };
            }
            return el;
          }),
        ],
      };
    }
    case OfferActionTypes.RESET_VIEW_OFFER_MODAL: {
      return {
        ...state,
        viewOfferModal: {
          showModal: false,
          isModalFormSubmitting: false,
          offer: null,
        },
      };
    }
    case OfferActionTypes.DELETE_OFFER_MODAL: {
      return {
        ...state,
        deleteOfferModal: {
          ...state.deleteOfferModal,
          ...action.deleteOfferModal,
        },
      };
    }
    case OfferActionTypes.RESET_DELETE_OFFER_MODAL: {
      return {
        ...state,
        deleteOfferModal: {
          showModal: false,
          isModalFormSubmitting: false,
          offer: null,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as offerReducer };
