const planVariables = {
  fields: [
    {
      key: "name",
      label: "Name",
      _style: { width: "10%" },
    },
    { key: "skuid", _style: { width: "10%" } },
    { key: "isActive", _style: { width: "10%" } },
    { key: "minimumLicenceCount", _style: { width: "10%" } },
    { key: "subscriptionType", _style: { width: "10%" } },
    {
      key: "Action",
      label: "Action",
      _style: {width: "5%", textAlign: "center", paddingRight: "0.22rem"  },
      sorter: false,
      filter: false,
    },
  ],

  CSVHeaders: [
    { key: "name", label: "Name" },
    { key: "skuid", label: "Skuid" },
    { key: "isActive", label: "Is Active" },
    { key: "minimumLicenceCount", label: "MinimunLicence Count" },
    { key: "subscriptionType", label: "Subscription Type" },
  ],
};

export default planVariables;