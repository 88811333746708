const UserTableVariables = {
  fields: [
    {
      key: "name",
      label: "Name",
      _style: { width: "35%", textAlign:"start",color: "#6B6B6B", fontWeight: "600",fontSize:"1rem",padding:"10px 0",paddingLeft:"10px"},
    },
    { key: "detail", label: "Details", _style: { width: "35%", textAlign:"start",color: "#6B6B6B", fontWeight: "600",fontSize:"1rem",padding:"10px 0" ,paddingLeft:"10px"} },
    { key: "record", label: "Record", _style: { width: "15%", textAlign:"start",color: "#6B6B6B", fontWeight: "600",fontSize:"1rem",padding:"10px 0" } },
    {
      key: "Action",
      label: "Action",
      _style: { width: "15%", textAlign:"center",color: "#6B6B6B", fontWeight: "600",fontSize:"1rem",padding:"10px 0" },
      sorter: false,
      filter: false,
    },
  ],
  CSVHeaders: [
    { key: "first_Name", label: "Name" },
    { key: "isActive", label: "Is Active" },
    { key: "email", label: "Email" },
    { key: "phone", label: "Phone" },
  ],
  CSV: [
    { key: "first_Name", label: "Name", _style: { width: "10%" } },
    { key: "isActive", label: "Is Active", _style: { width: "2%" } },
    { key: "email", label: "Email", _style: { width: "10%" } },
    { key: "phone", label: "Phone ", _style: { width: "10%" } },
  ],
};

export default UserTableVariables;
