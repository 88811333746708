import Constants from "constants/Constants";
import { Dispatch } from "react";
import api from "services/api.service";
import { getAllBookmarkLoader } from "store/bookmark/action";
import { getAllGlobalTag, getAllGlobalTagLoader } from "store/tag/action";

const tagApi = {
    fetchAllGlobalTag: async function (
        dispatch: Dispatch<any>,
        pageNo: number,
        showLoader: boolean = true,
        searchKey?:string

    ) {
        if (showLoader) dispatch(getAllGlobalTagLoader(true));

        const params: any = {
            limit: 10,
            offset: pageNo,
        };

        if ( searchKey) {
            params.search = searchKey;
          }

        try {
            const response = await api.getWithParams(`${Constants.GET_ALL_TAG}`,{ ...params});
            dispatch(getAllGlobalTag(response.data))
        } catch (error) {
            throw error;
        } finally {
            if (showLoader) dispatch(getAllGlobalTagLoader(false));
        }
    },

}

export default tagApi;