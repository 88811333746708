import AvishModal from "components/modal/modal";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dispatch } from "redux";
import { ApplicationState } from "store";
import partnerApi from "../../../services/apis/partner.api";
import { resetEditPartnerModal } from "../../../store/partner/action";
import PartnerFormElements from "./partnerFormElement";

const EditPartnerModal = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const editPartnerModal = useSelector(
    (state: ApplicationState) => state.partner?.editPartnerModal
  );

  function closeHandler() {
    dispatch(resetEditPartnerModal());
  }

  function submitHandler(e) {
    const data = new FormData(e.target);

    var formData: IPartnerFormData = [...data.entries()].reduce((attr, el) => {
      attr[el[0]] = el[1];
      return attr;
    }, {} as IPartnerFormData);
    ////console.log("Partner Data", formData);
    partnerApi
      .updatePartner(dispatch, formData, editPartnerModal?.partner?.partnerID)
      .then(() => {
        toast.success("😊 Partner edited successfully.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        closeHandler();
      })
      .catch((error) => {
        toast.error(`😔 Error while editing partner!`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  }

  const ModalIconBuild = () => {
    return (
      <svg
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <defs>
          <linearGradient x1="16%" x2="84%" y1="0%" y2="100%" id="linear">
            <stop offset="0%" stopColor="#00B5FF" stopOpacity="1"></stop>
            <stop offset="55%" stopColor="#2F578A" stopOpacity="1"></stop>
          </linearGradient>
        </defs>
        <path
          stroke="url(#linear)"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
    );
  };
  return editPartnerModal?.showModal ? (
    <AvishModal
      submitButtonType="submit"
      submitButtonValue="Save Changes"
      title="Edit Partner"
      icon={<ModalIconBuild></ModalIconBuild>}
      onClose={closeHandler}
      onSubmit={closeHandler}
      showModal={!!editPartnerModal?.showModal}
      isFormSubmitting={!!editPartnerModal?.isModalFormSubmitting}
    >
      <PartnerFormElements
        partner={editPartnerModal?.partner}
      ></PartnerFormElements>
    </AvishModal>
  ) : (
    <></>
  );
};
export default EditPartnerModal;
