import { useContext } from "react";
import AvishInput from "../inputWrapper";
import { selectContext } from "./select.provider";

export default function SelectSearch() {
  const { selectedOption, setSearch, resetSelectedOption } =
    useContext(selectContext);

  // const selectedOption = useSelector(
  //   (state: ApplicationState) => state.global?.currentSelectData
  // );
  const resetOption = () => {
    resetSelectedOption();
  };

  function searchHandler(e) {
    setSearch(e.target.value);
  }

  const SelectedOptionBuild = () => {
    return selectedOption ? (
      <div className="avish-select__selected">
        <span>{selectedOption?.label}</span>
        <div
          className="avish-select__selected__remove"
          title="Remove selected option"
          onClick={resetOption}
        >
          <svg
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <div className="avish-modal__header__content">
      <AvishInput
        autoFocus
        placeholder="Search"
        className="avish-select__search"
        onChange={searchHandler}
      ></AvishInput>
      <SelectedOptionBuild></SelectedOptionBuild>
    </div>
  );
}
