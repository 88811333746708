import Constants from "constants/Constants";
import { Dispatch } from "react";
import api from "services/api.service";
import { getAllBookmarkLoader, getBookmarkByID } from "store/bookmark/action";

const bookmarkApi = {
    fetchBookmarkById: async function (
        dispatch: Dispatch<any>,
        selectedNodeId: string,
        pageNo: number,
        showLoader: boolean = true

    ) {
        if (showLoader) dispatch(getAllBookmarkLoader(true));

        const params: any = {
            limit: 10,
            offset: pageNo,
        };

        try {
            const dataResponse = await api.getWithParams(`${Constants.GET_ALL_BOOKMARK_BY_ID}/${selectedNodeId}`,{ ...params, isImage: false });

            const iconResponse = await api.getWithParams(`${Constants.GET_ALL_BOOKMARK_BY_ID}/${selectedNodeId}`,{ ...params, isImage:true });

            const mergedData: IBookmark[] = dataResponse.data.data.map(bookmark => {
                const iconData = iconResponse.data.find(iconBookmark => iconBookmark.id === bookmark.id);
                return {
                    ...bookmark,
                    icon: iconData ? iconData.icon : null
                };
            });

            const finalData={
                data: mergedData,
                totalcount:dataResponse.data.totalcount,
            }

            dispatch(getBookmarkByID(finalData))
        } catch (error) {
            throw error;
        } finally {
            if (showLoader) dispatch(getAllBookmarkLoader(false));
        }
    },

}

export default bookmarkApi;