import CIcon from "@coreui/icons-react";
import {
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem
} from "@coreui/react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { Dispatch } from "redux";
import { NavigationHeaderAccountDropdown } from "./index";

import { ApplicationState } from "store";
import "styles/_navHeader.scss";

const NavigationHeader: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const sidebar: ISidebar = useSelector(
    (state: ApplicationState) => state.sidebar.sidebar,
    shallowEqual
  );

  // const toggleSidebar = () => {
  //   const val = !sidebar.showSidebar;
  //   dispatch(addSidebar({ showSidebar: val }));
  // };

  // const toggleSidebarMobile = () => {
  //   const val = !![false, "responsive"].includes(sidebar.showSidebar!);
  //   dispatch(addSidebar({ showSidebar: val }));
  // };

  return (
    <CHeader className={sidebar.showSidebar ? "sidebar-open-class header" : "sidebar-close-class header"}>
      <div className={sidebar.showSidebar ? "d-flex  marginleft ":" d-flex responsive-marginleft "}>
        <img
          src={process.env.REACT_APP_BASEURL + "/TagMyFavLogo.png"}
          alt="tmflogo"
          className="float-left"
          loading="lazy"
       
        />
        {/* <div>
          <CToggler
            inHeader
            className="ml-md-3 d-lg-none"
            onClick={toggleSidebarMobile}
          />
          <CToggler
            inHeader
            className="ml-3 d-md-down-none"
            onClick={toggleSidebar}
          />
        </div> */}
      </div>
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <CIcon className="logo" height="48" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem className="px-3"></CHeaderNavItem>
      </CHeaderNav>
      <CHeaderNav className="px-3">
        <NavigationHeaderAccountDropdown />
      </CHeaderNav>
    </CHeader>
  );
};

export default NavigationHeader;
