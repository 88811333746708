import { authContext } from "auth/auth.provider";
import { useContext } from "react";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";

import IRoute from "interfaces/route";

const PublicRoute = ({
  component: Component,
  path,
  name,
  exact,
  isProtected,
  props,
  ...rest
}: IRoute) => {
  const { isAuthenticated } = useContext(authContext);
  return (
    <Route
      {...props}
      path={path}
      exact={exact}
      render={(props: RouteComponentProps<any>) =>
        isAuthenticated ? (
          <Redirect to="/" />
        ) : (
          <Component name={name} {...props} {...rest} />
        )
      }
    />
  );
};

export default PublicRoute;
