const offerTableVariables = {
  fields: [
    { key: "offerName", _style: { width: "25%" } },
    { key: "offerDescription", _style: { width: "30%" } },
    { key: "isActive", _style: { width: "10%" } },
    { key: "discountUnit", _style: { width: "15%" } },
    { key: "maximumDiscountAmount", _style: { width: "15%" } },
    {
      key: "Action",
      label: "Action",
      _style: { width: "5%", textAlign: "center", paddingRight: "0.22rem" },
      sorter: false,
      filter: false,
    },
  ],
  CSV:[
    { key: "offerName", _style: { width: "10%" } },
    { key: "offerDescription", _style: { width: "10%" } },
    { key: "isActive", _style: { width: "10%" } },
    { key: "discountUnit", _style: { width: "5%" } },
    { key: "maximumDiscountAmount", _style: { width: "5%" } }
  ]
};

export default offerTableVariables;
