import { CDropdown, CDropdownMenu, CDropdownToggle } from "@coreui/react";
import { ReactNode } from "react";
import { BsThreeDots } from "react-icons/bs";
import { RiArrowDropDownLine } from "react-icons/ri";
import "styles/_dropdown.scss";

interface ITmfDropdown {
  toggleIcon?: string;
  props?: CDropdown;
  children?: ReactNode;
  name?: string;
}

const TmfDropdown = ({
  toggleIcon = "caret-down",
  props,
  children,
  name
}: ITmfDropdown) => {
  return (
    <>
      {
        name === "court" ? (
          <>

            <CDropdown
              className="tmf-dropdown"
              {...props}
              style={{
                height: "40px",
              }}
            >
              <CDropdownToggle
                caret={false}
                size="sm"
                className="dropdown"
                style={{ outline: "#fff" }}
              >
                <BsThreeDots style={{ height: "20px", width: "20px" }} />
              </CDropdownToggle>
              <CDropdownMenu>{children}</CDropdownMenu>
            </CDropdown>
          </>
        ) : (
          <>
            <CDropdown
              className="tmf-dropdown"
              {...props}
              style={{
                borderRadius: "0px 25px 25px 0px",
                borderTop: " 1px solid var(--primary, #1C63B7)",
                borderRight: "1px solid var(--primary, #1C63B7)",
                borderBottom: "1px solid var(--primary, #1C63B7)",
                height: "40px",
              }}
            >
              <CDropdownToggle
                caret={false}
                size="sm"
                className="dropdown"
                style={{ outline: "#fff" }}
              >

                <RiArrowDropDownLine style={{ height: "20px", width: "20px" }} />
              </CDropdownToggle>
              <CDropdownMenu>{children}</CDropdownMenu>
            </CDropdown>
          </>
        )
      }
    </>
  );
};

export default TmfDropdown;
