const partnerVariables = {
  fields: [
    {
      key: "organizationName",
      label: "Organization Name",
      _style: { width: "10%" },
    },
    { key: "partnerName", _style: { width: "10%" } },
    { key: "isActive", _style: { width: "10%" } },
    { key: "creationDate", _style: { width: "10%" } },
   
  ],

  CSVHeaders: [
    { key: "organizationName", label: "Organization Name" },
    { key: "partnerName", label: "Partner Name" },
    { key: "isActive", label: "Is Active" },
    { key: "creationDate", label: "Creation Date" },
  ],
};

export default partnerVariables;
