const avatarInitials = {
    getAvatarInitials: (userName: string) => {
        const nameList = userName.split(" ");
        var initials = "";
        if (nameList.length > 1) {
            initials =
                nameList[0].slice(0, 1) + nameList[nameList.length - 1].slice(0, 1);
        } else {
            if (userName.length > 1) {
                initials = userName.slice(0, 2);
            } else {
                initials = userName;
            }
        }
        return initials;
    }
};
export default avatarInitials;