

const DefaultSubscription = (id , userName, subscriptionName, subscriptionType, endDate, totalAmount) => `
<div class="content_wrapper">
  <table style="width: 100%;border-collapse: collapse; margin:0; background-color: #DFE1E3;">  
          <tr>
            <td colspan="6"  style="text-align:center;">
              <img src="64x64.png" alt="tmf logo" height="40" width="40"/>
            </td>
          </tr>
          <tr>
            <td colspan="6" style="text-align:center">
              <h4>TagMyFav - Save, Share & Manage your favourites on Microsoft365</h4>
            <td>
          </tr>
          <tr>
          <td colspan="6" style="text-align:center">
            <h4> Subscription Details</h4>
          </td>
        </tr>
            <tr>
             <td colspan="6">
                 <h5>Your Subscription is About to Expire</h5>
             </td>
             </tr>
             <tr>
                     <th>UserID</th>
                     <th>UserName</th>
                     <th>Name</th>
                     <th>Type</th>
                     <th>Expiry Date</th>
                     <th>Price</th>
              </tr>
                   <tr>
                     <td>${id}</td>
                     <td>${userName}</td>
                     <td>${subscriptionName}</td>
                     <td>${subscriptionType}</td>
                     <td>${endDate}</td>
                     <td>${totalAmount}</td>
                   </tr>                     
                   <tr>
                     <td colspan="5" class="tl"> Grand Total</th>
                     <td>${totalAmount}</th>
                   </tr>
                  <tr class="footer">
                    <td colspan="2"><a href="https://www.tagmyfav.com/"> &copy; 2023</a></td>
                    <td colspan="2"><a href="https://www.tagmyfav.com/privacy-policy">Privacy Policy</a></td>
                    <td colspan="2"><a href="https://www.tagmyfav.com/terms-conditions">Terms & Conditions</a></td>
                  </tr>
  </table>
</div>`;
export { DefaultSubscription };
