import { Reducer } from "redux";
import { CategoryActionTypes } from "./action.types";

export const initialState: categoryState = {
  category: [],
  isLoading: false,
  subCategory:[],
  isSubCategoryLoading:false
};

const reducer: Reducer<categoryState> = (state = initialState, action) => {
  switch (action.type) {
    case CategoryActionTypes.SET_ALL_COURT_CATEGORY: {
      return {
        ...state,
        category: [...action.category],
      };
    }

    case CategoryActionTypes.SET_ALL_COURT_CATEGORY_LOADER: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }

    case CategoryActionTypes.SET_COURT_SUB_CATEGORY: {
      return {
        ...state,
        subCategory: [...action.subCategory],
      };
    }

    case CategoryActionTypes.SET_COURT_SUB_CATEGORY_LOADER: {
      return {
        ...state,
        isSubCategoryLoading: action.isSubCategoryLoading,
      };
    }


    case CategoryActionTypes.EDIT_CATEGORY_MODAL: {
      return {
        ...state,
        editCategoryModal: { ...state.editCategoryModal, ...action.editCategoryModal },
      };
    }
    case CategoryActionTypes.ADD_SUB_CATEGORY_MODAL: {
      return {
        ...state,
        addSubCategoryModal: { ...state.addSubCategoryModal, ...action.addSubCategoryModal },
      };
    }
    case CategoryActionTypes.DELETE_CATEGORY_MODAL: {
      return {
        ...state,
        deleteCategoryModal: { ...state.deleteCategoryModal, ...action.deleteCategoryModal },
      };
    }
 

    default: {
      return state;
    }
  }
};

export { reducer as categoryReducer };
