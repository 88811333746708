import { Reducer } from "redux";
import { TagActionTypes } from "./action.types";


export const initialState: tagState = {
    alltag: {
        data: [],
        totalcount: 0
    },
    isLoading: false,
};

const reducer: Reducer<tagState> = (state = initialState, action) => {
    switch (action.type) {

        case TagActionTypes.SET_ALL_GLOBAL_TAG: {
            return {
                ...state,
                alltag: action.alltag,
            };
        }

        case TagActionTypes.SET_ALL_TAG_LOADER : {
            return {
                ...state,
                isLoading: action.isLoading,
            };
        }
        case TagActionTypes.SET_EDIT_TAG_MODAL : {
            return {
                ...state,
                editTagModal: { ...state.editTagModal, ...action.editTagModal },
            };
        }
        case TagActionTypes.SET_DELETE_TAG_MODAL : {
            return {
                ...state,
                deleteTagModal: { ...state.deleteTagModal, ...action.deleteTagModal },
            };
        }


        default: {
            return state;
          }
    }
}

export { reducer as tagReducer };