import {
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import axios from "axios";
import Loader from "components/loader/Loader";
import Constants from "constants/Constants";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
const AddIconModal = ({ isOpen, onClose, refreshDetails }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState();
  const [fileTypeError, setFileTypeError] = useState();
  const [fileNameError, setFileNameError] = useState();
  const [icon, setIcon] = useState({
    name: "",
    value: "",
  });
  const { handleSubmit } = useForm();

  const onInputChange = (e) => {
    let fileName = e.target.value;
    let pattern = new RegExp(/^[a-zA-Z_]*$/);
    let validateFileName = pattern.test(fileName);
    if (!validateFileName) {
      setFileNameError("invalid file name");
    } else {
      setIcon({ ...icon, name: e.target.value });
      setFileNameError(null);
    }
  };
  const onSubmit = () => {
    if (icon.name !== "" || icon.value !== "") {
      setIsLoading(true);
      axios
        .post(Constants.ADD_ICON, icon)
        .then((res) => {
          if (res.data === "") {
            Swal.fire({
              title: "Icon not added!",
              icon: "error",
              position: "top-end",
              timer: 2000,
              showConfirmButton: false,
              customClass: {
                title: "title-class h6",
                icon: "icon-class small font-weight-200",
              },
            });
          } else {
            Swal.fire({
              title: "Icon added",
              icon: "success",
              position: "top-end",
              timer: 2000,
              showConfirmButton: false,
              customClass: {
                title: "title-class h6",
                icon: "icon-class small font-weight-200",
              },
            });
          }
          refreshDetails();
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => setIsLoading(false));
    }
  };
  const getSvgFile = (e) => {
    if (e.target.files[0].type === "image/svg+xml") {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.addEventListener("load", (e) => {
        const svgValue = e.target.result;
        setFileName(file.name);
        document.getElementById("uploaded_svg").innerHTML = e.target.result;
        setIcon({ ...icon, value: svgValue });
      });
      reader.readAsText(file);
      setFileTypeError(null);
    } else {
      setFileName(null);
      setIcon({ ...icon, value: null });
      document.getElementById("uploaded_svg").innerHTML = "";
      setFileTypeError("not a valid svg file");
    }
  };

  return (
    <div>
      <CModal show={isOpen} onClose={onClose}>
        <CModalHeader closeButton>
          <CModalTitle>Add New Icon</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ fontWeight: "bold", fontSize: "12px" }}
          >
            <div className="form-row">
              <label>Icon Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={icon.name}
                onChange={(e) => onInputChange(e)}
              />
              <div className="small text-danger mt-2">
                {fileNameError && (
                  <>
                    Name must not contain space or any special character except
                    _
                  </>
                )}
              </div>
            </div>

            <div className="form-row mt-2">
              <label htmlFor="svg-icon">
                <div className="d-flex">
                  <div>
                    <span className="d-block">
                      Svg File &nbsp;&nbsp; {fileName}
                    </span>
                    <div className="form-control w-100">
                      <i className="fa fa-upload fa-7x"></i>
                    </div>
                  </div>
                  <div id="uploaded_svg" className="ml-2"></div>
                </div>
              </label>
              <div className="small text-danger w-100">
                {fileTypeError && <>svg file is required.</>}
              </div>

              <input
                type="file"
                id="svg-icon"
                className="form-control"
                accept=".svg"
                name="value"
                onChange={getSvgFile}
                style={{ visibility: "hidden" }}
              />

              {/* <textarea{}
                className="form-control"
                name="value"
                value={icon.value}
                onChange={(e) => onInputChange(e)}
                rows={5}
              /> */}
            </div>
            <div
              style={{
                placeItems: "center",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                marginTop: "10px",
              }}
            >
              <CButton style={{background: "var(--primary)",color: "white"}} type="submit" size="sm">
                {isLoading && <Loader size="1.5rem" className="d-inline" />}{" "}
                Save Changes
              </CButton>
            </div>
          </form>
        </CModalBody>
      </CModal>
    </div>
  );
};

export default AddIconModal;
