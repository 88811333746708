import AvishModal from "components/modal/modal";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import planApi from "services/apis/plan.api";
import { ApplicationState } from "store";
import { resetAddPlanModal } from "store/plan/action";
import PlanFormElements from "./planFormElement";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddPlanModal = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const addPlanModal = useSelector(
    (state: ApplicationState) => state.plan?.addPlanModal
  );

  function closeHandler() {
    dispatch(resetAddPlanModal());
  }

  function submitHandler(e) {
    const data = new FormData(e.target);
    var formData: IPlanFormData = [...data.entries()].reduce((attr, el) => {
      attr[el[0]] = el[1];
      return attr;
    }, {} as IPlanFormData);
    planApi
      .createNewPlan(dispatch, formData)
      .then(() => {
        toast.success("😊 Plan added successfully.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        closeHandler();
      })
      .catch((error) => {
        toast.error(`😔 Error while adding plan!`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  }

  const ModalIconBuild = () => {
    return (
      <svg
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <defs>
          <linearGradient x1="16%" x2="84%" y1="0%" y2="100%" id="linear">
            <stop offset="0%" stopColor="#00B5FF" stopOpacity="1"></stop>
            <stop offset="55%" stopColor="#2F578A" stopOpacity="1"></stop>
          </linearGradient>
        </defs>
        <path
          stroke="url(#linear)"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
    );
  };

  return addPlanModal?.showModal ? (
    <AvishModal
      submitButtonType="submit"
      submitButtonValue="Add Plan"
      title="Add Plan"
      icon={<ModalIconBuild></ModalIconBuild>}
      onClose={closeHandler}
      onSubmit={submitHandler}
      showModal={!!addPlanModal?.showModal}
      isFormSubmitting={!!addPlanModal?.isModalFormSubmitting}
    >
      <PlanFormElements></PlanFormElements>
    </AvishModal>
  ) : (
    <></>
  );
};

export default AddPlanModal;
