import { LeadsActionTypes } from "./action.types";

// All Leads
export function setAllLeads(allLeads: Ilead[]) {
  const action: LeadAction = {
    type: LeadsActionTypes.SET_ALL_LEADS,
    allLeads,
  };
  return (dispatch: LeadDispatchType) => dispatch(action);
}

export function setAllLeadLoader(isLeadFilterDataLoading: boolean) {
  const action: LeadAction = {
    type: LeadsActionTypes.SET_ALL_LEAD_LOADER,
    isLeadFilterDataLoading,
  };
  return (dispatch: LeadDispatchType) => dispatch(action);
}

//leads Filter
export function setLeadsFilter(leadsFilter: ILeadsFilter) {
  const action: LeadAction = {
    type: LeadsActionTypes.SET_LEADS_FILTER,
    leadsFilter,
  };
  return (dispatch: LeadDispatchType) => dispatch(action);
}

export function viewLead(lead: Ilead) {
  const action: LeadAction = {
    type: LeadsActionTypes.VIEW_LEAD,
    lead,
  };
  return (dispatch: LeadDispatchType) => dispatch(action);
}

export function setViewLeadModal(viewLeadModal: IViewLeadModal) {
  const action: LeadAction = {
    type: LeadsActionTypes.VIEW_LEAD_MODAL,
    viewLeadModal,
  };
  return (dispatch: LeadDispatchType) => dispatch(action);
}

export function setViewLeadsMailModal(viewLeadsMailModal: IViewLeadsMailModal) {
  const action: LeadAction = {
    type: LeadsActionTypes.VIEW_LEAD_MAIL_MODAL,
    viewLeadsMailModal,
  };
  return (dispatch: LeadDispatchType) => dispatch(action);
}

export function setLeadSearchValue(leadSearchValue: string | undefined) {
  const action = {
    type: LeadsActionTypes.SET_LEAD_SEARCH_VALUE,
    leadSearchValue,
  };
  return (dispatch: LeadDispatchType) => dispatch(action);
}
