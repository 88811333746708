import {
  CCol,
  CContainer,
  CRow
} from '@coreui/react';
import { useEffect } from "react";

const Page401 = () => {
  useEffect(() => {
    setTimeout(() => {
      window.opener.UNAUTHORIZED_LOGIN = "unauthorized";
      window.close();
    }, 3000);
  }, []);
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            <div className="clearfix">
              <h1 className="float-left display-3 mr-4">401</h1>
              <h4 className="pt-3">Oops!</h4>
              <p className="text-muted float-left">UnAuthorized Access</p>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}
export default Page401
