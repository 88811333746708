import {
  CModal,
  CModalHeader,
  CModalTitle
} from "@coreui/react";
import Loader from "components/loader/Loader";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "services/api.service";
import Constants from "../../constants/Constants";

interface IOrganizationDetailsProps {
  organization: IOrganization;
}

const OrganizationDetails = ({ organization }: IOrganizationDetailsProps) => {
  const [organizationDetails, setOrganizationDetails] = useState({});

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadOrganizationDetails();
  });

  const loadOrganizationDetails = () => {
    api
      .post(
        Constants.ORGANIZATION_DETAILS_GET_URI +
          "/" +
          organization.organizationId
      )
      .then((res) => setOrganizationDetails(res.data))
      .finally(() => setLoading(true));
  };

  return (
    <CModal show={true} closeOnBackdrop={false}>
      {organizationDetails && loading ? (
        <div>
          <CModalHeader closeButton>
            <CModalTitle>
              <div>Add Organization</div>{" "}
            </CModalTitle>
          </CModalHeader>
          <Link className="btn btn-primary btn-sm" to="/organization">
            Back
          </Link>
          <div>Organization: {organizationDetails["name"]}</div>
          {/* <CRow>
            <CCol xs="12" sm="6" md="4">
              <CCard borderColor="warning">
                <CCardHeader className="text-center">
                  <h4> Details</h4>
                </CCardHeader>
                <CCardBody>
                  <li className="list-group-item">
                    Active User Count: {organizationDetails["activeUserCount"]}
                  </li>{" "}
                  <li className="list-group-item">
                    Admin Email: {organizationDetails.adminEmail}
                  </li>
                  <li className="list-group-item">
                    Admin Name: {organizationDetails.adminName}
                  </li>
                  <li className="list-group-item">
                    Org Admin: {organizationDetails.orgAdmin}
                  </li>
                  <li className="list-group-item">Name: {organizationDetails.name}</li>
                  <li className="list-group-item">
                    Creation_Date: {organizationDetails.creationDate}
                  </li>
                  <li className="list-group-item">
                    Description: {organizationDetails.description}
                  </li>
                  <li className="list-group-item ">
                    IsActive: {organizationDetails.isActive ? "True" : "False"}
                  </li>
                  <li className="list-group-item">
                    Modified Date: {organizationDetails.modifiedDate}
                  </li>
                  <li className="list-group-item">E-mail: {organizationDetails.email}</li>
                  <li className="list-group-item">
                    Address: {organizationDetails.address}
                  </li>
                  <li className="list-group-item">Phone: {organizationDetails.phone}</li>
                  
                  <li className="list-group-item">
                    TotalUserCount: {organizationDetails.totalUserCount}
                  </li>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol xs="12" sm="6" md="4">
              <CCard borderColor="info">
                <CCardHeader className="text-center">
                  <h4>Plan</h4>
                </CCardHeader>
                <CCardBody>
                  <li className="list-group-item">
                    Creation Date: {organizationDetails?.plan?.creationDate}
                  </li>

                  <li className="list-group-item">
                    Is Active: {organizationDetails?.plan?.isActive ? "True" : "False"}
                  </li>

                  <li className="list-group-item">
                    Minimum License Count: {organizationDetails?.plan?.minimumLicenceCount}
                  </li>
                  <li className="list-group-item">
                    Modified Date: {organizationDetails?.plan?.modifiedDate}
                  </li>
                  <li className="list-group-item">
                    Per License Cost: {organizationDetails?.plan?.perLicenceCost}
                  </li>

                  <li className="list-group-item">
                    Skuid: {organizationDetails?.plan?.skuid}
                  </li>
                  <li className="list-group-item">
                    Subscription Type: {organizationDetails?.plan?.subscriptionType}
                  </li>
                </CCardBody>
              </CCard>
            </CCol>

            <CCol xs="12" sm="6" md="4">
              <CCard borderColor="success">
                <CCardHeader className="text-center">
                  <h4>Subscription</h4>
                </CCardHeader>
                <CCardBody>
                  <li className="list-group-item">
                    Auto Renew:{" "}
                    {organizationDetails?.subscription?.autoRenew ? "True" : "False"}
                  </li>

                  <li className="list-group-item">
                    Creation Date: {organizationDetails?.subscription?.creationDate}
                  </li>
                  <li className="list-group-item">
                    End Date: {organizationDetails?.subscription?.endDate}
                  </li>

                  <li className="list-group-item">
                    Is Active:{" "}
                    {organizationDetails?.subscription?.isActive ? "True" : "False"}
                  </li>

                  <li className="list-group-item">
                    Minimum License Count:{" "}
                    {organizationDetails?.subscription?.minimumLicenceCount}
                  </li>

                  <li className="list-group-item">
                    Modified Date: {organizationDetails?.subscription?.modifiedDate}
                  </li>

                  <li className="list-group-item">
                    Name: {organizationDetails?.subscription?.name}
                  </li>

                  <li className="list-group-item">
                    Per License Cost: {organizationDetails?.subscription?.perLicenseCost}
                  </li>

                  <li className="list-group-item">
                    Purchased Licenses:{" "}
                    {organizationDetails?.subscription?.purchasedLicenses}
                  </li>
                  <li className="list-group-item">
                    Remaining License: {organizationDetails?.subscription?.remainingLicence}
                  </li>
                  <li className="list-group-item">
                    Start Date: {organizationDetails?.subscription?.startDate}
                  </li>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow> */}
        </div>
      ) : (
        <div className="spinner">
          <Loader color="blue.500" size="2rem" />
        </div>
      )}
    </CModal>
  );
};

export default OrganizationDetails;
