import { OrganizationActionTypes } from "./action.types";

//Filtered organizations
export function setOrganizations(organizations: IOrganization[]) {
  const action: OrganizationAction = {
    type: OrganizationActionTypes.SET_ORGANIZATIONS,
    organizations,
  };
  return (dispatch: OrganizationDispatchType) => dispatch(action);
}

export function setOrganizationsLoader(isLoading: boolean) {
  const action: OrganizationAction = {
    type: OrganizationActionTypes.SET_ORGANIZATIONS_LOADER,
    isLoading,
  };
  return (dispatch: OrganizationDispatchType) => dispatch(action);
}

// All Organizations
export function setAllOrganizations(allOrganizations: IAllOrganization[]) {
  const action: OrganizationAction = {
    type: OrganizationActionTypes.SET_ALL_ORGANIZATIONS,
    allOrganizations,
  };
  return (dispatch: OrganizationDispatchType) => dispatch(action);
}

export function setAllOrganizationLoader(isLoading: boolean) {
  const action: OrganizationAction = {
    type: OrganizationActionTypes.SET_ALL_ORGANIZATION_LOADER,
    isLoading,
  };
  return (dispatch: OrganizationDispatchType) => dispatch(action);
}

//Organization types
export function setOrganizationTypes(organizationTypes: IOrganizationType[]) {
  const action: OrganizationAction = {
    type: OrganizationActionTypes.SET_ORGANIZATION_TYPES,
    organizationTypes,
  };
  return (dispatch: OrganizationDispatchType) => dispatch(action);
}

//Organization modals
export function setAddOrganizationModal(showAddOrganizationModal: boolean) {
  const action: OrganizationAction = {
    type: OrganizationActionTypes.SET_SHOW_ADD_ORGANIZATION_MODAL,
    showAddOrganizationModal,
  };
  return (dispatch: OrganizationDispatchType) => dispatch(action);
}

//Organization Filters
export function setOrganizationFilters(
  organizationFilters: IOrganizationFilter
) {
  const action: OrganizationAction = {
    type: OrganizationActionTypes.SET_ORGANIZATION_FILTERS,
    organizationFilters,
  };
  return (dispatch: OrganizationDispatchType) => dispatch(action);
}

//Organization Loader
export function setOrganizationLoader(isOrganizationLoading: IOrganizationLoader) {
  const action: OrganizationAction = {
    type: OrganizationActionTypes.SET_ORGANIZATION_LOADER,
    isOrganizationLoading,
  };
  return (dispatch: OrganizationDispatchType) => dispatch(action);
}

//Organization status
export function setOrganizationStatus(organization: IOrganization) {
  const action: OrganizationAction = {
    type: OrganizationActionTypes.SET_ORGANIZATION_STATUS,
    organization: organization,
  };
  return (dispatch: OrganizationDispatchType) => dispatch(action);
}

// edit organization modal
export function setEditOrganizationModal(editOrganizationModal: IEditOrganizationModal) {
  const action: OrganizationAction = {
    type: OrganizationActionTypes.EDIT_ORGANIZATION_MODAL,
    editOrganizationModal,
  };
  return (dispatch: OrganizationDispatchType) => dispatch(action);
}