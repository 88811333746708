import { PartnerActionTypes } from "./action.types";

export function setAllPartners(allPartners: Ipartner[]) {
  const action: PartnerAction = {
    type: PartnerActionTypes.SET_ALL_PARTNERS,
    allPartners,
  };
  return (dispatch: PartnerDispatchType) => dispatch(action);
}

export function setAllPartnerLoader(isAllPartnersLoading: boolean) {
  const action: PartnerAction = {
    type: PartnerActionTypes.SET_ALL_PARTNERS_LOADER,
    isAllPartnersLoading,
  };
  return (dispatch: PartnerDispatchType) => dispatch(action);
}

export function addPartner(partner: Ipartner) {
  const action: PartnerAction = {
    type: PartnerActionTypes.ADD_PARTNER,
    partner,
  };
  return (dispatch: PartnerDispatchType) => dispatch(action);
}

export function updatePartner(partner: Ipartner) {
  const action: PartnerAction = {
    type: PartnerActionTypes.UPDATE_PARTNER,
    partner,
  };
  return (dispatch: PartnerDispatchType) => dispatch(action);
}

export function setAddPartnerModal(addPartnerModal: IAddPartnerModal) {
  const action: PartnerAction = {
    type: PartnerActionTypes.ADD_PARTNER_MODAL,
    addPartnerModal,
  };
  return (dispatch: PartnerDispatchType) => dispatch(action);
}

export function resetAddPartnerModal() {
  const action: PartnerAction = {
    type: PartnerActionTypes.RESET_ADD_PARTNER_MODAL,
  };
  return (dispatch: PartnerDispatchType) => dispatch(action);
}

export function setEditPartnerModal(editPartnerModal: IEditPartnerModal) {
  const action: PartnerAction = {
    type: PartnerActionTypes.EDIT_PARTNER_MODAL,
    editPartnerModal,
  };
  return (dispatch: PartnerDispatchType) => dispatch(action);
}

export function resetEditPartnerModal() {
  const action: PartnerAction = {
    type: PartnerActionTypes.RESET_EDIT_PARTNER_MODAL,
  };
  return (dispatch: PartnerDispatchType) => dispatch(action);
}

export function setPartnerTypes(partnerTypes: IPartnerType[]) {
  const action: PartnerAction = {
    type: PartnerActionTypes.SET_PARTNER_TYPES,
    partnerTypes,
  };
  return (dispatch: PartnerDispatchType) => dispatch(action);
}
export function resetViewPartnerModal() {
  const action: PartnerAction = {
    type: PartnerActionTypes.RESET_VIEW_PARTNER_MODAL,
  };
  return (dispatch: PartnerDispatchType) => dispatch(action);
}

export function setDeletePartnerModal(deletePartnerModal: IDeletePartnerModal) {
  const action: PartnerAction = {
    type: PartnerActionTypes.DELETE_PARTNER_MODAL,
  };
  return (dispatch: PartnerDispatchType) => dispatch(action);
}

export function setViewPartnerModal(viewPartnerModal: IViewPartnerModal) {
  const action: PartnerAction = {
    type: PartnerActionTypes.VIEW_PARTNER_MODAL,
    viewPartnerModal,
  };
  return (dispatch: PartnerDispatchType) => dispatch(action);
}
