import { UserActionTypes } from "./action.types";

export function setUsers(users: IUser[]) {
  const action: UserAction = {
    type: UserActionTypes.SET_USERS,
    users,
  };
  return (dispatch: UserDispatchType) => dispatch(action);
}

export function setUsersLoader(isUsersLoading: boolean) {
  const action: UserAction = {
    type: UserActionTypes.SET_USERS_LOADER,
    isUsersLoading,
  };
  return (dispatch: UserDispatchType) => dispatch(action);
}

//Users Filter
export function setUsersFilter(
  usersFilter: IUserFilter
) {
  const action: UserAction = {
    type: UserActionTypes.SET_USERS_FILTER,
    usersFilter,
  };
  return (dispatch: UserDispatchType) => dispatch(action);
}

//User Loader
export function setUserLoader(isUserLoading: IUserLoader) {
  const action: UserAction = {
    type: UserActionTypes.SET_USER_LOADER,
    isUserLoading: isUserLoading,
  };
  return (dispatch: UserDispatchType) => dispatch(action);
}

//User status
export function setUserStatus(user: IUser) {
  const action: UserAction = {
    type: UserActionTypes.SET_USER_STATUS,
    user: user,
  };
  return (dispatch: UserDispatchType) => dispatch(action);
}

// edit user modal
export function setEditUserModal(editUserModal: IEditUserModal) {
  const action: UserAction = {
    type: UserActionTypes.EDIT_USER_MODAL,
    editUserModal,
  };
  return (dispatch: UserDispatchType) => dispatch(action);
}
// delete user modal
export function setDeleteUserModal(deleteUserModal: IDeleteUserModal) {
  const action: UserAction = {
    type: UserActionTypes.DELETE_USER_MODAL,
    deleteUserModal,
  };
  return (dispatch: UserDispatchType) => dispatch(action);
}
// view user modal
export function setViewUserModal(viewUserModal: IViewUserModal) {
  const action: UserAction = {
    type: UserActionTypes.VIEW_USER_MODAL,
    viewUserModal,
  };
  return (dispatch: UserDispatchType) => dispatch(action);
}

// undo deleted modal
export function setUndoDeleteUserModal(undoDeleteUserModal: IUndoDeleteUserModal) {
  const action: UserAction = {
    type: UserActionTypes.UNDO_DELETE_USER_MODAL,
    undoDeleteUserModal,
  };
  return (dispatch: UserDispatchType) => dispatch(action);
}