export enum LeadsActionTypes {
    SET_ALL_LEADS = "@@lead/SET_ALL_LEADS",
    SET_LEADS = "@@lead/SET_LEADS",
    SET_LEADS_FILTER ="@@lead/SET_LEADS_FILTER",
    SET_ALL_LEAD_LOADER = "@@lead/SET_ALL_LEAD_LOADER",
    SET_LEADS_LOADER = "@@leads/SET_LEADS_LOADER",
    VIEW_LEAD ="@@leads/VIEW_LEAD",
    VIEW_LEAD_MODAL="@@leads/VIEW_LEAD_MODAL",
    SET_LEAD_FILTER_DATA_LOADER = "@@leads/SET_LEAD_FILTER_DATA_LOADER",
    VIEW_LEAD_MAIL_MODAL = "@@leads/VIEW_LEAD_MAIL_MODAL",
    SET_VIEW_LEAD_MAIL_MODAL="@@leads/SET_VIEW_LEAD_MAIL_MODAL",
    SELECT_ALL_LEADS="@@leads/SELECT_ALL_LEADS",
    SET_LEAD_SEARCH_VALUE="@@SET_LEAD_SEARCH_VALUE"
  }

  export const leadsFilterInitial: ILeadsFilter = {
    BeginDate: null,
    EndDate: null,
    IsSubscribed: null,
    LeadSource: null,
  };
  