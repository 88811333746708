import { Dispatch } from "redux";
import apiUrls from "constants/Constants";
import api from "services/api.service";
import {
  setFilteredSubscriptions,
  setFilteredSubscriptionsLoader,
  setSubscriptionLoader,
  setSubscriptionStatus,
  setSubscriptionTypes,
  setYearlySubscriptions,
} from "store/subscription/action";

const subscriptionApi = {
  fetchSubscriptionTypes: function (dispatch: Dispatch<any>) {
    return api
      .get(apiUrls.SUBSCRIPTION_TYPES_GET_ALL_URI)
      .then((res) => {
        dispatch(setSubscriptionTypes(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  fetchYearlySubscriptions: function (dispatch: Dispatch<any>) {
    return api
      .get(apiUrls.SUBSCRIPTION_GETYEARLY_URI)
      .then((res) => {
        dispatch(setYearlySubscriptions(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  fetchFilteredSubscriptions: function (
    payload: ISubscriptionFilter,
    dispatch: Dispatch<any>,
    pageSize,
    pageNo
  ) {
    dispatch(setFilteredSubscriptionsLoader(true));
    return api
      .post(`${apiUrls.SUBSCRIPTION_FILTER_URI}/${pageSize}/${pageNo}`, payload)
      .then((res) => {
        dispatch(setFilteredSubscriptions(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => dispatch(setFilteredSubscriptionsLoader(false)));
  },

  fetchExpiredSubscriptions: function (
    payload: ISubscriptionFilter,
    dispatch: Dispatch<any>,
    pageSize,
    pageNo
  ) {
    dispatch(setFilteredSubscriptionsLoader(true));
    return api
      .post(`${apiUrls.SUBSCRIPTION_EXPIRED_URI}/${pageSize}/${pageNo}`, payload)
      .then((res) => {
        dispatch(setFilteredSubscriptions(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => dispatch(setFilteredSubscriptionsLoader(false)));
  },

  toggleSubscriptionStatus: function (
    subscription: ISubscription,
    dispatch: Dispatch<any>
  ) {
    dispatch(
      setSubscriptionLoader({
        id: subscription.id,
        isStatusLoading: true,
      })
    );
    return api
      .post(
        apiUrls.SUBSCRIPTION_TOGGLE_STATUS_URI +`/${subscription.id}/${!subscription.isActive}`
      )
      .then((res) => {
        dispatch(
          setSubscriptionStatus({
            ...subscription,
            isActive: !subscription.isActive,
          })
        );
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() =>
        dispatch(
          setSubscriptionLoader({
            id: null,
            isStatusLoading: false,
          })
        )
      );
  },
};

export default subscriptionApi;
