import {
    CButton,
    CModal,
    CModalBody
} from "@coreui/react";
import { useState } from "react";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api.service";
import Constants from "../../../constants/Constants";

interface IDeleteBookmarkModal {
    refreshDetails: () => void,
    onClose: any,
    isOpen: boolean,
    title: string,
    selectedBookmark: IBookmark | null | undefined,
}

const DeleteBookmarkModal: React.FC<IDeleteBookmarkModal> = ({ refreshDetails, isOpen, onClose, title, selectedBookmark}) => {

    const [isSpinning, setSpinning] = useState(false);
    const onSubmit = async (id: number) => {
        setSpinning(true);
        try {
            const res = await api.delete(`${Constants.DELETE_BOOKMARK}/${id}`);
            if (res.status === 200) {
                toast.success("😊 Court Link Deleted successfully.", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                await onClose();
                refreshDetails();
            }
        } catch (err) {
            // Handle errors
            toast.error("", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        } finally {
            setSpinning(false);
        }
    }

    return (
        <CModal
            show={isOpen}
            onClose={onClose}
            centered={true}
            closeOnBackdrop={false}
        >
            <CModalBody>
                <div>{title}</div>
                {!isSpinning && (
                    <div
                        style={{
                            placeItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            display: "flex",
                            // marginBottom: "1rem",
                            float: "right",
                        }}
                    >
                       {
                        selectedBookmark && (
                            <CButton color="danger" onClick={() => onSubmit( selectedBookmark.id)} size="sm">
                            Ok
                        </CButton>
                        )
                       }
                        <CButton
                            onClick={onClose}
                            color="secondary"
                            className="ml-2"
                            size="sm"
                        >
                            Cancel
                        </CButton>
                    </div>
                )}
                {isSpinning && (
                    <div
                        style={{
                            placeItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            display: "flex",
                            // marginBottom: "1rem",
                            float: "right",
                        }}
                    >
                        <CButton color="primary" disabled size="sm">
                            Deleting
                        </CButton>
                        <CButton
                            onClick={onClose}
                            color="secondary"
                            className="ml-2"
                            size="sm"
                        >
                            Cancel
                        </CButton>
                    </div>
                )}
            </CModalBody>
        </CModal>
    );
};

export default DeleteBookmarkModal;
