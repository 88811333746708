import TmfDropdown from "components/dropdown/dropdown";
import TmfDropdownItem from "components/dropdown/dropdownItem";
import { FaRegTrashAlt } from "react-icons/fa";
import { GoPencil } from "react-icons/go";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  setDeleteOfferModal,
  setEditOfferModal,
  setViewOfferModal,
} from "store/offer/action";

interface IOfferActionParams {
  offer: IOffer;
}

const OfferActions = ({ offer }: IOfferActionParams) => {
  const dispatch: Dispatch<any> = useDispatch();
  return (
    <div style={{ display: "flex", height: "40px" }}>
      <button
        onClick={() => {
          dispatch(setViewOfferModal({ showModal: true, offer }));
        }}
        disabled={offer.isDeleted}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid var(--primary, #1C63B7)",
          borderRadius: "25px 0px 0px 25px",
          background: "#fff",
          width: "84px",
          cursor: offer.isDeleted ? "not-allowed" : "pointer",
        }}
      >
        View
      </button>
      <TmfDropdown>
        {offer.isDeleted ? (
          <div className="px-2">No Actions</div>
        ) : (
          <>
            <TmfDropdownItem
              icon={<GoPencil/>}
              text="Edit"
              props={{
                onClick: () => {
                  dispatch(setEditOfferModal({ showModal: true, offer }));
                },
              }}
            ></TmfDropdownItem>
            <TmfDropdownItem
              icon={<FaRegTrashAlt/>}
              text="Delete"
              props={{
                onClick: () => {
                  dispatch(
                    setDeleteOfferModal({
                      showModal: true,
                      isModalFormSubmitting: false,
                      offer,
                    })
                  );
                },
              }}
            ></TmfDropdownItem>
          </>
        )}
      </TmfDropdown>
    </div>
  );
};

export default OfferActions;
