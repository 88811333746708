import { Dispatch } from "react";
import api from "services/api.service";
import apiUrls from "constants/Constants";
import {
  setAllLeads,
  setAllLeadLoader,
  setViewLeadModal,
  setViewLeadsMailModal,
} from "store/leads/action";

const leadsApi = {
  filterLeads: (
    payload: ILeadsFilter,
    dispatch: Dispatch<any>,
    pageSize,
    pageNo
  ) => {
    
    dispatch(setAllLeadLoader(true));

    return api
      .post(`${apiUrls.GET_LEADS_BY_DATE_RANGE}/${pageSize}/${pageNo}`, payload)
      .then((res) => {
        dispatch(setAllLeads(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => dispatch(setAllLeadLoader(false)));
  },

  fetchAllLeads: function (dispatch: Dispatch<any>) {
    return api
      .get(apiUrls.LEADS_GET_URI)
      .then((res) => {
        // dispatch(setAllLeads(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  viewLeads: function (dispatch: Dispatch<any>, leadsId?: number) {
    dispatch(setViewLeadModal({ isModalFormSubmitting: false }));
    return api
      .post(`${apiUrls.LEADS_GET_URI}/${leadsId}`)
      .then((res) => {
        dispatch(this.viewLeads(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() =>
        dispatch(setViewLeadModal({ isModalFormSubmitting: false }))
      );
  },

  viewLeadMail: function (dispatch: Dispatch<any>) {
    dispatch(setViewLeadsMailModal({ isModalFormSubmitting: false }));
    return api
      .post(`${apiUrls.LEADS_GET_URI}`)
      .then((res) => {
        dispatch(this.viewLeadMail(res.data));
        return res.data;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        dispatch(setViewLeadsMailModal({ isModalFormSubmitting: false }));
      });
  },
};
export default leadsApi;
