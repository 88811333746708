import { BookmarkActionTypes } from "./action.types";

export function getAllBookmarkLoader(isLoading: boolean) {
    const action: BookmarkAction = {
        type: BookmarkActionTypes.SET_BOOKMARK_BY_ID_LOADER,
        isLoading,
    };
    return (dispatch: BookmarkDispatchType) => dispatch(action);
}

// Bookmark By Id
export function getBookmarkByID(bookmark: IAllBookmarkCount) {
    const action: BookmarkAction = {
        type: BookmarkActionTypes.SET_BOOKMARK_BY_ID,
        bookmark,
    };
    return (dispatch: BookmarkDispatchType) => dispatch(action);
}

//Open Add Bookmark Modal
export function openAddBookmarkModal(addBookmarkModal: IBookmarkModal) {
    const action: BookmarkAction = {
      type: BookmarkActionTypes.SET_ADD_BOOKMARK_MODAL,
      addBookmarkModal,
    };
    return (dispatch: UserDispatchType) => dispatch(action);
  }

  export function openEditBookmarkModal(editBookmarkModal: IBookmarkModal) {
    const action: BookmarkAction = {
      type: BookmarkActionTypes.SET_EDIT_BOOKMARK_MODAL,
      editBookmarkModal,
    };
    return (dispatch: UserDispatchType) => dispatch(action);
  }



  export function   openDeleteBookmarkModal(deleteBookmarkModal: IBookmarkModal) {
    const action: BookmarkAction = {
      type: BookmarkActionTypes.SET_DELETE_BOOKMARK_MODAL,
      deleteBookmarkModal,
    };
    return (dispatch: UserDispatchType) => dispatch(action);
  }

  export function openTagBookmarkModal(tagBookmarkModal: IBookmarkModal) {
    const action: BookmarkAction = {
      type: BookmarkActionTypes.SET_TAG_BOOKMARK_MODAL,
      tagBookmarkModal,
    };
    return (dispatch: UserDispatchType) => dispatch(action);
  }


 


