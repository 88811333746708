import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

interface IDTablePagination {
  tPage: number,
  cPage: number,
  onPageChange: (value: React.SetStateAction<number>) => void,
  name?: string
}


export const DTablePagination: React.FC<IDTablePagination> = ({ tPage, cPage, onPageChange, name="page" }) => {
  const [currentPage, setCurrentPage] = useState<number>(cPage);
  useEffect(() => {
      setCurrentPage(cPage);
  }, [cPage]);




  const handleDecreasePage = (page) => {
   if(name==="court"||"tags"){
    if (page === 0) {
      return
    }
   }else{
    if (page === 1) {
      return
    }
   }
    setCurrentPage(page - 1);
    onPageChange(page - 1);
  }

  const handleIncreasePage = (page) => {
    if (page === tPage) {
      return;
    }
    setCurrentPage(page + 1);
    onPageChange(page + 1);
  }


if (name === "court" || name === "tags") {
    return (
      <div className="pagination-box">
        <button
          disabled={cPage === 0}
          style={{
            cursor: cPage === 0 ? "not-allowed" : "pointer",
          }}
          onClick={() => handleDecreasePage(cPage)}
        >
          <IoIosArrowBack />
        </button>
        <p>
          {currentPage + 1} <span style={{ fontSize: "400" }}>of</span> {tPage ? tPage : "0"}
        </p>
        <button
          disabled={cPage === tPage - 1}
          style={{
            cursor: cPage === tPage - 1 ? "not-allowed" : "pointer",
          }}
          onClick={() => handleIncreasePage(cPage)}
        >
          <IoIosArrowForward />
        </button>
      </div>
    );
  } else {
    return (
      <div className="pagination-box">
        <button
          disabled={cPage === 1}
          style={{
            cursor: cPage === 1 ? "not-allowed" : "pointer",
          }}
          onClick={() => handleDecreasePage(cPage)}
        >
          <IoIosArrowBack />
        </button>
        <p>
          {currentPage} <span style={{ fontSize: "400" }}>of</span> {tPage ? tPage : "0"}
        </p>
        <button
          disabled={cPage === tPage}
          style={{
            cursor: cPage === tPage ? "not-allowed" : "pointer",
          }}
          onClick={() => handleIncreasePage(cPage)}
        >
          <IoIosArrowForward />
        </button>
      </div>
    );
  }


      {/* <Pagination
        size="small"
        count={tPage}
        page={currentPage}
        onChange={handlePageChange}
        variant="outlined"
      /> */}
 
};
