import { Reducer } from "redux";
import { LeadsActionTypes, leadsFilterInitial } from "./action.types";

export const initialState: leadState = {
  allLeads: [],
  isleadFilterDataLoading: false,
  leadsFilter: leadsFilterInitial,
  viewLeadModal: {
    showModal: false,
    isModalFormSubmitting: false,
    lead: null,
  },
  viewLeadsMailModal: {
    showModal: false,
    isModalFormSubmitting: false,
    lead: null,
    selectedRows: null,
  },
  leadSearchValue: "",
};

const reducer: Reducer<leadState> = (state = initialState, action) => {
  switch (action.type) {
    case LeadsActionTypes.SET_ALL_LEADS: {
      return {
        ...state,
        allLeads: [...action.allLeads],
      };
    }
    case LeadsActionTypes.SET_LEAD_FILTER_DATA_LOADER: {
      return {
        ...state,
        isleadFilterDataLoading: action.isleadFilterDataLoading,
      };
    }
    case LeadsActionTypes.VIEW_LEAD: {
      return {
        ...state,
        allLeads: [
          ...state.allLeads.map((el) => {
            if (el.Id === action.lead.id) {
              el = { ...el, ...action.lead };
            }
            return el;
          }),
        ],
      };
    }
    case LeadsActionTypes.VIEW_LEAD_MODAL: {
      return {
        ...state,
        viewLeadModal: { ...state.viewLeadModal, ...action.viewLeadModal },
      };
    }
    case LeadsActionTypes.VIEW_LEAD_MAIL_MODAL: {
      return {
        ...state,
        viewLeadsMailModal: {
          ...state.viewLeadsMailModal,
          ...action.viewLeadsMailModal,
        },
      };
    }
    case LeadsActionTypes.SET_LEAD_SEARCH_VALUE: {
      return {
        ...state,
        leadSearchValue:  action.leadSearchValue,
      };
    }
    case LeadsActionTypes.SET_LEADS_FILTER: {
      return {
        ...state,
        leadsFilter: {
          ...state.leadsFilter,
          ...action.leadsFilter,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as leadsReducer };
