import { Dispatch } from "react";
import api from "services/api.service";
import apiUrls from "constants/Constants";
import { getAllCategoryLoader, getAllCourtCategory, getCourtSubCategory, getSubCategoryLoader} from "store/court/action";

const courtApi = {

    fetchAllCourtCategory: function (
        dispatch: Dispatch<any>,
        showLoader: boolean = true
    ) {
        if (showLoader) dispatch(getAllCategoryLoader(true));
        return api
            .get(apiUrls.GET_ALL_COURT_CATEGORY)
            .then((res) => {
                dispatch(getAllCourtCategory(res.data));
                return res.data;
            })
            .catch((error) => {
                throw error;
            })
            .finally(() => {
                if (showLoader) dispatch(getAllCategoryLoader(false));
            });
    },

    fetchCourtSubCategory: function (
        dispatch: Dispatch<any>,
        id:number,
        showLoader: boolean = true
    ) {
        if (showLoader) dispatch(getSubCategoryLoader(true));
        return api
            .get(`${apiUrls.GET_COURT_SUB_CATEGORY}/${id}`)
            .then((res) => {
                dispatch(getCourtSubCategory(res.data));
                return res.data;
            })
            .catch((error) => {
                throw error;
            })
            .finally(() => {
                if (showLoader) dispatch(getSubCategoryLoader(false));
            });
    },

}

export default courtApi;