import { createContext, useState, FC, ReactNode, useEffect } from "react";
import { CFade } from "@coreui/react";
export interface ISelect {
  readonly selectedOption: ISelectData | null;
  setSelectedOption: (option: ISelectData) => void;
  resetSelectedOption: () => void;
  readonly search: string;
  setSearch: (search: string) => void;
  readonly options: ISelectData[];
}

interface ISelectProviderProps {
  children: ReactNode;
  optionsData: ISelectData[];
}

const selectContentDefaults: ISelect = {
  selectedOption: null,
  setSelectedOption: () => {},
  resetSelectedOption: () => {},
  search: "",
  setSearch: () => {},
  options: [],
};

export const selectContext = createContext<ISelect>(selectContentDefaults);

const SelectProvider = ({ children, optionsData }: ISelectProviderProps) => {
  const [selectedOption, setSelectedOption] = useState(
    selectContentDefaults.selectedOption
  );
  const [search, setSearch] = useState(selectContentDefaults.search);
  const [options, setOptions] = useState(selectContentDefaults.options);

  useEffect(() => {
    setOptions(optionsData);
    selectedOptionHandler(optionsData[0]);
  }, [optionsData]);

  function selectedOptionHandler(option: ISelectData) {
    setSelectedOption(option);
  }
  function resetOptionHandler() {
    setSelectedOption(null);
  }
  function searchHandler(searchData: string) {
    setSearch(searchData);
  }

  return (
    <selectContext.Provider
      value={{
        selectedOption,
        setSelectedOption: selectedOptionHandler,
        resetSelectedOption: resetOptionHandler,
        search,
        setSearch: searchHandler,
        options,
      }}
    >
      <CFade>{children}</CFade>
    </selectContext.Provider>
  );
};
export default SelectProvider;
