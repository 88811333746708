import { CButton, CModal, CModalBody } from "@coreui/react";
import api from "services/api.service";
import { useState } from "react";
import Constants from "../../constants/Constants";

const OrganizationStatusModal = ({ onClose, organization, refreshDetails }) => {
  const [isLoading, setLoaded] = useState(false);
  const isActive = organization.isActive;

  const handleOrganizationToogle = async () => {
    setLoaded(true);
    //console.log("here");
    await api
      .post(
        Constants.ORGANIZATION_TOGGLE_STATUS_URI +
          "/" +
          organization.organizationId +
          "/" +
          !isActive
      )
      .then(async () => {
        refreshDetails();
        await onClose();

        if (!isActive) {
          //console.log(`${organization.name} organization is Activated!`);
        } else {
          //console.log(`${organization.name} organization is Deactivated!`);
        }
        setLoaded(false);
      });
  };
  return (
    <div>
      <CModal
        show={true}
        onClose={onClose}
        centered={true}
        closeOnBackdrop={false}
      >
        <CModalBody>
          <div style={{ textAlign: "center" }}>
            <div>
              {!isActive ? (
                <div>
                  <div
                    className="col-md-10"
                    style={{ fontWeight: "bold", marginLeft: "2rem" }}
                  >
                    Are you sure, {organization.name} to be Activated?
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      fontWeight: "bold",
                    }}
                    className="col-md-11"
                  >
                    Are you sure, {organization.name} to be Deactivated?
                  </div>
                </div>
              )}
            </div>

            <div
              style={{
                marginTop: "0.5rem",
                display: "flex",
                marginLeft: "8rem",
              }}
            >
              <div style={{ marginLeft: "1rem" }}>
                <CButton onClick={onClose} color="secondary" size="sm">
                  No,cancel Plz!
                </CButton>
              </div>
              <div style={{ marginLeft: "0.5rem" }}>
                {!isLoading && (
                  <CButton
                    onClick={() => handleOrganizationToogle()}
                    color="danger"
                    size="sm"
                  >
                    {" "}
                    Yes
                  </CButton>
                )}
                {isLoading && (
                  <CButton
                    disabled
                    className="float-right"
                    color="danger"
                    size="sm"
                  >
                    {" "}
                    <i className="fa fa-spinner fa-spin"></i> Updating
                  </CButton>
                )}
              </div>
            </div>
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
};

export default OrganizationStatusModal;
