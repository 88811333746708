import { useEffect } from "react";

const LoginSuccess = () => {
  useEffect(() => {
    setTimeout(() => {
      window.opener.LOGIN_SUCCESS = "success";
      window.close();
    }, 1000);
  }, []);

  return <></>;
};

export default LoginSuccess;
