import {
    CButton,
    CCol,
    CFormGroup,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CRow,
} from "@coreui/react";
import SearchLoader from "components/loader/SearchLoader";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "services/api.service";
import Constants from "../../../constants/Constants";

interface IEditBookmarkModal {
    refreshDetails: () => void,
    onClose: any,
    isOpen: boolean,
    title: string,
    selectedBookmark: IBookmark | null | undefined,
    selectedCategoryId: string | null
}

interface ISelectedTag {
    value: string;
    tagID: number
}


interface Tag {
    tagIds: ISelectedTag[];
}

const TagBookmarkModal: React.FC<IEditBookmarkModal> = ({ refreshDetails, isOpen, onClose, title, selectedBookmark, selectedCategoryId }) => {
    const [isSpinning, setSpinning] = useState(false);
    const [inputText, setInputText] = useState('');
    const [isSearchSpinning, setIsSearchSpinning] = useState(false);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
    const [tagsOptions, setTagsOptions] = useState<any>(null);
    const [selectedTags, setSelectedTags] = useState<ISelectedTag[]>([]);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
    const tagOptionsRef = useRef<HTMLDivElement | null>(null);
    const [focusedButtonId, setFocusedButtonId] = useState<number | null>(null);

    const {
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm();


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputText(event.target.value);
    };

    useEffect(() => {
        if (inputText === '') return;
        if (timer) {
            clearTimeout(timer);
        }

        const newTimer = setTimeout(async () => {
            if (inputText.trim() !== '') {
                setIsSearchSpinning(true);
                try {
                    const response = await api.get(`${Constants.SEARCH_GLOBAL_TAG}/${inputText}`);
                    const filteredEmails = response.data.filter((tag: ITag) =>
                        !selectedTags.some((selectedTag: ISelectedTag) =>
                            selectedTag.value === tag.name
                        )
                    );
                    setTagsOptions(filteredEmails);
                } catch (err) {
                    setTagsOptions(null);
                } finally {
                    setIsSearchSpinning(false);
                }
            } else {
                setTagsOptions(null);
            }
        }, 500);

        setTimer(newTimer);

        // Clean up the timer on unmount or input change
        return () => {
            if (newTimer) {
                clearTimeout(newTimer);
            }
        };
    }, [inputText]);

    const handleSelectChange = (data: ITag) => {
        const newItem = { value: data.name, tagID: data.tagID };
        if (!selectedTags.some((item: ISelectedTag) => item.tagID === newItem.tagID)) {
            const updatedTags = [...selectedTags, newItem];
            setSelectedTags(updatedTags);
            setValue('tagIds', updatedTags);
        }
        setTagsOptions(null);
        setInputText("");
        setSelectedOptionIndex(-1);
    };

    const handleDeleteEmail = (data: ISelectedTag) => {
        const updatedTags = selectedTags.filter((e: ISelectedTag) => e.tagID !== data.tagID);
        setSelectedTags(updatedTags);
        setValue('tagIds', updatedTags);
    };

    //Handle KeyDown
    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
        if (tagsOptions != null) {
            if (event.key === 'ArrowDown') {
                event.preventDefault();
                setSelectedOptionIndex(prevIndex =>
                    prevIndex < tagsOptions.length - 1 ? prevIndex + 1 : prevIndex
                );

            } else if (event.key === 'ArrowUp') {
                event.preventDefault();
                setSelectedOptionIndex(prevIndex =>
                    prevIndex > 0 ? prevIndex - 1 : -1
                );
            } else if (event.key === 'Enter' && selectedOptionIndex !== -1) {
                event.preventDefault();
                handleSelectChange(tagsOptions[selectedOptionIndex]);
            }

        }

    };
    useEffect(() => {
        if (tagOptionsRef.current && selectedOptionIndex >= 0) {
            setTimeout(() => {
                const optionsContainer = tagOptionsRef.current;
                if (!optionsContainer) return;

                const selectedOption = optionsContainer.children[0].children[selectedOptionIndex] as HTMLElement;
                if (selectedOption) {
                    const containerHeight = optionsContainer.clientHeight;
                    const optionTop = selectedOption.offsetTop;
                    const optionHeight = selectedOption.offsetHeight;

                    if (optionTop < optionsContainer.scrollTop) {
                        // Scroll up if the option is above the visible area
                        optionsContainer.scrollTop = optionTop;
                    } else if (optionTop + optionHeight > optionsContainer.scrollTop + containerHeight) {
                        // Scroll down if the option is below the visible area
                        optionsContainer.scrollTop = optionTop + optionHeight - containerHeight;
                    }
                }
            }, 0);
        }
    }, [selectedOptionIndex]);

    useEffect(() => {
        if (selectedBookmark) {
            const transformedTags = selectedBookmark.tags.map((tag: any) => ({
                value: tag.name,
                tagID: tag.tagID,
            }));
            setSelectedTags(transformedTags);
            setValue('tagIds', transformedTags);
        }
    }, [selectedBookmark, setValue]);


    const onSubmit = async (data: any) => {
        setSpinning(true);
        try {
            const res = await api.post(`${Constants.ADD_TAG_TO_BOOKMARK}/${selectedBookmark!.id}`, data.data.tagIds);

            var currenttagslength = selectedBookmark!.tags.length;
            var updatedtagslength = data.data.tagIds.length;

            if (res.status === 200) {
                if (updatedtagslength >= currenttagslength && currenttagslength !== 0 && updatedtagslength !== 0 || updatedtagslength >= currenttagslength && currenttagslength === 0 && updatedtagslength != 0) {
                    toast.success("😊Tags added to bookmark.", {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                } else if (updatedtagslength < currenttagslength) {
                    toast.success(`${currenttagslength - updatedtagslength} tag removed from bookmark`, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                }
                await onClose();
                refreshDetails();
            }

        } catch (err) {
            // Handle errors
            toast.error("Error Found", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        } finally {
            setSpinning(false);
        }
    }

    const handleAddTag = (data: any) => {

        const incomingTagIds = data.tagIds.map(tag => tag.tagID);
        const existingTagIds = selectedBookmark!.tags.map(tag => tag.tagID);

        // Find new tagIDs that don't exist in bookmarkstags
        const newTagExistingIds = existingTagIds.filter(id => !incomingTagIds.includes(id));
        const newTagIncomingIds = incomingTagIds.filter(id => !existingTagIds.includes(id));


        if (incomingTagIds.length === 0 && existingTagIds.length === 0) {

            toast.error("Empty Tag cannot be added in bookmark.", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            return;
        }


        if (newTagExistingIds.length === 0 && newTagIncomingIds.length === 0) {
            toast.error("No new tags to add. All selected tags already exist.", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });

            return;

        }
        const newData = {

            data: data,

        }
        onSubmit(newData)
    }


    return (
        <div>
            <CModal show={isOpen} onClose={onClose} closeOnBackdrop={true}>
                <CModalHeader closeButton>
                    <CModalTitle>
                        {" "}
                        <div> {title}</div>{" "}
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <form
                        onSubmit={handleSubmit(handleAddTag)}
                        style={{ fontWeight: "bold", fontSize: "12px" }}
                    >
                        <CRow>
                            <CCol>
                                <CFormGroup>
                                    <label>Search Tag</label>

                                    <input
                                        type="text"
                                        className="form-control "
                                        value={inputText}
                                        onChange={handleInputChange}
                                        maxLength={60}
                                        onKeyDown={handleKeyDown}
                                        placeholder="Search By Tag Name..."
                                    />

                                    {
                                        isSearchSpinning ?
                                            <div style={{
                                                position: "absolute",
                                                zIndex: "20",
                                                marginTop: "0.25rem",
                                                background: "white",
                                                width: "95%",
                                                borderColor: "#6b7280",
                                                borderRadius: "20px",
                                                overflowY: "auto",
                                                padding: "0.5rem",
                                                paddingTop: "0px",
                                                boxShadow: " rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px"
                                            }} >
                                                <SearchLoader />
                                            </div>
                                            :
                                            (
                                                <>
                                                    {tagsOptions && inputText.length > 0 && (
                                                        <>
                                                            {
                                                                tagsOptions.length > 0 ? (
                                                                    <div className={`${tagsOptions.length > 0 ? "tag-search-box" : "hidden"} `}>
                                                                        {tagsOptions.length > 0 && (
                                                                            <div ref={tagOptionsRef} className="search-box">
                                                                                <ul>
                                                                                    {tagsOptions.map((elem: ITag, index: number) => (
                                                                                        <li
                                                                                            style={{
                                                                                                display: "block",
                                                                                                fontSize: "12px",
                                                                                                fontWeight: "400",
                                                                                                backgroundColor: `${index === selectedOptionIndex ? "#e0f2fe" : ""}`,
                                                                                                color: "#334155",
                                                                                                cursor: "pointer",
                                                                                                padding: "4px"
                                                                                            }}

                                                                                            key={index} onClick={() => handleSelectChange(elem)}>
                                                                                            {elem.name}
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <div className="tag-search-box">
                                                                        <p className="text-sm text-gray-500 text-center">Tag not found</p>
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                    }
                                                </>
                                            )
                                    }

                                    <div style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        marginTop: "4px"
                                    }}>
                                        {selectedTags && selectedTags.length > 0 ? (
                                            selectedTags.map((elem: ISelectedTag, index: number) => (
                                                <div
                                                    className="selected-tag"
                                                    key={index}>
                                                    <span>{elem.value}</span>
                                                    <button type="button"
                                                        onClick={() => handleDeleteEmail(elem)}
                                                        onFocus={() => setFocusedButtonId(index)}
                                                        onBlur={() => setFocusedButtonId(null)}
                                                        className={`${focusedButtonId === index ? 'element-with-red-highlight' : ''
                                                            }`}
                                                    >
                                                        &#10005;
                                                    </button>
                                                </div>
                                            ))
                                        ) : (
                                            <span className="block text-sm font-medium text-slate-600">No tags selected</span>
                                        )}
                                    </div>

                                </CFormGroup>
                            </CCol>
                        </CRow>

                        {!isSpinning && (
                            <div
                                style={{
                                    placeItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    display: "flex",
                                    marginTop: "10px",
                                    float: "right",
                                }}
                            >
                                <CButton
                                    style={{
                                        background: "var(--primary)",
                                        color: "white"
                                    }}
                                    type="submit"
                                    size="sm"
                                >
                                    Add
                                </CButton>

                                <div className="ml-1">
                                    <CButton color="danger" onClick={onClose} size="sm">
                                        Close
                                    </CButton>
                                </div>
                            </div>
                        )}

                        {isSpinning || isSearchSpinning && (
                            <div
                                style={{
                                    placeItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    display: "flex",
                                    marginTop: "10px",
                                    float: "right",
                                }}
                            >
                                <CButton
                                    disabled
                                    style={{
                                        background: "var(--primary)",
                                        color: "white"
                                    }}
                                    size="sm"
                                >
                                    <i className="fa fa-spinner fa-spin"></i> Adding
                                </CButton>
                                <div className="ml-1">
                                    <CButton color="danger" onClick={onClose} size="sm">
                                        Close
                                    </CButton>
                                </div>
                            </div>
                        )}

                    </form>
                </CModalBody>
            </CModal>
        </div>
    );
};

export default TagBookmarkModal;
