// eslint-disable-next-line import/no-anonymous-default-export
export default {
  //process.env.REACT_APP_BASEURL +

  //Login
  LOGIN_URI: process.env.REACT_APP_BASEURL + "/api/account/Login",

  //Logout
  LOGOUT_URI: process.env.REACT_APP_BASEURL + "/logout",

  //Organization
  GET_ORG_COUNT:
    process.env.REACT_APP_BASEURL + "/api/organization/getTotalCount",
  ORGANIZATION_GET_URI: process.env.REACT_APP_BASEURL + "/api/organization/get",
  UNSUBSCRIBED_ORGANIZATION_GET_URI:
    process.env.REACT_APP_BASEURL + "/api/organization/getunsuscribed",
  ORGANIZATION_UPDATE_POST_URI:
    process.env.REACT_APP_BASEURL + "/api/organization/update",
  ORGANIZATION_GET_ONLY_ORG_DETAILS_URI:
    process.env.REACT_APP_BASEURL + "/api/organization/orgdetails",
  ORGANIZATION_CREATE_POST_URI:
    process.env.REACT_APP_BASEURL + "/api/organization/create",
  ORGANIZATION_DETAILS_GET_URI:
    process.env.REACT_APP_BASEURL + `/api/organization/details`,
  ORGANIZATION_EDIT_URI:
    process.env.REACT_APP_BASEURL + `/api/organization/Edit`,
  ORGANIZATION_TOGGLE_STATUS_URI:
    process.env.REACT_APP_BASEURL + `/api/organization/toggleorganization`,
  ORGANIZATION_SAFELINK_URI:
    process.env.REACT_APP_BASEURL + `/api/organization/editSafeLink`,
  ORGANIZATION_AICOPILOTLINK_URI: process.env.REACT_APP_BASEURL + `/api/organization/editAiCopilotLink`,

  DATE_WISE_ORGANIZATION_FILTER_URI:
    process.env.REACT_APP_BASEURL +
    `/api/organization/getorganizationbydaterange`,

  ORGANIZATION_TYPE_GET_URI:
    process.env.REACT_APP_BASEURL + `/api/organization/getallorganizationtypes`,

  ORGANIZATION_SEARCH_URI:
    process.env.REACT_APP_BASEURL + `/api/organization/searchOrganization`,

  ORGANIZATION_COURTLINK_URI:
    process.env.REACT_APP_BASEURL + `/api/organization/EditCourtLink`,

  //User
  GET_USER_COUNT: process.env.REACT_APP_BASEURL + "/api/user/getTotalUserCount",

  USER_GET_URI: process.env.REACT_APP_BASEURL + "/api/user/Get",
  USER_EDIT_URI: process.env.REACT_APP_BASEURL + "/api/user/edit",
  USER_UPDATE_POST_URI: process.env.REACT_APP_BASEURL + "/api/user/update",
  USER_CREATE_POST_URI: process.env.REACT_APP_BASEURL + "/api/user/create",
  USER_GETALLBYORGANIZATION_URI:
    process.env.REACT_APP_BASEURL + "/api/user/getAllUserByOrganization",
  USER_DELETE_URI: process.env.REACT_APP_BASEURL + "/api/user/delete",

  USER_ALLORGANIZATIONS_URI:
    process.env.REACT_APP_BASEURL + "/api/user/GetUser",

  USER_TOGGLE_STATUS_URI:
    process.env.REACT_APP_BASEURL + `/api/user/toggleuser`,

  DATE_WISE_USER_FILTER_URI:
    process.env.REACT_APP_BASEURL + `/api/user/getusersbydaterange`,

  USER_CHECK_EXISTS_URI: process.env.REACT_APP_BASEURL + `/api/user/userExists`,

  USER_ME_URI: process.env.REACT_APP_BASEURL + `/api/user/me`,

  USER_UNDO_DELETE_URI: process.env.REACT_APP_BASEURL + `/api/user/undodelete`,

  USER_SEARCH_URI: process.env.REACT_APP_BASEURL + "/api/user/searchUser",

  USER_BOOKMARK_COUNT:
    process.env.REACT_APP_BASEURL + `/api/user/getBookmarkCount`,

  //Links
  GET_ALL_LINKS: process.env.REACT_APP_BASEURL + "/api/category/GetCourtLinks",

  LINK_CREATE_POST_URI: process.env.REACT_APP_BASEURL + "/api/bookmark/add",

  LINK_CHECK_EXISTS_URI:
    process.env.REACT_APP_BASEURL + `/api/bookmark/bookmarkExists`,
  LINK_DELETE_URI: process.env.REACT_APP_BASEURL + "/api/bookmark/delete",
  //collections

  //category
  LINKTYPES_GET_URI:
    process.env.REACT_APP_BASEURL + "/api/category/GetCourtLinks",
  GET_ALL_CATEGORIES: process.env.REACT_APP_BASEURL + "/api/category/get",
  GET_ALL_SUB_CATEGORIES:
    process.env.REACT_APP_BASEURL + "/api/category/subcategories",
  ADD_CATEGORIES: process.env.REACT_APP_BASEURL + "/api/category/add",
  //Timezone

  TIMEZONE_GET_URI:
    process.env.REACT_APP_BASEURL + "/api/organization/timezone",

  //Country
  COUNTRY_GET_URI: process.env.REACT_APP_BASEURL + "/api/country/getall",

  //States
  STATE_GET_URI: process.env.REACT_APP_BASEURL + "/api/state/getbycountry",
  GET_ALL_STATE_URI: process.env.REACT_APP_BASEURL + "/api/state/getAllStates",

  // Subscripttion
  GET_SUBSCRIPTION_COUNT:
    process.env.REACT_APP_BASEURL +
    "/api/subscription/getTotalSubscriptionCount",
  SUBSCRIPTION_GET_URI:
    process.env.REACT_APP_BASEURL + "/api/subscription/AllSubscription",
  SUBSCRIPTION_EDIT_URI:
    process.env.REACT_APP_BASEURL + "/api/Subscription/edit",
  SUBSCRIPTION_UPDATE_POST_URI:
    process.env.REACT_APP_BASEURL + "/api/Subscription/update",
  SUBSCRIPTION_CREATE_URI:
    process.env.REACT_APP_BASEURL + "/api/subscription/create",
  SUBSCRIPTION_DELETE_URI:
    process.env.REACT_APP_BASEURL + "/api/subscription/delete",

  SUBSCRIPTION_GETMONTHLY_URI:
    process.env.REACT_APP_BASEURL + "/api/subscription/getMonthly",
  SUBSCRIPTION_GETYEARLY_URI:
    process.env.REACT_APP_BASEURL + "/api/subscription/getyearly",
  ORGANIZATION_GET_ALL_SUBSCRIPTION_URI:
    process.env.REACT_APP_BASEURL + `/api/subscription/GetByOrganization`,

  UNDO_DELETE_SUBSCRIPTION_URI:
    process.env.REACT_APP_BASEURL + `/api/subscription/undodeletesubscription`,

  SUBSCRIPTION_SEARCH_URI:
    process.env.REACT_APP_BASEURL + `/api/subscription/searchSubscription`,

  //Organization Wise Subscription Expired

  SUBSCRIPTION_EXPIRED_URI:
    process.env.REACT_APP_BASEURL + "/api/organization/getexpired",

  SUBSCRIPTION_TYPES_GET_ALL_URI:
    process.env.REACT_APP_BASEURL + "/api/subscription/allsubscriptiontypes",

  SUBSCRIPTION_FILTER_URI:
    process.env.REACT_APP_BASEURL + "/api/subscription/getsubscriptionfilter",

  SUBSCRIPTION_EXPIRED_URI:
    process.env.REACT_APP_BASEURL + "/api/subscription/getexpiredsubscription",

  SUBSCRIPTION_TOGGLE_STATUS_URI:
    process.env.REACT_APP_BASEURL + "/api/subscription/togglesubscription",

  //payment History
  PAYMENT_HISTORY_URI: process.env.REACT_APP_BASEURL + "/api/payment/getall",

  // LicenseDetails
  LICENSE_DETAILS_URI:
    process.env.REACT_APP_BASEURL + "/api/subscription/getlicensedetails",

  //Invoice
  INVOICE_URI: process.env.REACT_APP_BASEURL + "/api/invoice/get",

  //license
  //LICENSE_DETAILS_URI: process.env.REACT_APP_BASEURL + "/api/organization/getdetails",

  //Plans
  PLAN_GET_URI: process.env.REACT_APP_BASEURL + "/api/plan/getallplans",
  PLAN_EDIT_POST_URI: process.env.REACT_APP_BASEURL + "/api/plan/edit",
  PLAN_CREATE_POST_URI: process.env.REACT_APP_BASEURL + "/api/plan/create",
  PLAN_UPDATE_POST_URI: process.env.REACT_APP_BASEURL + "/api/plan/update",
  PLAN_GETBYID_POST_URI: process.env.REACT_APP_BASEURL + "/api/plan/getbyid",
  PLAN_DELETE_POST_URI: process.env.REACT_APP_BASEURL + "/api/plan/delete",
  PLAN_TOGGLE_POST_URI: process.env.REACT_APP_BASEURL + "/api/plan/toggleplan",
  PLAN_TOTAL_COUNT_URI:
    process.env.REACT_APP_BASEURL + "/api/plan/gettotalplancount",
  PLAN_FILTER_URI:
    process.env.REACT_APP_BASEURL + "/api/plan/getPlanByDateRange",
  PLAN_SEARCH_URI: process.env.REACT_APP_BASEURL + "/api/plan/searchPlan",

  //Icons
  ICON_GET_URI: process.env.REACT_APP_BASEURL + "/api/icon/get",
  ADD_ICON: process.env.REACT_APP_BASEURL + "/api/icon/add",

  //UserTypes
  USERTYPES_GET_URI: process.env.REACT_APP_BASEURL + "/api/user/userType",

  //Offers
  OFFERS_GET_ALL_URI: process.env.REACT_APP_BASEURL + "/api/offer/getalloffers",
  OFFERS_CREATE_POST_URI: process.env.REACT_APP_BASEURL + "/api/offer/create",
  OFFERS_EDIT_POST_URI: process.env.REACT_APP_BASEURL + "/api/offer/edit",
  OFFERS_VIEW_POST_URI: process.env.REACT_APP_BASEURL + "/api/offer/getbyid",
  OFFERS_UPDATE_POST_URI: process.env.REACT_APP_BASEURL + "/api/offer/update",
  OFFERS_DELETE_POST_URI: process.env.REACT_APP_BASEURL + "/api/offer/delete",
  OFFERS_TOGGLE_POST_URI:
    process.env.REACT_APP_BASEURL + "/api/offer/toggleoffer",
  OFFERS_TOTAL_COUNT_URI:
    process.env.REACT_APP_BASEURL + "/api/offer/getTotalOffersCount",
  OFFERS_FILTER_URI:
    process.env.REACT_APP_BASEURL + "/api/offer/getoffersbydaterange",
  OFFERS_SEARCH_URI: process.env.REACT_APP_BASEURL + "/api/offer/searchOffer",

  //Leads
  LEADS_GET_URI: process.env.REACT_APP_BASEURL + "/api/lead/getallleads",
  LEADS_SEARCH_URI: process.env.REACT_APP_BASEURL + "/api/lead/searchleads",
  GET_LEADS_BY_DATE_RANGE:
    process.env.REACT_APP_BASEURL + "/api/lead/getleadsfilter",
  LEADS_SEND_MAIL: process.env.REACT_APP_BASEURL + "/api/lead/sendleadmail",
  LEADS_SEND_MULTIPLE_MAIL:
    process.env.REACT_APP_BASEURL + "/api/lead/sendmultiplemail",
  IMPORT_LEAD_CSV: process.env.REACT_APP_BASEURL + "/api/lead/import_csv_file",
  LEAD_TEMPLATE_DOWNLOAD:
    process.env.REACT_APP_BASEURL + "/api/lead/LeadsTemplateDownload",
  GET_LEADS_COUNT:
    process.env.REACT_APP_BASEURL + "/api/lead/getTotalLeadsCount",
  LEAD_MAIL_UPDATE_URI:
    process.env.REACT_APP_BASEURL + "/api/lead/setLeadsMailStatus",


  //Partners
  PARTNER_GET_ALL_URI:
    process.env.REACT_APP_BASEURL + "/api/partner/getallpartners",
  PARTNER_EDIT_URL: process.env.REACT_APP_BASEURL + "/api/partner/edit",
  PARTNER_UPDATE_URL: process.env.REACT_APP_BASEURL + "/api/partner/update",
  PARTNER_CREATE_URL: process.env.REACT_APP_BASEURL + "/api/partner/create",
  PARTNER_DELETE_URL: process.env.REACT_APP_BASEURL + "/api/partner/delete",
  PARTNER_VIEW_URL: process.env.REACT_APP_BASEURL + "/api/partner/getbyid",
  PARTNER_EXISTS_URI:
    process.env.REACT_APP_BASEURL + "/api/partner/partnerexists",
  PARTNER_TOGGLE_POST_URI:
    process.env.REACT_APP_BASEURL + "/api/partner/togglepartner",
  PARTNER_TOTAL_COUNT_URI:
    process.env.REACT_APP_BASEURL + "/api/partner/getTotalPartnerCount",
  PARTNER_FILTER_URI:
    process.env.REACT_APP_BASEURL + "/api/partner/getPartnersbydaterange",

  //PartnerType
  PARTNER_TYPE_GET_ALL:
    process.env.REACT_APP_BASEURL + "/api/partner/getallpartnertype",

  //payment all subscription
  PAYMENT_ALL_SUBSCRIPTION:
    process.env.REACT_APP_BASEURL + "/api/payment/payments",

  //email
  SEND_MAIL: process.env.REACT_APP_BASEURL + "/api/mail/sendmail",

  // Dashboard
  GET_TOTAL_DASHBOARD_COUNTS:
    process.env.REACT_APP_BASEURL + "/api/dashboard/GetDashboardTotalCount",
  GET_LEADS_COUNT_BY_MONTH:
    process.env.REACT_APP_BASEURL + "/api/dashboard/GetLeadsCountByMonth",

  GET_SUBSCRIPTION_COUNT_BY_MONTH:
    process.env.REACT_APP_BASEURL + "/api/dashboard/GetSubscriptionCountByMonth",

  //Court
  GET_ALL_COURT_CATEGORY: process.env.REACT_APP_BASEURL + "/api/court/GetCourtLinks",
  GET_COURT_SUB_CATEGORY:process.env.REACT_APP_BASEURL + "/api/court/subcategories",
  GET_ALL_BOOKMARK_BY_ID: process.env.REACT_APP_BASEURL + "/api/bookmarks/getBookmarks",
  POST_COURT_CATEGORY: process.env.REACT_APP_BASEURL + "/api/court/category",
  UPDATE_COURT_CATEGORY: process.env.REACT_APP_BASEURL + "/api/court/edit",
  DELETE_COURT_CATEGORY: process.env.REACT_APP_BASEURL + "/api/court",


  //Bookmark
  GET_ALL_BOOKMARK_BY_ID: process.env.REACT_APP_BASEURL + "/api/bookmark/getBookmarks",
  POST_BOOKMARK_BY_CATEGORY_ID: process.env.REACT_APP_BASEURL + "/api/bookmark/create",
  GET_POST_RESOLVED_BOKMARK_DATA:
    process.env.REACT_APP_BASEURL + "/api/bookmark/fetch",
  UPDATE_BOOKMARK: process.env.REACT_APP_BASEURL + "/api/bookmark/edit",
  DELETE_BOOKMARK: process.env.REACT_APP_BASEURL + "/api/bookmark/delete",
  ADD_TAG_TO_BOOKMARK:process.env.REACT_APP_BASEURL + "/api/bookmark/addtobookmark",

  //Tag
  GET_ALL_TAG:process.env.REACT_APP_BASEURL + "/api/tag",
  POST_GLOBAL_TAG:process.env.REACT_APP_BASEURL + "/api/tag/create",
  EDIT_GLOBAL_TAG:process.env.REACT_APP_BASEURL + "/api/tag/edit",
  DELETE_GLOBAL_TAG:process.env.REACT_APP_BASEURL + "/api/tag/delete",
  SEARCH_GLOBAL_TAG:process.env.REACT_APP_BASEURL + "/api/tag/searchglobaltags",

  // Month
  MONTHS: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  EXPIRY_IN: [
    {
      label: "Today",
      value: "day",
    },
    {
      label: "Week",
      value: "week",
    },
    {
      label: "Month",
      value: "month",
    },
    {
      label: "Year",
      value: "year",
    },
  ],
  LEAD_SOURCE: [
    {
      label: "Teams",
      value: "teams",
    },
    {
      label: "Word",
      value: "word",
    },
    {
      label: "Web",
      value: "web",
    },
    {
      label: "Outlook",
      value: "outlook",
    },
  ],

  YEARS: () => {
    let years = [];
    for (let i = 2000; i <= new Date().getFullYear(); i++) {
      years.push(i);
    }
    return years;
  },
};
